<template>
  <v-container fill-height class="confirmation-mask">
    <v-layout align-center justify-center>
      <v-flex xs10 sm8 md4>
        <v-card class="elevation-4 confirmation-wrapper">
          <div class="card-header" v-if="actionName == 'deleteUser'">
            <p
              v-if="
                item['Attributes'].find(e => e['Name'] == 'email')['Value'] ==
                  $store.state.user.attributes.email
              "
            >
              Are you sure you want to delete your own account?
            </p>
            <p
              v-if="
                item['Attributes'].find(e => e['Name'] == 'email')['Value'] !=
                  $store.state.user.attributes.email
              "
            >
              Are you sure you want to delete?
            </p>
          </div>
          <div v-if="actionName == 'archiveCampaign'">
            <p>{{ messageText }}</p>
          </div>
          <div
            class="card-header"
            v-if="actionName != 'deleteUser' && actionName != 'archiveCampaign'"
          >
            <p>Are you sure you want to delete?</p>
          </div>
          <div class="card-footer" v-if="actionName != 'archiveCampaign'">
            <v-btn
              class="elevation-0"
              v-on:click="$emit('close')"
              :disabled="deleteInProgress"
              >Cancel</v-btn
            >
            <v-btn
              class="elevation-0 primary"
              v-on:click="confirmation(item)"
              :disabled="deleteInProgress"
            >
              <v-progress-circular
                indeterminate
                color="#fff"
                v-if="deleteInProgress"
              ></v-progress-circular
              ><span v-if="!deleteInProgress">{{ primaryTxt }}</span></v-btn
            >
          </div>
          <div class="card-footer" v-if="actionName == 'archiveCampaign'">
            <v-btn
              class="elevation-0"
              v-on:click="$emit('close')"
              :disabled="archiveInProgress"
              >Cancel</v-btn
            >
            <v-btn
              class="elevation-0 primary"
              v-on:click="confirmationArchive(item)"
              :disabled="archiveInProgress"
            >
              <v-progress-circular
                indeterminate
                color="#fff"
                v-if="archiveInProgress"
              ></v-progress-circular
              ><span v-if="!archiveInProgress">{{ positiveButtonText }}</span></v-btn
            >
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  name: "ConfirmationDIalog",
  props: [
    "item",
    "primaryTxt",
    "actionName",
    "positiveButtonText",
    "messageText"
  ],
  data() {
    return {
      /* actionName: '' */
    };
  },
  computed: {
    deleteInProgress() {
      return this.$store.state.deleteInProgress;
    },
    archiveInProgress() {
      return this.$store.state.archiveInProgress;
    }
  },
  methods: {
    /*setAction(){
            if($route.params.action = deleteCampaign){
                this.actionName = 'deleteCampaign'
            }
            else if($route.params.action = deleteTemplate){
                this.actionName = 'deleteEmailTemplate'
            }
        }, */
    confirmation(item) {
      this.$store.state.deleteInProgress = true;
      if (item.Username != undefined) {
        this.$store.state.selectedID = item.Username;
      } else {
        this.$store.state.selectedID = item.id;
      }
      //this.$store.state.dialogId = '';
      this.$store.dispatch(this.actionName, item);
    },
    confirmationArchive(item) {
      this.$store.state.archiveInProgress = true;
      if (item.archived) {
        item.archived = false;
      } else {
        item.archived = true;
      }
      console.log(item);
      this.$store.state.archiveDialogId = item.id;
      this.$store.dispatch("updateCampaign", {
        campaignID: item.id,
        campaignData: item,
        updatingArchiveStatus: true
      });
    }
  }
  /* watch: {
        '$route': function() {
            this.setAction();
        }
    } */
};
</script>
<style scoped>
.confirmation-mask {
  position: fixed;
  max-width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
}
.confirmation-wrapper {
  padding: 40px 50px;
  background-color: #fafafa;
  text-align: center;
}
.confirmation-mask .card-footer button {
  padding: 10px 30px;
}
.card-footer button:last-child {
  margin-left: 20px;
}
.card-header p {
  font-size: 18px;
  color: #575757;
  margin-bottom: 25px;
}
.v-stepper.getStarted .confirmation-mask .card-footer .elevation-0.v-btn {
  min-width: 40% !important;
  width: 40% !important;
}
</style>
