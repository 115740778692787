<template>
    <transition name="fade">
            <!-- <div id="add-email-template-wrapper">
                <div id="add-email-template-box">
                    
                </div>
            </div> -->
        <v-container fill-height id="add-new-user-container">
            <v-layout align-center justify-center>
                <v-flex xs10 sm8 md6 class="add-new-user-card-wrapper">
                        <span class="close_btn" @click="cancel">&#10005;</span>
                    <v-card class="elevation-4" id="add-new-user-card" v-bind:style="{ height: cardHeight }">
                        <v-card-text ref="userCard">
                            <h1>{{userHd}}</h1>
                            <v-form ref="form" v-model="valid" lazy-validation>
                                <v-text-field color="#777BD2" outlined dense name="f_name" label="First Name" type="text" v-model="f_name" required :rules="nameRules"></v-text-field>

                                <v-text-field color="#777BD2" outlined dense name="l_name" label="Last Name" type="text" required v-model="l_name" :rules="nameRules"></v-text-field>

                                <v-text-field color="#777BD2" outlined dense name="email" label="Email" type="email" required v-model="email" :rules="emailRules" :disabled="fieldDisabled"></v-text-field>

                                <v-text-field color="#777BD2" outlined dense name="temporary_password" label="Temporary Password" type="text" required v-model="temporary_password" :rules="passwordRules" v-if="!fieldDisabled"></v-text-field>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn elevation="0" id="cancelUserBtn" @click="cancel" style="margin-left:auto">Cancel</v-btn>
                            <v-btn elevation="0" id="addUserBtn" color="primary" @click="submit" :disabled="userActionBtnDisabled">
                                <v-progress-circular
                                    indeterminate
                                    color="#fff"
                                    v-if="userActionInProgress"
                                ></v-progress-circular>
                                <span id="userPrimaryBtnText" v-if="!userActionInProgress">{{add_user_primary_btn_txt}}</span>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>    
    </transition>
</template>
<script>
export default {
    name: 'AddNewUser',
    data(){
        return{
            cardHeight: '500',
            user_id: '',
            f_name: '',
            l_name: '',
            email: '',
            role: '',
            temporary_password: '',
            valid: false,
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+/.test(v) || 'E-mail must be valid'
            ],
            passwordRules: [
                v => !!v || 'Password is required',
                v => v.length >= 6 ||
                    'Password must be greater than 6 characters',
                v => v.match(/[A-Z]/) != null || 'Password should have atleast one uppercase character',
                v => v.match(/[0-9]/) != null || 'Password should have atleast one numeric character',
                v => v.match(/[@$!%*#?&]/) != null || 'Password should have atleast one special character',
            ],
            nameRules: [
                v => !!v || 'Name is required',
                v => v.match(/^[A-za-z ]*$/) != null || 'Name should only contain alphabets'
            ]
        }
    },
    methods: {
        submit(){
            //var _this = this;
            this.$store.state.userActionInProgress = true;
            this.$store.state.userActionBtnDisabled = true;
            if (this.$refs.form.validate()) {
                if(this.add_user_primary_btn_txt == 'Add'){
                    
                    var addUserData = {
                        "email": this.email,
                        "password":this.temporary_password,
                        "role":"Moderator",
                        "phone_number":"",
                        "given_name":this.f_name,
                        "family_name":this.l_name
                    };
                    this.$store.dispatch('addUser', addUserData);
                }
                else if(this.add_user_primary_btn_txt == 'Update'){
                    var updateUserData = {
                        "email": this.email,
                        "role": this.role,
                        "phone_number":"",
                        "given_name":this.f_name,
                        "family_name":this.l_name
                    };
                    
                    this.$store.dispatch('updateUser', {updateUserData : updateUserData, userID : this.user_id});
                }
                else{
                    this.$store.state.responseMsg = 'Error: Could not perform action';
                    this.$store.state.ResponseClass = 'error';
                }
            }
            else{
                this.$store.state.userActionInProgress = false;
                this.$store.state.userActionBtnDisabled = false;
            }
        },
        cancel(){
            this.$store.state.addUserBox = false;
            this.$store.state.updateUserData = null;
        },
        onResize() {
            if((this.$refs.userCard.clientHeight + 40)> (window.innerHeight - 100)){
                this.cardHeight = (window.innerHeight - 100)+'px';
            }
            else{
                this.cardHeight = 'auto';
            }
        },
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
            });
        this.onResize();
        if(this.$store.state.updateUserData != null){
            var user = this.$store.state.updateUserData;
            this.user_id= user['Username'];
            this.f_name= user['Attributes'].find(e => e['Name'] == 'given_name')['Value'];
            this.l_name= user['Attributes'].find(e => e['Name'] == 'family_name')['Value'];
            this.email= user['Attributes'].find(e => e['Name'] == 'email')['Value'];
            this.role= user['Attributes'].find(e => e['Name'] == 'custom:role')['Value'];
        }
    },

    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize); 
    },
    computed:{
        fieldDisabled(){
            var x = false;
            if(this.add_user_primary_btn_txt == 'Add'){
                x = false;
            }
            else if(this.add_user_primary_btn_txt == 'Update'){
                x = true;
            }
            return x;
        },
        add_user_primary_btn_txt(){
            return this.$store.state.add_user_primary_btn_txt;
        },
        userHd(){
            return this.$store.state.userHd;
        },
        userActionInProgress(){
            return this.$store.state.userActionInProgress;
        },
        userActionBtnDisabled(){
            return this.$store.state.userActionBtnDisabled
        },
    }
}
</script>
<style scoped>
.theme--light.v-text-field--filled > .v-input__control > .v-input__slot{
    background: #fff !important;
}
#add-new-user-container{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    max-width: 100%;
    background-color: rgba(0,0,0,0.3);
}
#add-new-user-card{
    background-color: #FAFAFA;
    padding: 0 75px 40px;
    margin: 38px auto;
    overflow-y: auto;
}
#add-new-user-card h1{
    margin: 25px 0 40px;
    text-align:center;
    color: #000;
}
.v-card__actions{
    padding: 0 16px;
}
/* width */
#add-new-user-card::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#add-new-user-card::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
#add-new-user-card::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
#add-new-user-card::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.close_btn{
    position: absolute;
    right: 10px;
    top: 40px;
    z-index: 999;
    color: rgba(0,0,0,0.8);
    padding: 2px 10px;
    font-size: 20px;
    cursor: pointer;
    transition: 0.5s all;
}

.add-new-user-card-wrapper{
    position: relative;
}
.v-card__actions button{
    padding: 10px 30px !important;
    min-width: 150px !important;
}

</style>