import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";
import { Auth } from "aws-amplify";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    csv_data: null,
    user: null,
    temporaryUser: null,
    isAuthenticated: false,
    showHeader: false,
    showSubHeader: false,
    signinResponse: "",
    campaigns: null,
    csvUploadBox: false,
    saveCampaignInProgress: false,
    emailSendingInProgress: false,
    more_options_selected_campaign: null,
    responseMsg: "",
    campaignTitle: "",
    EmailTemplates: null,
    addEmailTemplateBox: false,
    primary_btn_txt: "",
    emailTemplateHd: "",
    templateName: "",
    templateFromName: "",
    templateFrom: "",
    templateSubject: "",
    templateBody: "",
    templateID: "",
    templateActionInProgress: false,
    templateActionBtnDisabled: false,
    campaignActionInProgress: false,
    campaignActionBtnDisabled: false,
    selectedID: "",
    gettingTemplatesInProgress: false,
    gettingCampaignsInProgress: false,
    campaignData: null,
    campaignTemplate: "",
    campaignDate: "",
    loading: false,
    dialogId: "",
    archiveDialogId: "",
    ResponseClass: "",
    responseData: "",
    TemplateData: "",
    signinActionInProgress: false,
    signinActionBtnDisabled: false,
    unSubscribers: "",
    gettingUsersInProgress: false,
    allUsers: null,
    sendEmailData: "",
    updatePasswordInProgress: false,
    forgotPasswordInProgress: false,
    verificationCodeInProgress: false,
    add_user_primary_btn_txt: "",
    userHd: "",
    userActionBtnDisabled: false,
    userActionInProgress: false,
    userID: "",
    addUserBox: false,
    selectedUserID: "",
    // sentCampaigns: [],
    // sentCampaignsDate: [],
    // sentEmails: [],
    // openedEmails: [],
    // sentEmailsDate: [],
    dashboardLoading: false,
    updateUserData: null,
    rulesActionInProgress: false,
    gettingRule: false,
    Rule: {
      user_email: "",
      default_template_name: "",
      global_send_time: "00:00",
      sheet_name: "",
      sheet_id: "",
      sheet_url: "",
      selected_rule_type: "CITY",
      city_wise_rule_data: [
        {
          city: "Plano",
          template_name: ""
        },
        {
          city: "Frisco",
          template_name: ""
        },
        {
          city: "Dallas Texas",
          template_name: ""
        },
        {
          city: "McKinney",
          template_name: ""
        },
        {
          city: "Allen",
          template_name: ""
        }
      ],
      categegory_wise_rule_data: []
    },
    googleSheetCalendarData: [],
    googleSheetCalendarDates: [],
    googleSheetCalendarEventDates: [],
    googleSheetCalendarTime: "00:00",
    stepperCampaignTemplate: "",
    stepperCampaignName: "",
    templateReplyTo: "",
    routeConfirmationDialog: false,
    routeChangeMsg: "",
    routeTo: "",
    routeChangeFlag: false,
    JWTaccessToken: "",
    emailTemplateAttachment: [],
    emailTemplateAttachmentName: "",
    campaignUploadStep: 1,
    UploadedFileName: "",
    fileUploaded: false,
    currentFileUploadID: null,
    pageLoading: false,
    rememberMeCheckbox: false,
    SendEmailConfirmation: false,
    campaignSingleDate: null,
    // yearSentCampaigns: [],
    // yearSentLabel: [],
    // yearSentEmails: [],
    // yearOpenedEmails: [],

    // monthSentCampaigns: [],
    // monthSentLabel: [],
    // monthSentEmails: [],
    // monthOpenedEmails: [],

    thisWeekData: null,
    thisMonthData: null,
    thisYearData: null,
    singleCampaignPagination: 1,
    singleItemsPerPage: 10,
    singleTotalPageNumber: 1,
    getSubscriptionStatusInProgress: false,
    deleteInProgress: false,
    archiveInProgress: false,
    bulkActionInProgress: false,
    automationRule: null,
    fetching: false,
    datePicker: null,
    timePicker: '00:00',
    frequency: {text:'', value:''},
    sheetURL: "",
    ruleName: "",
    sheetID: "",
    automationCreateBtn: false,
    automationUpdateBtn: false,
    ruleSelectedID: null,
    sheetVerified: false,
    verifyingGoogleSheet: false,
    stepperPagination: 1,
  },
  mutations: {
    setEmptyfield(state) {
      state.stepperPagination = 1;
      localStorage.setItem("remember-", null);
      state.csv_data = null;
      state.user = null;
      state.isAuthenticated = false;
      /* state.showHeader= false;
      state.showSubHeader= false; */
      state.signinResponse = "";
      state.campaigns = null;
      state.csvUploadBox = false;
      state.saveCampaignInProgress = false;
      state.emailSendingInProgress = false;
      state.more_options_selected_campaign = null;
      state.responseMsg = "";
      state.campaignTitle = "";
      state.EmailTemplates = null;
      state.addEmailTemplateBox = false;
      state.primary_btn_txt = "";
      state.emailTemplateHd = "";
      state.templateName = "";
      state.templateFromName = "";
      state.templateReplyTo = "";
      state.templateFrom = "";
      state.templateSubject = "";
      state.templateBody = "";
      state.emailTemplateAttachment = [];
      state.emailTemplateAttachmentName = "";
      state.templateID = "";
      state.templateActionInProgress = false;
      state.templateActionBtnDisabled = false;
      state.campaignActionInProgress = false;
      state.campaignActionBtnDisabled = false;
      state.selectedID = "";
      state.gettingTemplatesInProgress = false;
      state.gettingCampaignsInProgress = false;
      state.campaignData = null;
      state.campaignTemplate = "";
      state.campaignDate = "";
      state.loading = false;
      state.dialogId = "";
      state.archiveDialogId = "";
      state.ResponseClass = "";
      state.responseData = "";
      state.TemplateData = "";
      state.signinActionInProgress = false;
      state.signinActionBtnDisabled = false;
      state.unSubscribers = "";
      state.gettingUsersInProgress = false;
      state.allUsers = null;
      state.sendEmailData = "";
      state.rulesActionInProgress = "";
      state.updatePasswordInProgress = false;
      state.forgotPasswordInProgress = false;
      state.verificationCodeInProgress = false;
      state.add_user_primary_btn_txt = "";
      state.userHd = "";
      state.userActionBtnDisabled = false;
      state.userActionInProgress = false;
      state.userID = "";
      state.addUserBox = false;
      state.selectedUserID = "";
      // state.sentCampaigns = [];
      // state.sentCampaignsDate = [];
      // state.sentEmails = [];
      // state.sentEmailsDate = [];
      state.dashboardLoading = false;
      state.updateUserData = null;
      state.rulesActionInProgress = false;
      state.gettingRule = false;
      state.automationRule = null;
      state.Rule = {
        user_email: "",
        default_template_name: "",
        global_send_time: "00:00",
        sheet_name: "",
        sheet_id: "",
        sheet_url: "",
        selected_rule_type: "CITY",
        city_wise_rule_data: [
          {
            city: "Plano",
            template_name: ""
          },
          {
            city: "Frisco",
            template_name: ""
          },
          {
            city: "Dallas Texas",
            template_name: ""
          },
          {
            city: "McKinney",
            template_name: ""
          },
          {
            city: "Allen",
            template_name: ""
          }
        ],
        categegory_wise_rule_data: []
      };
      state.googleSheetCalendarData = [];
      state.googleSheetCalendarDates = [];
      state.googleSheetCalendarEventDates = [];
      state.googleSheetCalendarTime = "00:00";
      state.stepperCampaignTemplate = "";
      state.stepperCampaignName = "";
      state.templateReplyTo = "";
      state.campaignUploadStep = 1;

      // state.yearSentCampaigns = [];
      // state.yearSentLabel = [];
      // state.yearSentEmails = [];
      // state.yearOpenedEmails= [],
      // state.monthOpenedEmails=[],

      // state.monthSentCampaigns = [];
      // state.monthSentLabel = [];
      // state.monthSentEmails = [];
      state.thisWeekData = null;
      state.thisMonthData = null;
      state.thisYearData = null;
      state.singleCampaignPagination = 1;
      state.singleItemsPerPage = 10;
      state.singleTotalPageNumber = 1;
      state.getSubscriptionStatusInProgress = false;
      state.deleteInProgress = false;
      state.archiveInProgress = false;
      state.sheetURL = "";
      state.ruleName = "";
      state.sheetID = "";
      state.ruleSelectedID = null;
      state.sheetVerified = false;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setTemporaryUser(state, payload) {
      state.temporaryUser = payload;
    },
    setIsAuthenticated(state, payload) {
      state.isAuthenticated = payload;
    },
    /* setHeaders(state, payload) {
      state.showHeader = payload;
      state.showSubHeader = payload;
    }, */
    setSigninResponse(state, payload) {
      state.signinResponse = payload;
    },
    setCampaigns(state, payload) {
      state.campaigns = payload;
    },
    sendingEmail(state, payload) {
      state.emailSendingInProgress = payload;
    },
    setSaveCampaignInProgress(state, payload) {
      state.saveCampaignInProgress = payload;
    },
    setTemplateActionInProgress(state, payload) {
      state.templateActionBtnDisabled = payload;
      state.templateActionInProgress = payload;
    },
    setUserActionInProgress(state, payload) {
      state.userActionBtnDisabled = payload;
      state.userActionInProgress = payload;
    },
    setCampaignActionInProgress(state, payload) {
      state.campaignActionBtnDisabled = payload;
      state.campaignActionInProgress = payload;
    },
    setSignInActionInProgress(state, payload) {
      state.signinActionBtnDisabled = payload;
      state.signinActionInProgress = payload;
    },
    setUpdatePasswordInProgress(state, payload) {
      state.updatePasswordInProgress = payload;
    },
    setResponseMessage(state, payload) {
      state.responseMsg = payload;
    },
    setResponseClass(state, payload) {
      state.ResponseClass = payload;
    },
    setResponseData(state, payload) {
      state.responseData = payload;
    },
    setEmailTemplates(state, payload) {
      state.EmailTemplates = payload;
    },
    showAddEmailTemplateBox(state, payload) {
      state.addEmailTemplateBox = payload;
    },
    setSelectedID(state, payload) {
      state.SelectedID = payload;
    },
    setGettingTemplatesInProgress(state, payload) {
      state.gettingTemplatesInProgress = payload;
    },
    setGettingCampaignsInProgress(state, payload) {
      state.gettingCampaignsInProgress = payload;
    },
    setCampaignData(state, payload) {
      state.campaignData = payload;
    },
    setCampaignTemplate(state, payload) {
      state.campaignTemplate = payload;
    },
    setCampaignTitle(state, payload) {
      state.campaignTitle = payload;
    },
    setCampaignDate(state, payload) {
      state.campaignDate = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setUnsubscribers(state, payload) {
      state.unSubscribers = payload;
    },
    setGettingUsersInProgress(state, payload) {
      state.gettingUsersInProgress = payload;
    },
    setForgotPasswordInProgress(state, payload) {
      state.forgotPasswordInProgress = payload;
    },
    setVerificationCodeInProgress(state, payload) {
      state.verificationCodeInProgress = payload;
    },
    setAllUsers(state, payload) {
      state.allUsers = payload;
    },
    resetEmailTemplateBox(state) {
      state.templateName = "";
      state.templateFromName = "";
      state.templateSubject = "";
      state.templateBody = "";
      state.emailTemplateAttachment = [];
      state.emailTemplateAttachmentName = "";
      state.templateID = "";
      state.templateReplyTo = "";
    },
    showAddUserBox(state, payload) {
      state.addUserBox = payload;
    },
    setThisWeekData(state, payload) {
      state.thisWeekData = payload;
    },
    setThisMonthData(state, payload) {
      state.thisMonthData = payload;
    },
    setThisYearData(state, payload) {
      state.thisYearData = payload;
    },
    // setSentCampaigns(state, payload) {
    //   state.sentCampaigns = payload;
    // },
    // setSentCampaignsDate(state, payload) {
    //   state.sentCampaignsDate = payload;
    // },
    // setSentEmails(state, payload) {
    //   state.sentEmails = payload;
    // },
    // setSentEmailsDate(state, payload) {
    //   state.sentEmailsDate = payload;
    // },
    // setOpenedEmails(state, payload){
    //   state.openedEmails = payload;
    // },

    // setYearSentCampaigns(state, payload) {
    //   state.yearSentCampaigns = payload;
    // },
    // setYearSentLabel(state, payload) {
    //   state.yearSentLabel = payload;
    // },
    // setYearSentEmails(state, payload) {
    //   state.yearSentEmails = payload;
    // },
    // setYearOpenedEmails(state, payload) {
    //   state.yearOpenedEmails = payload;
    // },

    // setMonthSentCampaigns(state, payload) {
    //   state.monthSentCampaigns = payload;
    // },
    // setMonthSentLabel(state, payload) {
    //   state.monthSentLabel = payload;
    // },
    // setMonthSentEmails(state, payload) {
    //   state.monthSentEmails = payload;
    // },

    // setMonthOpenedEmails(state, payload) {
    //   state.monthOpenedEmails = payload;
    // },

    setDashboardLoading(state, payload) {
      state.dashboardLoading = payload;
    },
    resetUserBox(state) {
      state.updateUserData = null;
    },
    setRulesActionInProgress(state, payload) {
      state.rulesActionInProgress = payload;
    },
    setGettingRule(state, payload) {
      state.gettingRule = payload;
    },
    setRule(state, payload) {
      state.Rule = payload;
    },
    setGoogleSheetCalendarData(state, payload) {
      state.googleSheetCalendarData = payload;
    },
    setGoogleSheetCalendarDates(state, payload) {
      state.googleSheetCalendarDates = payload;
    },
    setGoogleSheetCalendarEventDates(state, payload) {
      state.googleSheetCalendarEventDates = payload;
    },
    setGoogleSheetCalendarTime(state, payload) {
      state.googleSheetCalendarTime = payload;
    },
    setCSVdata(state, payload) {
      state.csv_data = payload;
    },
    resetRule(state) {
      state.Rule = {
        user_email: "",
        default_template_name: "",
        global_send_time: "00:00",
        sheet_name: "",
        sheet_id: "",
        sheet_url: "",
        selected_rule_type: "CITY",
        city_wise_rule_data: [
          {
            city: "Plano",
            template_name: ""
          },
          {
            city: "Frisco",
            template_name: ""
          },
          {
            city: "Dallas Texas",
            template_name: ""
          },
          {
            city: "McKinney",
            template_name: ""
          },
          {
            city: "Allen",
            template_name: ""
          }
        ],
        categegory_wise_rule_data: []
      };
      state.googleSheetCalendarData = [];
      state.googleSheetCalendarDates = [];
      state.googleSheetCalendarEventDates = [];
    },
    setStepperCampaignTemplate(state, payload) {
      state.stepperCampaignTemplate = payload;
    },
    setStepperCampaignName(state, payload) {
      state.stepperCampaignName = payload;
    },
    resetCampaignData(state) {
      state.csv_data = null;
      state.stepperCampaignTemplate = "";
      state.stepperCampaignName = "";
      state.UploadedFileName = "";
      state.fileUploaded = false;
      state.currentFileUploadID = null;
      state.campaignUploadStep = 1;
      state.getSubscriptionStatusInProgress = false;
      if (document.getElementById("browse-csv-file")) {
        document.getElementById("browse-csv-file").value = "";
        document.getElementById("csv-file").value = "";
        document.getElementById("csv-file-name").innerHTML = "";
      }
    },
    setAutomationRule(state, payload){
      state.automationRule = payload;
    },
    setFetching(state, payload){
      state.fetching = payload;
    }
  },
  actions: {
    nullAll({ commit }) {
      commit("setEmptyfield");
      commit("resetCampaignData");
      commit("setUser", null);
      commit("setIsAuthenticated", false);
    },
    userLogin({ commit }, { email, password, remember_me }) {
      Auth.signIn(email, password)
        .then(user => {
          if (remember_me == true) {
            var rememberObject = {
              remember_me: "true",
              user: user.attributes.email
            };
            localStorage.setItem("remember-", JSON.stringify(rememberObject));
          }
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            router.push({
              name: "Update Password",
              params: { temporaryUser: user }
            });
            commit("setSignInActionInProgress", false);
          } else {
            commit("setUser", user);
            commit("setIsAuthenticated", true);
            //commit('setHeaders', true);
            commit("setSignInActionInProgress", false);
            router.push({ path: "/" });
          }
        })
        .catch(error => {
          commit("setResponseMessage", error.message);
          commit("setResponseClass", "red lighten-2");
          //localStorage.setItem("user", null);
          commit("setUser", null);
          commit("setIsAuthenticated", false);
          commit("setSignInActionInProgress", false);
          setTimeout(function() {
            commit("setResponseMessage", "");
            commit("setResponseClass", "");
          }, 5000);
        });
    },
    updatePassword({ commit }, { user, newPassword }) {
      Auth.completeNewPassword(
        user,
        newPassword
        // eslint-disable-next-line
      ).then(user => {
          // at this time the user is logged in if no MFA required
          commit("setUpdatePasswordInProgress", false);
          commit(
            "setResponseMessage",
            "Password updated successfully. Kindly signin again."
          );
          commit("setResponseClass", "green lighten-2");
          setTimeout(function() {
            commit("setResponseMessage", "");
            commit("setResponseClass", "");
          }, 5000);
          router.push({ path: "/sign-in" });
        })
        .catch(error => {
          if (error.response) {
            commit("setResponseMessage", error.response.data);
          } else {
            commit("setResponseMessage", error.message);
          }
          commit("setResponseClass", "red lighten-2");
          setTimeout(function() {
            commit("setResponseMessage", "");
            commit("setResponseClass", "");
          }, 4000);
        });
    },
    userSignOut({ commit }) {
      Auth.signOut({ global: true })
        .then(() => {
          localStorage.removeItem("remember-");
          commit("setEmptyfield");
          commit("resetCampaignData");
          commit("setUser", null);
          commit("setIsAuthenticated", false);
          //commit('setHeaders', false);
          router.push({ path: "/sign-in" });
        })
        .catch(err => {
          localStorage.removeItem("remember-");
          commit("setEmptyfield");
          commit("resetCampaignData");
          commit("setUser", null);
          commit("setIsAuthenticated", false);
          //commit('setHeaders', false);
          router.push({ path: "/sign-in" });
          console.log(err.message);
        });
    },
    getUserLoginStatus({ commit }, cacheStatus) {
      Auth.currentAuthenticatedUser({
        bypassCache: cacheStatus // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      })
        .then(user => {
          commit("setUser", user);
        })
        .catch(err => {
          commit("setUser", null);
          console.log(err.message);
        });
    },
    userCurrentSession() {
      Auth.currentSession()
        .then(data => console.log(data))
        .catch(err => console.log(err.message));
    },
    forgotPasswordSendCode({ commit }, { username }) {
      Auth.forgotPassword(username)
        // eslint-disable-next-line
      .then(data => {
          console.log(data);
          commit("setResponseMessage", "Code sent successfully on your email.");
          commit("setResponseClass", "green lighten-2");
          commit("setVerificationCodeInProgress", false);
          setTimeout(function() {
            commit("setResponseMessage", "");
            commit("setResponseClass", "");
          }, 4000);
        })
        .catch(err => {
          if (err.code == "UserNotFoundException") {
            commit("setResponseMessage", "Please enter a valid Email address.");
          } else {
            commit("setResponseMessage", err.message);
          }
          commit("setResponseClass", "red lighten-2");
          setTimeout(function() {
            commit("setResponseMessage", "");
            commit("setResponseClass", "");
          }, 4000);
          commit("setVerificationCodeInProgress", false);
        });
    },
    forgotPasswordSubmit({ commit }, { username, code, new_password }) {
      Auth.forgotPasswordSubmit(username, code, new_password)
        // eslint-disable-next-line
      .then(data => {
          commit("setForgotPasswordInProgress", false);
          commit("setResponseMessage", "Password reset successfully.");
          commit("setResponseClass", "red lighten-2");
          router.push({ path: "/sign-in" });
          setTimeout(function() {
            commit("setResponseMessage", "");
            commit("setResponseClass", "red lighten-2");
          }, 6000);
        })
        .catch(err => {
          commit("setForgotPasswordInProgress", false);
          console.log(err.message);
          commit("setResponseMessage", "Password reset failed. Try again.");
          commit("setResponseClass", "error");
        });
    },
    getUserLoginStatusAfterUserDelete({ commit, dispatch }, cacheStatus) {
      Auth.currentAuthenticatedUser({
        bypassCache: cacheStatus // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      })
        .then(user => {
          commit("setUser", user);
        })
        .catch(err => {
          dispatch("userSignOut");
          console.log(err.message);
        });
    },
    bulkAction({commit, dispatch, state}, data){
      axios.post("https://api.mailshipp.com/batches/batch_update", data)
      .then(function(response) {
        console.log(response)
        state.gettingCampaignsInProgress = true;
        if(data[0].action == 'a'){
          commit("setResponseMessage", "Selected campaigns archived successfully!");
        }
        else if(data[0].action == 'd'){
          commit("setResponseMessage", "Selected campaigns deleted successfully!");
        }
        else if(data[0].action == 'u'){
          commit("setResponseMessage", "Selected campaigns un-archived successfully!");
        }
        else{
          commit("setResponseMessage", "Selected campaigns updated successfully!");
        }
        commit("setResponseClass", "success");
        console.log(router);
        if(router.history.current.name == "Archived Campaigns"){
          dispatch("getArchivedCampaigns", {
            user: state.user.attributes.email,
            status: "",
            archived: true
          });
        }else{
          dispatch("getCampaigns", {
            user: state.user.attributes.email,
            status: ""
          });
        }
        state.bulkActionInProgress = false
      }).catch(function(error){
        if (error.response) {
          commit("setResponseMessage", error.response.data);
        } else {
          commit("setResponseMessage", error.message);
        }
        commit("setResponseClass", "error");
        state.bulkActionInProgress = false;
      });
    },
    getCampaigns({ commit }, user) {
      axios
        .post("https://api.mailshipp.com/batches/filteredlist", user)
        .then(function(response) {
          function compare(a, b) {
            if (a.updatedAt < b.updatedAt) return -1;
            if (a.updatedAt > b.updatedAt) return 1;
            return 0;
          }
          response.data.sort(compare).reverse();
          commit("setGettingCampaignsInProgress", false);
          commit("setCampaigns", response);
        })
        .catch(function(error) {
          if (error.response) {
            commit("setResponseMessage", error.response.data);
          } else {
            commit("setResponseMessage", error.message);
          }
          commit("setResponseClass", "error");
        });
    },
    getArchivedCampaigns({ commit }, user) {
      axios
        .post("https://api.mailshipp.com/batches/filteredlist", user)
        .then(function(response) {
          function compare(a, b) {
            if (a.updatedAt < b.updatedAt) return -1;
            if (a.updatedAt > b.updatedAt) return 1;
            return 0;
          }
          response.data.sort(compare).reverse();
          commit("setGettingCampaignsInProgress", false);
          commit("setCampaigns", response);
        })
        .catch(function(error) {
          if (error.response) {
            commit("setResponseMessage", error.response.data);
          } else {
            commit("setResponseMessage", error.message);
          }
          commit("setResponseClass", "error");
        });
    },
    getCampaignData({ commit, state }, campaignID) {
      axios
        .get("https://api.mailshipp.com/batches/" + campaignID)
        .then(function(response) {
          commit("setCampaignData", response);
          commit("setCampaignDate", response.data.updatedAt);
          commit("setCampaignTemplate", response.data.template_name);
          commit("setCampaignTitle", response.data.name);
          var dataLength = response.data.clients.length;
          var itemsPerPage = state.singleItemsPerPage; // is number of items per page
          var x = dataLength / itemsPerPage;
          var totalPageNumber;
          if (x >= 1) {
            if (dataLength % 10 > 0) {
              totalPageNumber = Math.floor(dataLength / 10) + 1;
            } else {
              totalPageNumber = Math.floor(dataLength / 10);
            }
          } else {
            totalPageNumber = 1;
          }
          state.singleTotalPageNumber = totalPageNumber;
          state.gettingcampaignInProgress = false;
          commit("setLoading", false);
        })
        .catch(function(error) {
          if (error.response) {
            commit("setResponseMessage", error.response.data);
          } else {
            commit("setResponseMessage", error.message);
          }
          commit("setResponseClass", "error");
          //commit('setLoading', false);
        });
    },
    saveCampaign({ commit, dispatch, state }, createCampaignData) {
      axios
        .post("https://api.mailshipp.com/batches", createCampaignData)
        .then(function(response) {
          if (response.status == 401) {
            state.campaignTitle = "";
            commit(
              "setResponseMessage",
              "All uploaded emails are Unsubscribed and campaign was not created."
            );
            commit("setResponseClass", "error");
          } else {
            state.campaignTitle = "";
            commit("setCSVdata", null);
            var scheduledDate, scheduledTime, hrs, mins, ampm;
            if (response.data.send_time) {
              scheduledDate =
                new Date(response.data.send_time).getMonth() +
                1 +
                "/" +
                new Date(response.data.send_time).getDate() +
                "/" +
                new Date(response.data.send_time).getFullYear();
              scheduledTime = new Date(response.data.send_time);
              if (scheduledTime.getHours() < 10) {
                hrs = "0" + scheduledTime.getHours();
              } else {
                hrs = scheduledTime.getHours();
              }
              if (scheduledTime.getMinutes() < 10) {
                mins = "0" + scheduledTime.getMinutes();
              } else {
                mins = scheduledTime.getMinutes();
              }

              if (hrs < 12) {
                ampm = "AM";
              } else {
                ampm = "PM";
                if (hrs > 12) {
                  hrs = hrs - 12;
                }
              }
              scheduledTime = hrs + ":" + mins + " " + ampm;
            }
            commit(
              "setResponseMessage",
              "Campaign scheduled successfully on " +
                scheduledDate +
                " at " +
                scheduledTime +
                "."
            );
            commit("setResponseClass", "success");
            var sendEmailData = {
              user: response.data.user,
              batch_id: response.data.id,
              template_name: response.data.template_name
            };
            commit("setResponseData", sendEmailData);
            state.gettingCampaignsInProgress = true;
            dispatch("getCampaigns", {
              user: state.user.attributes.email,
              status: ""
            });
            commit("setSaveCampaignInProgress", false);
            commit("resetCampaignData");
            state.campaignUploadStep = 1;
            router.push({ path: "/all-campaigns" });
          }
        })
        .catch(function(error) {
          if (error.response) {
            commit("setResponseMessage", error.response.data);
          } else {
            commit("setResponseMessage", error.message);
          }
          commit("setResponseClass", "error");
          commit("resetCampaignData");
          state.campaignUploadStep = 1;
          commit("setSaveCampaignInProgress", false);
        });
    },
    sendEmails({ commit, dispatch, state }, sendEmailData) {
      var startTime = new Date().getTime();
      axios
        .post("https://api.mailshipp.com/batches/send", sendEmailData)
        .then(function(response) {
          console.log(response);
          commit("setResponseMessage", "Campaign emails sent successfully.");
          commit("setResponseClass", "success");
          commit("setResponseData", "");
          state.gettingCampaignsInProgress = true;
          dispatch("getCampaigns", {
            user: state.user.attributes.email,
            status: ""
          });
          commit("setCSVdata", null);
          commit("sendingEmail", false);
          state.SendEmailConfirmation = false;
          commit("setSaveCampaignInProgress", false);
          if (router.name == "Campaign Detail") {
            router.go(-1);
            commit("resetCampaignData");
            state.campaignUploadStep = 1;
          } else {
            commit("resetCampaignData");
            state.campaignUploadStep = 1;
          }
        })
        .catch(function(error) {
          var endTime = new Date().getTime();
          if (endTime - startTime <= 6000) {
            if (error.response) {
              commit("setResponseMessage", error.response.data);
            } else {
              commit("setResponseMessage", error.message);
            }
            commit("setResponseClass", "error");
            commit("setResponseData", "");
            commit("sendingEmail", false);
            commit("setSaveCampaignInProgress", false);
            state.SendEmailConfirmation = false;
            commit("resetCampaignData");
            state.campaignUploadStep = 1;
          } else {
            commit(
              "setResponseMessage",
              "Campaign emails sending in progress. Campaign status will be updated once all emails are sent."
            );
            commit("setResponseClass", "success");
            commit("setResponseData", "");
            dispatch("getCampaigns", {
              user: state.user.attributes.email,
              status: ""
            });
            commit("setCSVdata", null);
            commit("sendingEmail", false);
            state.SendEmailConfirmation = false;
            commit("setSaveCampaignInProgress", false);
            if (router.name == "Campaign Detail") {
              router.go(-1);
              commit("resetCampaignData");
              state.campaignUploadStep = 1;
            } else {
              commit("resetCampaignData");
              state.campaignUploadStep = 1;
            }
          }
        });
    },
    sendAndSaveCampaignEmails({ commit, dispatch, state }, createCampaignData) {
      axios
        .post("https://api.mailshipp.com/batches", createCampaignData)
        .then(function(response) {
          if (response.status == 401) {
            state.campaignTitle = "";
            commit(
              "setResponseMessage",
              "All uploaded emails are Un-subscribed and campaign was not created."
            );
            commit("setResponseClass", "Error");
          } else {
            var sendEmailData = {
              user: response.data.user,
              batch_id: response.data.id,
              template_name: response.data.template_name
            };
            dispatch("sendEmails", sendEmailData);
          }
        })
        .catch(function(error) {
          if (error.response) {
            commit("setResponseMessage", error.response.data);
          } else {
            commit("setResponseMessage", error.message);
          }
          commit("setResponseClass", "error");
          commit("setSaveCampaignInProgress", false);
          commit("resetCampaignData");
          state.SendEmailConfirmation = false;
          state.campaignUploadStep = 1;
        });
    },
    cancelScheduledCampaign({ commit }, campaignID) {
      axios
        .get("https://api.mailshipp.com/batches/cancel_schedule/" + campaignID)
        .then(function(response) {
          console.log(response);
          commit("setResponseMessage", "Campaign is unscheduled successfully.");
          commit("setResponseClass", "success");
          commit("setSaveCampaignInProgress", false);
          router.go(-1);
        })
        .catch(function(error) {
          if (error.response) {
            commit("setResponseMessage", error.response.data);
          } else {
            commit("setResponseMessage", error.message);
          }
          commit("setResponseClass", "error");
        });
    },
    setAutomationRule({ commit, dispatch, state }, userJson){
      axios
        .post("https://api.mailshipp.com/automation/", userJson)
        .then(function(response) {
          console.log("r:"+ JSON.stringify(response));
          state.stepperPagination = 1;
          state.stepperCampaignTemplate = null;
          state.sheetURL = null;
          state.ruleName = null;
          state.sheetID = null;
          state.timePicker = '00:00';
          state.datePicker = '';
          state.frequency = '';
          
          commit("setResponseMessage", "Rule saved successfully.");
          var ruleID = response.data.id;
          commit("setResponseData", ruleID);

          if(response.data.frequency.toString().toLowerCase() == "none"){
            commit("setResponseClass", "info hasAction");
          }else{
            commit("setResponseClass", "info hasAction");
          }

          dispatch("getAutomationRule", state.user.attributes.email);
          commit("setRulesActionInProgress", false);
        })
        .catch(function(error) {
          if (error.response) {
            commit("setResponseMessage", error.response.data);
          } else {
            commit("setResponseMessage", error.message);
          }
          commit("setResponseClass", "error");
          commit("setRulesActionInProgress", false);
        });
    },
    sendNowAutomatedEmail({ commit }, sendEmailData){
      axios
        .get("https://api.mailshipp.com/automation/send_now/" + sendEmailData)
        .then(function(response) {
          console.log(response);
          commit("setResponseMessage", "Emails are sent successfully.");
          commit("setResponseClass", "success");
          commit("sendingEmail", false);
        })
        .catch(function(error) {
          if (error.response) {
            commit("setResponseMessage", error.response.data);
          } else {
            commit("setResponseMessage", error.message);
          }
          commit("setResponseClass", "error");
          commit("sendingEmail", false);
        });
    },
    updateAutomationRule({ commit, dispatch, state }, {ruleID, userJson}){
      axios
        .put("https://api.mailshipp.com/automation/"+ruleID, userJson)
        .then(function(response) {
          console.log(response);
          commit("setResponseMessage", "Rule updated successfully.");
          commit("setResponseClass", "success");
          dispatch("getAutomationRule", state.user.attributes.email);
          commit("setRulesActionInProgress", false);
          state.ruleSelectedID = null;
        })
        .catch(function(error) {
          if (error.response) {
            commit("setResponseMessage", error.response.data);
          } else {
            commit("setResponseMessage", error.message);
          }
          commit("setResponseClass", "error");
          commit("setRulesActionInProgress", false);
        });
    },
    getAutomationRule({ commit, state }, userEmail){
      axios
        .get("https://api.mailshipp.com/automation/" + userEmail)
        .then(function(response) {
          commit("setAutomationRule", response.data);
          state.automationCreateBtn = true;
          commit('setFetching', false);
        })
        .catch(function(error) {
          if (error.response) {
            commit("setResponseMessage", error.response.data);
          } else {
            commit("setResponseMessage", error.message);
          }
          commit("setResponseClass", "error");
          commit('setFetching', false);
        });
    },
    googleSheetExist({ commit, state }, sheetID){
      axios
        .get("https://api.mailshipp.com/schedules/does_exist/" + sheetID)
        .then(function(response) {
          console.log(response);
          state.sheetVerified = true;
          commit("setResponseMessage", "Google sheet verified successfully");
          commit("setResponseClass", "success");
          state.verifyingGoogleSheet = false;
        })
        .catch(function(error) {
          console.log(error);
          commit("setResponseMessage", "Sheet does not exist or you have not given permissions for that sheet (Share sheet with service account provided.)");
          commit("setResponseClass", "error");
          state.verifyingGoogleSheet = false;
        });
    },
    deleteAutomationRule({ commit, dispatch, state}, item){
      axios
        .delete("https://api.mailshipp.com/automation/" + item.id)
        .then(function(response) {
          console.log(response)
          commit("setResponseMessage", "Rule deleted successfully");
          commit("setResponseClass", "success");
          state.dialogId = "";
          state.deleteInProgress = false;
          commit("setSelectedID", "");
          dispatch("getAutomationRule", state.user.attributes.email);
        })
        .catch(function(error) {
          if (error.response) {
            commit("setResponseMessage", error.response.data);
          } else {
            commit("setResponseMessage", error.message);
          }
          commit("setResponseClass", "error");
        });
    },
    getEmailTemplates({ commit }, user) {
      axios
        .get("https://api.mailshipp.com/templates/templatesbyemail/" + user)
        .then(function(response) {
          function compare(a, b) {
            if (a.updatedAt < b.updatedAt) return -1;
            if (a.updatedAt > b.updatedAt) return 1;
            return 0;
          }
          response.data.sort(compare).reverse();

          commit("setEmailTemplates", response);
          commit("setGettingTemplatesInProgress", false);
        })
        .catch(function(error) {
          if (error.response) {
            commit("setResponseMessage", error.response.data);
          } else {
            commit("setResponseMessage", error.message);
          }
          commit("setResponseClass", "error");
        });
    },
    addEmailTemplate({ commit, dispatch, state }, createTempalteData) {
      axios
        .post("https://api.mailshipp.com/templates", createTempalteData)
        .then(function(response) {
          commit("showAddEmailTemplateBox", false);
          commit("resetEmailTemplateBox");
          commit("setResponseMessage", "Email template created successfully.");
          commit("setResponseClass", "success");
          commit("setTemplateActionInProgress", false);
          document.querySelector("#emailTemplatePrimaryBtnText").innerHTML =
            state.primary_btn_txt;
          dispatch("getEmailTemplates", state.user.attributes.email);
          commit("setStepperCampaignTemplate", response.data.name);
          if (document.querySelector("#templateRadio .active")) {
            document
              .querySelector("#templateRadio .active")
              .classList.remove("active");
            setTimeout(function() {
              document
                .querySelector("#templateRadio .v-item--active")
                .parentNode.classList.add("active");
            }, 100);
          } else if (document.querySelector("#templateRadio .v-item--active")) {
            setTimeout(function() {
              document
                .querySelector("#templateRadio .v-item--active")
                .parentNode.classList.add("active");
            }, 100);
          }
        })
        .catch(function(error) {
          commit("setResponseMessage", error.response.data.message.message);
          commit("setResponseClass", "error");
          commit("setTemplateActionInProgress", false);
          document.querySelector("#emailTemplatePrimaryBtnText").innerHTML =
            state.primary_btn_txt;
        });
    },
    updateEmailTemplate(
      { commit, dispatch, state },
      { updateTempalteData, templateID }
    ) {
      axios
        .put(
          "https://api.mailshipp.com/templates/" + templateID,
          updateTempalteData
        )
        .then(function(response) {
          console.log(response);
          commit("showAddEmailTemplateBox", false);
          commit("resetEmailTemplateBox");
          commit("setResponseMessage", "Email template updated successfully.");
          commit("setResponseClass", "success");
          //commit('sendingEmail', false);
          //document.querySelector('#SendEmail .v-btn__content span').innerHTML = 'Send';
          dispatch("getEmailTemplates", state.user.attributes.email);
          commit("setTemplateActionInProgress", false);
          document.querySelector("#emailTemplatePrimaryBtnText").innerHTML =
            state.primary_btn_txt;
        })
        .catch(function(error) {
          if (error.response) {
            commit("setResponseMessage", error.response.data);
          } else {
            commit("setResponseMessage", error.message);
          }
          commit("setResponseClass", "error");
          commit("setTemplateActionInProgress", false);
          document.querySelector("#emailTemplatePrimaryBtnText").innerHTML =
            state.primary_btn_txt;
        });
    },
    deleteEmailTemplate({ commit, dispatch, state }, item) {
      axios
        .delete(
          "https://api.mailshipp.com/templates/" + item.id + "/" + item.name
        )
        .then(function(response) {
          console.log(response);
          state.dialogId = "";
          state.deleteInProgress = false;
          commit("setSelectedID", "");
          commit("setTemplateActionInProgress", false);
          commit("setResponseMessage", "Email template deleted successfully.");
          commit("setResponseClass", "success");
          dispatch("getEmailTemplates", state.user.attributes.email);
        })
        .catch(function(error) {
          state.dialogId = "";
          state.deleteInProgress = false;
          commit("setSelectedID", "");
          commit("setTemplateActionInProgress", false);
          if (error.response) {
            commit("setResponseMessage", error.response.data);
          } else {
            commit("setResponseMessage", error.message);
          }
          commit("setResponseClass", "error");
        });
    },
    deleteCampaign({ commit, dispatch, state }, item) {
      axios
        .delete("https://api.mailshipp.com/batches/" + item.id)
        .then(function(response) {
          console.log(response);
          state.dialogId = "";
          state.deleteInProgress = false;
          commit("setSelectedID", "");
          commit("setCampaignActionInProgress", false);
          commit("setResponseMessage", "Campaign deleted successfully.");
          commit("setResponseClass", "success");
          commit("setCSVdata", null);
          dispatch("getCampaigns", {
            user: state.user.attributes.email,
            status: ""
          });
        })
        .catch(function(error) {
          state.dialogId = "";
          state.deleteInProgress = false;
          commit("setSelectedID", "");
          commit("setCampaignActionInProgress", false);
          if (error.response) {
            commit("setResponseMessage", error.response.data);
          } else {
            commit("setResponseMessage", error.message);
          }
          commit("setResponseClass", "error");
        });
    },
    updateCampaign({ commit, dispatch, state }, { campaignID, campaignData, updatingArchiveStatus }) {
      var campaign_data = campaignData;
      axios
        .put("https://api.mailshipp.com/batches/" + campaignID, campaignData)
        .then(function(response) {
          console.log(response);
          if (updatingArchiveStatus) {
            // eslint-disable-next-line prettier/prettier
            commit("setResponseMessage", campaign_data.archived ? "Campaign archived successfully." : "Campaign un-archived successfully.");
            commit("setResponseClass", "success");
            state.archiveDialogId = "";
            state.archiveInProgress = false;

            commit("setCSVdata", null);
            if (campaign_data.archived) {
              dispatch("getCampaigns", {
                user: state.user.attributes.email,
                status: ""
              });
            } else {
              dispatch("getArchivedCampaigns", {
                user: state.user.attributes.email,
                archived: true,
                status: ""
              });
            }
            commit("setCampaignActionInProgress", false);
          } else {
            commit("setResponseMessage", "Campaign updated successfully.");
            commit("setResponseClass", "success");
            document.querySelector("#updateCampaign span").innerHTML = "Update";
            router.go(-1);
            commit("setCSVdata", null);
            dispatch("getCampaigns", {
              user: state.user.attributes.email,
              status: ""
            });
            commit("setCampaignActionInProgress", false);
          }
        })
        .catch(function(error) {
          commit("setResponseMessage", error.message);
          commit("setResponseClass", "error");
          commit("setCampaignActionInProgress", false);
          if (campaign_data.archived == true) {
            state.archiveDialogId = "";
            state.archiveInProgress = false;
          } else {
            document.querySelector("#updateCampaign spane").innerHTML =
              "Update";
          }
        });
    },
    checkUnsubscribers({ commit, state }, EmailsData) {
      axios
        .post(
          "https://s93777qiwi.execute-api.us-east-2.amazonaws.com/dev/unsubscribe/unsubscriberslist",
          EmailsData
        )
        .then(function(response) {
          commit(
            "setUnsubscribers",
            response.data.map(e => e.email)
          );
          const filteredCSV_from_empty_rows = state.csv_data.filter(el => {
            return (
              el.phone_number !== "" ||
              el.category !== "" ||
              el.link_to_company !== "" ||
              el.company_name !== "" ||
              el.email !== ""
            );
          });
          state.csv_data = filteredCSV_from_empty_rows;
          state.csv_data.forEach(function(item /* , index, object */) {
            if (item.email !== "") {
              if (response.data.map(e => e).indexOf(item.email) > -1) {
                item.status = "un-subscribed";
              } else {
                item.status = "subscribed";
              }
            }
          });
          state.getSubscriptionStatusInProgress = false;
          setTimeout(() => {
            //_this.$refs.campaignForm.validate();
            document.getElementById("stepper_campaign_name").focus();
          }, 1);
        })
        .catch(function(error) {
          const filteredCSV_from_empty_rows = state.csv_data.filter(el => {
            return (
              el.phone_number !== "" ||
              el.category !== "" ||
              el.link_to_company !== "" ||
              el.company_name !== "" ||
              el.email !== ""
            );
          });
          state.csv_data = filteredCSV_from_empty_rows;
          console.log(error);
          state.getSubscriptionStatusInProgress = false;
        });
    },
    getAllUsers({ commit }) {
      axios
        .get("https://api.mailshipp.com/users")
        .then(function(response) {
          // eslint-disable-next-line
        function compare(a, b) {
            var x = new Date(a.UserCreateDate);
            var y = new Date(b.UserCreateDate);
            if (x < y) return -1;
            if (x > y) return 1;
            return 0;
          }
          response.data.Users.sort(compare).reverse();
          commit("setGettingUsersInProgress", false);
          commit("setAllUsers", response);
        })
        .catch(function(error) {
          if (error.response) {
            commit("setResponseMessage", error.response.data);
          } else {
            commit("setResponseMessage", error.message);
          }
          commit("setResponseClass", "error");
        });
    },
    addUser({ commit, dispatch }, addUserData) {
      axios
        .post("https://api.mailshipp.com/users", addUserData)
        .then(function(response) {
          console.log(response);
          commit("showAddUserBox", false);
          commit(
            "setResponseMessage",
            "User added successfully. User shall click on the confirmation link sent to their email to approve account."
          );
          commit("setResponseClass", "success");
          commit("setUserActionInProgress", false);
          dispatch("getAllUsers");
        })
        .catch(function(error) {
          if (error.response) {
            commit("setResponseMessage", error.response.data.message.message);
          } else {
            commit("setResponseMessage", error.message);
          }
          commit("setResponseClass", "error");
          commit("setUserActionInProgress", false);
        });
    },
    updateUser({ commit, dispatch }, { updateUserData, userID }) {
      axios
        .put("https://api.mailshipp.com/users/" + userID, updateUserData)
        .then(function(response) {
          console.log(response);
          commit("showAddUserBox", false);
          commit("resetUserBox");
          commit("setResponseMessage", "User updated successfully.");
          commit("setResponseClass", "success");
          dispatch("getUserLoginStatus", true);
          dispatch("getAllUsers");
          commit("setUserActionInProgress", false);
        })
        .catch(function(error) {
          if (error.response) {
            commit("setResponseMessage", error.response.data.message.message);
          } else {
            commit("setResponseMessage", error.message);
          }
          commit("setResponseClass", "error");
          commit("setUserActionInProgress", false);
        });
    },
    deleteUser({ commit, dispatch, state }, item) {
      axios
        .delete("https://api.mailshipp.com/users/" + item.Username)
        .then(function(response) {
          console.log(response);
          state.dialogId = "";
          state.deleteInProgress = false;
          commit("setSelectedID", "");
          commit("setUserActionInProgress", false);
          commit("setResponseMessage", "User deleted successfully.");
          commit("setResponseClass", "success");
          dispatch("getUserLoginStatusAfterUserDelete", true);
          dispatch("getAllUsers");
        })
        .catch(function(error) {
          state.dialogId = "";
          state.deleteInProgress = false;
          commit("setSelectedID", "");
          commit("setUserActionInProgress", false);
          if (error.response) {
            commit("setResponseMessage", error.response.data.message.message);
          } else {
            commit("setResponseMessage", error.message);
          }
          commit("setResponseClass", "error");
        });
    },
    createRule({ commit /*, dispatch, state */ }, ruleData) {
      axios
        .post("https://api.mailshipp.com/rules", ruleData)
        .then(function(response) {
          console.log(response);
          commit("setResponseMessage", "Rule saved successfully.");
          commit("setResponseClass", "success");
          commit("setRulesActionInProgress", false);
          /* var userEmail = state.user.attributes.email;
        dispatch('getRule', userEmail); */
        })
        .catch(function(error) {
          if (error.response) {
            commit("setResponseMessage", error.response.data);
          } else {
            commit("setResponseMessage", error.message);
          }
          commit("setResponseClass", "error");
          commit("setRulesActionInProgress", false);
        });
    },
    updateRule({ commit /*, dispatch, state */ }, { ruleUpdateData, ruleID }) {
      axios
        .put("https://api.mailshipp.com/rules/" + ruleID, ruleUpdateData)
        .then(function(response) {
          console.log(response);
          commit("setResponseMessage", "Rule updated successfully.");
          commit("setResponseClass", "success");
          commit("setRulesActionInProgress", false);
          /* commit('resetRule');
        var userEmail = state.user.attributes.email;
        dispatch('getCalendarDates', userEmail);
        dispatch('getRule', userEmail); */
        })
        .catch(function(error) {
          if (error.response) {
            commit("setResponseMessage", error.response.data);
          } else {
            commit("setResponseMessage", error.message);
          }
          commit("setResponseClass", "error");
          commit("setRulesActionInProgress", false);
        });
    },
    getRule({ commit, state }, userEmail) {
      axios
        .get("https://api.mailshipp.com/rules/" + userEmail)
        .then(function(response) {
          if (response.data.length > 0) {
            var planoTemplate,
              friscoTemplate,
              dallasTemplate,
              mckinneyTemplate,
              allenTemplate;
            response.data[0].city_wise_rule_data.find(e => e["city"] == "Plano")
              ? (planoTemplate = response.data[0].city_wise_rule_data.find(
                  e => e["city"] == "Plano"
                )["template_name"])
              : (planoTemplate = "");

            response.data[0].city_wise_rule_data.find(
              e => e["city"] == "Frisco"
            )
              ? (friscoTemplate = response.data[0].city_wise_rule_data.find(
                  e => e["city"] == "Frisco"
                )["template_name"])
              : (friscoTemplate = "");

            response.data[0].city_wise_rule_data.find(
              e => e["city"] == "Dallas Texas"
            )
              ? (dallasTemplate = response.data[0].city_wise_rule_data.find(
                  e => e["city"] == "Dallas Texas"
                )["template_name"])
              : (dallasTemplate = "");

            response.data[0].city_wise_rule_data.find(
              e => e["city"] == "McKinney"
            )
              ? (mckinneyTemplate = response.data[0].city_wise_rule_data.find(
                  e => e["city"] == "McKinney"
                )["template_name"])
              : (mckinneyTemplate = "");

            response.data[0].city_wise_rule_data.find(e => e["city"] == "Allen")
              ? (allenTemplate = response.data[0].city_wise_rule_data.find(
                  e => e["city"] == "Allen"
                )["template_name"])
              : (allenTemplate = "");
            var send_time;
            if (
              response.data[0].global_send_time &&
              response.data[0].global_send_time != "NaN:NaN"
            ) {
              console.log(response.data);
              var xTime =
                "1/1/" +
                (new Date().getFullYear() + 1) +
                " " +
                response.data[0].global_send_time +
                " UTC";
              send_time =
                new Date(xTime).getHours() + ":" + new Date(xTime).getMinutes();
            } else {
              send_time = "00:00";
            }

            var ruleData = {
              id: response.data[0].id,
              updatedAt: response.data[0].updatedAt,
              createdAt: response.data[0].createdAt,
              user_email: state.user.attributes.email,
              default_template_name: response.data[0].default_template_name,
              global_send_time: send_time,
              sheet_name: response.data[0].sheet_name,
              sheet_url: response.data[0].sheet_url,
              sheet_id: response.data[0].sheet_id,
              selected_rule_type: response.data[0].selected_rule_type,
              city_wise_rule_data: [
                {
                  city: "Plano",
                  template_name: planoTemplate
                },
                {
                  city: "Frisco",
                  template_name: friscoTemplate
                },
                {
                  city: "Dallas Texas",
                  template_name: dallasTemplate
                },
                {
                  city: "McKinney",
                  template_name: mckinneyTemplate
                },
                {
                  city: "Allen",
                  template_name: allenTemplate
                }
              ],
              categegory_wise_rule_data: []
            };
            commit("setRule", ruleData);
          }

          //dispatch('getAllUsers');
        })
        .catch(function(error) {
          if (error.response) {
            commit("setResponseMessage", error.response.data);
          } else {
            commit("setResponseMessage", error.message);
          }
          commit("setResponseClass", "error");
        });
    },
    /* createCalendarDates({ commit }, {calendarUpdateData, userEmail, global_send_time}){
      var data = {dates:calendarUpdateData, global_send_time:global_send_time}
      axios.put('https://api.mailshipp.com/schedules/'+ userEmail, data)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
        commit('setResponseMessage', 'Error updating calendar.');
        commit('setResponseClass', 'error');
      });
    }, */
    createCalendarDates({ commit }, { calendarUpdateData, userEmail }) {
      axios
        .put(
          "https://api.mailshipp.com/schedules/" + userEmail,
          calendarUpdateData
        )
        .then(function(response) {
          console.log(response);
        })
        .catch(function(error) {
          if (error.response) {
            commit("setResponseMessage", error.response.data);
          } else {
            commit("setResponseMessage", error.message);
          }
          commit("setResponseClass", "error");
        });
    },
    getCalendarDates({ commit }, userEmail) {
      axios
        .get("https://api.mailshipp.com/schedules/" + userEmail)
        .then(function(response) {
          var x = response.data;
          var list = [];
          var eventList = [];
          var calendarTime = "";
          function calMonth(month) {
            if (month > 12) {
              month = month - 12;
            }
            if (month < 10) {
              month = "0" + month;
            }
            return month;
          }
          function calDay(date) {
            if (date < 10) {
              date = "0" + date;
            }
            return date;
          }
          var current_time = new Date().getTime();
          // eslint-disable-next-line
        x.forEach((value, index) => {
            //var dateID = new Date(value.send_time).toISOString().split('T')[0];
            var dateID =
              new Date(value.send_time).getFullYear() +
              "-" +
              calMonth(new Date(value.send_time).getMonth() + 1) +
              "-" +
              calDay(new Date(value.send_time).getDate());
            calendarTime =
              new Date(value.send_time).getHours() +
              ":" +
              new Date(value.send_time).getMinutes();
            value["date_id"] = dateID;
            if (value.send_time < current_time) {
              eventList.push(dateID);
            } else {
              list.push(dateID);
            }
          });
          commit("setGoogleSheetCalendarTime", calendarTime);
          commit("setGoogleSheetCalendarEventDates", eventList);
          commit("setGoogleSheetCalendarDates", list);
          commit("setGoogleSheetCalendarData", response.data);
          commit("setGettingRule", false);
        })
        .catch(function(error) {
          if (error.response) {
            commit("setResponseMessage", error.response.data);
          } else {
            commit("setResponseMessage", error.message);
          }
          commit("setResponseClass", "error");
        });
    },
    userDashboardStats({ commit }, user) {
      var currentDate = new Date; // get current date
      var WeekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'], thisWeekData = [], thisMonthData = [], thisYearData = [], Months = ["January", "Feburary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      function getMonth(month) {
        if (month <= 0) {
          month = month + 12;
        } else if (month > 12) {
          month = month - 12;
        }
        if(month<10){
          month = '0'+month;
        }
        return month;
      }
      function setDate(date){
        if(date<10){
          date = '0'+date;
        }
        return date
      }
      function getThisWeekLabels(){
        for (let index = 0; index <= currentDate.getDay(); index++) {
          let date = null, currentTime = null, offset = null;
          currentTime = currentDate.getTime();
          offset = 86400000*index;
          date = currentTime - offset;
          date = getMonth(new Date(date).getMonth()+1) +'-'+ new Date(date).getDate() +'-'+ new Date(date).getFullYear();
          var dayIndex = new Date(date).getDay();
          thisWeekData.push(
            {
              day: WeekDays[dayIndex],
              date_id: date,
              emails_sent: 0,
              campaigns_sent: 0,
              emails_opened: 0
            }
          );
        }
      }
      function getThisMonthLabels(){
        for (let index = 1; index <= currentDate.getDate(); index++) {
          thisMonthData.push(
            {
              date: setDate(index)+'/'+Months[currentDate.getMonth()],
              emails_sent: 0,
              campaigns_sent: 0,
              emails_opened: 0
            }
          );
        }
      }
      function getThisYearLabels(){
        for (let index = 1; index <= (currentDate.getMonth()+1); index++) {
          thisYearData.push(
            {
              date: getMonth(index)+'/'+currentDate.getFullYear(),
              emails_sent: 0,
              campaigns_sent: 0,
              emails_opened: 0
            }
          );
        }
      }
      function compare(a, b) {
        if (
          new Date(a.date_id.replace(/_/g, "/")).getTime() <
          new Date(b.date_id.replace(/_/g, "/")).getTime()
        ) {
          return -1;
        }
        if (
          new Date(a.date_id.replace(/_/g, "/")).getTime() >
          new Date(b.date_id.replace(/_/g, "/")).getTime()
        ) {
          return 1;
        }
        return 0;
      }
      getThisWeekLabels();getThisMonthLabels();getThisYearLabels();
      axios
        .get("https://api.mailshipp.com/batches/stats/" + user)
        .then(function(response) {
          var stats = response.data;
          stats.sort(compare).reverse();
          /** adding data of this week */
          // stats.push(
          //   {"date_id":"2022_1_7",
          //   "emailCount":33,
          //   "updatedAt":1623126714310,
          //   "createdAt":1623099182257,
          //   "user":"shafqat.jamil@binexsolutions.com",
          //   "batchCount":1,
          //   "batch_id":"309912c0-c763-11eb-b061-335fc2f88e4f",
          //   "email_opened":3,
          //   "id":"59712920-c7d2-11eb-85f9-a9f9b0138201"},
          // );
          // stats.push(
          //   {"date_id":"2022_1_6",
          //   "emailCount":22,
          //   "updatedAt":1623126714310,
          //   "createdAt":1623099182257,
          //   "user":"shafqat.jamil@binexsolutions.com",
          //   "batchCount":2,
          //   "batch_id":"309912c0-c763-11eb-b061-335fc2f88e4f",
          //   "email_opened":2,
          //   "id":"59712920-c7d2-11eb-85f9-a9f9b0138201"},
          // );
          // stats.push(
          //   {"date_id":"2022_1_5",
          //   "emailCount":40,
          //   "updatedAt":1623126714310,
          //   "createdAt":1623099182257,
          //   "user":"shafqat.jamil@binexsolutions.com",
          //   "batchCount":10,
          //   "batch_id":"309912c0-c763-11eb-b061-335fc2f88e4f",
          //   "email_opened":4,
          //   "id":"59712920-c7d2-11eb-85f9-a9f9b0138201"},
          // );
          // stats.push(
          //   {"date_id":"2022_1_4",
          //   "emailCount":50,
          //   "updatedAt":1623126714310,
          //   "createdAt":1623099182257,
          //   "user":"shafqat.jamil@binexsolutions.com",
          //   "batchCount":1,
          //   "batch_id":"309912c0-c763-11eb-b061-335fc2f88e4f",
          //   "email_opened":5,
          //   "id":"59712920-c7d2-11eb-85f9-a9f9b0138201"},
          // );
          console.log(stats);
          stats.forEach(element => {
            function setDateFormat(date){
              let temps = date.split('_')
              if(temps[1] == '0')
              {
                temps[1] = 1
              }
              date = temps.join('_');
              
              date = new Date(date.replace(/_/g, "/"));
              // date = (date.getMonth()+2)+'-'+date.getDate()+'-'+date.getFullYear();
              /** adding 0 to month from 1 to 9 -> 1 to 01 */
              var tempMonth = date.getMonth()+1;
              if(tempMonth.toString().length < 2){
                tempMonth = "0"+tempMonth;
              }
              date = tempMonth+'-'+date.getDate()+'-'+date.getFullYear();
              return date;
            }
            element.date_id = setDateFormat(element.date_id);
            let weekIndex  = thisWeekData.findIndex(e => e.date_id == element.date_id);
            
            /** adding 0 to date from 1 to 9 -> 1 to 01 */
            let tempDate = new Date(element.date_id).getDate();
            if(tempDate.toString().length < 2){
              tempDate = 0 +""+ tempDate;
            }

            let monthIndex  = thisMonthData.findIndex(e=> e.date == (tempDate+'/'+Months[new Date(element.date_id).getMonth()]));
            let yearIndex  = thisYearData.findIndex(e=> e.date == getMonth(new Date(element.date_id).getMonth() +1)+'/'+new Date(element.date_id).getFullYear());
            if(weekIndex>-1){
              thisWeekData[weekIndex].campaigns_sent += parseFloat(element.batchCount);
              thisWeekData[weekIndex].emails_sent += parseFloat(element.emailCount);
              if(element.email_opened){
                thisWeekData[weekIndex].emails_opened += parseFloat(element.email_opened);
              }
            }
            if(monthIndex>-1){
              thisMonthData[monthIndex].campaigns_sent += parseFloat(element.batchCount);
              thisMonthData[monthIndex].emails_sent += parseFloat(element.emailCount);
              if(element.email_opened){
                thisMonthData[monthIndex].emails_opened += parseFloat(element.email_opened);
              }
            }
            if(yearIndex>-1){
              thisYearData[yearIndex].campaigns_sent += parseFloat(element.batchCount);
              thisYearData[yearIndex].emails_sent += parseFloat(element.emailCount);
              if(element.email_opened){
                thisYearData[yearIndex].emails_opened += parseFloat(element.email_opened);
              }
            }
          });

          // alert("Week: " + JSON.stringify(thisWeekData));
          // alert("Month: " + JSON.stringify(thisMonthData));
          // alert("Year: " + JSON.stringify(thisYearData));

          commit("setThisWeekData", thisWeekData);
          commit("setThisMonthData", thisMonthData);
          commit("setThisYearData", thisYearData);
          commit("setDashboardLoading", false);
        }).catch(function(error) {
          if (error.response) {
            commit("setResponseMessage", error.response.data);
          } else {
            commit("setResponseMessage", error.message);
          }
          commit("setResponseClass", "error");
        });
    },
    userDashboardStats1({ commit/* , state */}, user) {
      alert("DB-1");
      axios
        .get("https://api.mailshipp.com/batches/stats/" + user)
        .then(function(response) {
          var stats = response.data;
          console.log(stats);
          console.log(new Date().getDay());
          var sentCampaigns = [],
            sentCampaignsDate = [],
            sentEmails = [],
            openedEmails = [],
            sentEmailsDate = [],
            last_7_years_header = [],
            last_7_months_header = [],
            element,
            last_date = new Date(),
            date,
            current_date,
            displacement = 86400000,
            last_7_months_emailCount = [0, 0, 0, 0, 0, 0, 0],
            last_7_months_openedEmails = [0, 0, 0, 0, 0, 0, 0],
            last_7_months_batchCount = [0, 0, 0, 0, 0, 0, 0],
            last_7_years_emailCount = [0, 0, 0, 0, 0, 0, 0],
            last_7_years_openedEmails = [0, 0, 0, 0, 0, 0, 0],
            last_7_years_batchCount = [0, 0, 0, 0, 0, 0, 0],
            Months = [
              "January",
              "Feburary",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December"
            ],
            today = new Date();
          function compare(a, b) {
            if (
              new Date(a.date_id.replace(/_/g, "/")).getTime() <
              new Date(b.date_id.replace(/_/g, "/")).getTime()
            ) {
              return -1;
            }
            if (
              new Date(a.date_id.replace(/_/g, "/")).getTime() >
              new Date(b.date_id.replace(/_/g, "/")).getTime()
            ) {
              return 1;
            }
            return 0;
          }
          stats.sort(compare).reverse();

          function getMonth(month) {
            if (month < 0) {
              month = month + 12;
            } else if (month > 11) {
              month = month - 12;
            }
            return month;
          }
          function getYear(index) {
            var todayMonth = getMonth(new Date().getMonth() - index);
            var MonthEpoch = 0;
            var LongMonth = ["0", "2", "4", "6", "7", "9", "11"];
            if (index > 0) {
              for (let i = 0; i < index; i++) {
                if (todayMonth == 1) {
                  MonthEpoch = MonthEpoch + 2505600000;
                } else if (LongMonth.find(e => e[todayMonth])) {
                  MonthEpoch = MonthEpoch + 2678400000;
                } else {
                  MonthEpoch = MonthEpoch + 2592000000;
                }
              }
            } else {
              if (todayMonth == 1) {
                MonthEpoch = 2505600000 * index;
              } else if (LongMonth.find(e => e[todayMonth])) {
                MonthEpoch = 2678400000 * index;
              } else {
                MonthEpoch = 2592000000 * index;
              }
            }
            return new Date(new Date().getTime() - MonthEpoch).getFullYear();
          }
          
          let array = [];
          stats.forEach(element => {
            let index  = array.findIndex(e=> e.date_id == element.date_id);
            if(index>-1){
              array[index].batchCount += parseFloat(element.batchCount);
              array[index].emailCount += parseFloat(element.emailCount);
              array[index].email_opened += parseFloat(element.email_opened);
            }
            else{
              array.push(element);
            }
          });



          for (let index = 0; index < 7; index++) {
            last_7_months_header.push(
              Months[getMonth(today.getMonth() - index)] + "-" + getYear(index)
            );
            last_7_years_header.push(today.getFullYear() - index);

            if (array[index] != undefined) {
              element = array[index];
              let date_id = element.date_id.split('_')
              let tempMonth = Number.parseInt(date_id[1])+1;
              date_id[1] = tempMonth;
              last_date = date_id.join('/');
              current_date =
                getMonth(new Date(last_date).getMonth() + 1) +
                "/" +
                new Date(last_date).getDate() +
                "/" +
                new Date(last_date).getFullYear();

                sentCampaigns.push(element.batchCount);
                sentEmails.push(element.emailCount);
                sentEmailsDate.push(current_date);
                sentCampaignsDate.push(current_date);

                

                if(element.email_opened){
                  openedEmails.push(element.email_opened);
                }
                else{
                  openedEmails.push(0);
                }
            } else {
              sentCampaigns.push(0);
              sentEmails.push(0);
              openedEmails.push(0);
              if (last_date != "") {
                sentCampaignsDate.push(
                  getMonth(
                    new Date(
                      new Date(last_date).getTime() - displacement * index
                    ).getMonth() + 2
                  ) +
                    "/" +
                    new Date(
                      new Date(last_date).getTime() - displacement * index
                    ).getDate() +
                    "/" +
                    new Date(
                      new Date(last_date).getTime() - displacement * index
                    ).getFullYear()
                );

                sentEmailsDate.push(
                  getMonth(
                    new Date(
                      new Date(last_date).getTime() - displacement * index
                    ).getMonth() + 2
                  ) +
                    "/" +
                    new Date(
                      new Date(last_date).getTime() - displacement * index
                    ).getDate() +
                    "/" +
                    new Date(
                      new Date(last_date).getTime() - displacement * index
                    ).getFullYear()
                );
              } else {
                sentCampaignsDate.push(
                  getMonth(
                    new Date(
                      new Date().getTime() - displacement * index
                    ).getMonth() + 2
                  ) +
                    "/" +
                    new Date(
                      new Date().getTime() - displacement * index
                    ).getDate()
                ) +
                  "/" +
                  new Date(
                    new Date().getTime() - displacement * index
                  ).getFullYear();

                sentEmailsDate.push(
                  getMonth(
                    new Date(
                      new Date().getTime() - displacement * index
                    ).getMonth() + 2
                  ) +
                    "/" +
                    new Date(
                      new Date().getTime() - displacement * index
                    ).getDate()
                ) +
                  "/" +
                  new Date(
                    new Date().getTime() - displacement * index
                  ).getFullYear();
              }
            }

            array.forEach(x => {
              date = x.date_id.replace(/_/g, "/");
              if (
                getMonth(new Date(date).getMonth() + 1) ==
                  getMonth(today.getMonth() - index) &&
                new Date(date).getFullYear() == getYear(index)
              ) {
                last_7_months_emailCount[index] += x.emailCount;
                if(x.email_opened){
                  last_7_months_openedEmails[index] += x.email_opened;
                }
                last_7_months_batchCount[index] += x.batchCount;
              }
              if (new Date(date).getFullYear() == today.getFullYear() - index) {
                last_7_years_emailCount[index] += x.emailCount;
                
                if(x.email_opened){
                  last_7_years_openedEmails[index] += x.email_opened;
                }
                last_7_years_batchCount[index] += x.batchCount;
              }
            });
          }

          function daysInMonth (month, year) {
            return new Date(year, month, 0).getDate();
          }
          var currentMonth = new Date().getMonth() +1;
          var currentYear = new Date().getFullYear();
          var thisMonthDates = [], presentDate, currentIndex;
          for (let index = 1; index <= daysInMonth(currentMonth,currentYear); index++) {
            if(index< 10){
              currentIndex = '0'+index;
            }
            else{
              currentIndex = index;
            }
            presentDate = currentMonth+'/'+currentIndex+'/'+currentYear;
            thisMonthDates.push(presentDate);
          }
          console.log(thisMonthDates);
          commit("setSentCampaigns", sentCampaigns.reverse());
          commit("setSentCampaignsDate", sentCampaignsDate.reverse());
          commit("setSentEmails", sentEmails.reverse());
          commit("setOpenedEmails", openedEmails.reverse());
          commit("setSentEmailsDate", sentEmailsDate.reverse());

          commit("setMonthSentCampaigns", last_7_months_batchCount.reverse());
          commit("setMonthSentLabel", last_7_months_header.reverse());
          commit("setMonthSentEmails", last_7_months_emailCount.reverse());
          commit("setMonthOpenedEmails", last_7_months_openedEmails.reverse());

          commit("setYearSentCampaigns", last_7_years_batchCount.reverse());
          commit("setYearSentLabel", last_7_years_header.reverse());
          commit("setYearSentEmails", last_7_years_emailCount.reverse());
          commit("setYearOpenedEmails", last_7_years_openedEmails.reverse());

          commit("setDashboardLoading", false);
          
        })
        .catch(function(error) {
          if (error.response) {
            commit("setResponseMessage", error.response.data);
          } else {
            commit("setResponseMessage", error.message);
          }
          commit("setResponseClass", "error");
        });
    }
  },
  modules: {}
});
