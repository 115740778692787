<template>
        <v-layout align-center justify-center class="import-file-container">
            <v-flex xs12 sm6 md3>
                <transition name="fade">
                <v-card class="elevation-5 import-file-box">
                    <span id="close-btn" @click="closeUploadCsv">&#10005;</span>
                    <h2>IMPORT FILE</h2>
                    <p class="subtitle">Upload or drag and drop the CSV file.</p>
                    <div id="csv-file-container"><input
                        id="csv-file"
                        type="file"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        label="File input"
                        @change="readCsvFile"/></div>
                    <p id="csv-file-name"></p>
                    <span>{{error}}</span>
                </v-card>
                </transition>
            </v-flex>
        </v-layout>
</template>
<script>
export default {
    name: 'UploadCsv',
    data(){
        return{
            csv_data: null,
            error: ''
        }
    },
    mounted(){

    },
    methods: {
        readCsvFile(event){
            var _this = this;
            var file = event.target.files[0];
            //eslint-disable-next-line
            var fileName = document.getElementById('csv-file').value.replace(/^.*[\\\/]/, '');
            document.getElementById('csv-file-name').innerHTML = fileName;
            /* this.$papa.parse(file, {
                header: true,
                complete: function (results) {
                    _this.$store.state.csv_data = results.data;
                    _this.$router.push({ name: 'Add New Campaign' })
                }
            }); */
            var allKeyPresent = false; // Flag
            var EmailsList = [];
            var EmailsData = {};
            this.$papa.parse(file, {
                header : true,
                skipEmptyLines : true,
                complete: function(row, /* parser */){
                    if (!allKeyPresent) { //Only chek if flag is not set, i.e, for the first time
                        //parser.pause(); // pause the parser
                        var first_row_data = row.data[0];
                        // Now check object keys, if it match
                        if (('phone_number' in first_row_data) && ('category' in first_row_data) && ('link_to_company' in first_row_data) &&('email' in first_row_data) && ('company_name' in first_row_data)) {
                            //every required key is present
                            allKeyPresent = true;

                            _this.$store.state.csv_data = row.data;
                            // eslint-disable-next-line
                            row.data.forEach((value, index) => {
                                EmailsList.push(value.email);
                            });
                            EmailsData = { "clients": EmailsList};
                            _this.$store.dispatch('checkUnsubscribers', EmailsData);
                            _this.$store.state.csvUploadBox = false;
                            _this.$router.push({ name: 'Add New Campaign' })

                            //parser.resume();
                        } else{
                            //some key is missing, abort parsing
                            console.log('Wrong File');
                            _this.error = 'File does not follow the specified format';
                            //parser.abort();
                        }

                    } else{ // we already match the header, all required key is present

                        // Do the Data processing here
                        _this.$store.state.csv_data = row.data;
                        
                        // eslint-disable-next-line
                        row.data.forEach((value, index) => {
                            EmailsList.push(value.email);
                        });
                        EmailsData = { "clients": EmailsList};
                        _this.$store.dispatch('checkUnsubscribers', EmailsData);
                        _this.$store.state.csvUploadBox = false;
                        _this.$router.push({ name: 'Add New Campaign' })

                    }

                }
            });
        },
        closeUploadCsv(){
            this.$store.state.csvUploadBox = false;
        }
    },
    
}
</script>
<style scoped>
.import-file-container{
    position: fixed;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 9;
    background-color: rgba(0,0,0,0.3);
}
.import-file-container .import-file-box{
    text-align: center;
    padding: 35px 0;
    box-shadow: none !important;
    background-color: #FAFAFA;
    border-radius: 10px;
}
.import-file-container .import-file-box h2{
    font-weight: 400;
    margin-bottom: 10px;
}

.import-file-container .import-file-box p{
    margin-bottom: 0;
}
.import-file-container .import-file-box p.subtitle{
    margin-bottom: 25px;
}
#csv-file-container{
    width: 150px;
    position: relative;
    margin: auto;
}
#csv-file{
    position: relative;
    height: 35px;
    width: 100%;
    opacity: 0;
    font-size: 0px;
    top: -15px;
    cursor: pointer;
}
#csv-file-container:before{
    content: "Upload File";
    position: absolute;
    left: 0;
    padding: 5px 0;
    color: #fff;
    width: 100%;
    text-align: center;
    border-radius: 3px;
    background-color: #1f94eeb7;
    cursor: pointer;
    transition: all 0.5s;
}
#csv-file-container:hover:before{
    background-color: #1F95EE;
}
#close-btn{
    position: absolute;
    cursor: pointer;
    right: 7px;
    top: 5px;
    color: rgba(0,0,0,0.8);
    padding: 0px 6px;
}

</style>