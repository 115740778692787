<template>
  <v-app>
    <transition name="fade-transition">
      <div v-if="!pageLoading" style="min-height:100%">
        <app-navigation v-if="showHeader"></app-navigation>
        <app-header v-if="showSubHeader"></app-header>
        <v-main transition="fade" :class="mainDivClass">
          <router-view></router-view>
        </v-main>
        <span class="version">V 1.0.43</span>
      </div>
    </transition>
    <transition name="fade-transition">
      <div class="pageLoader" v-if="pageLoading">
        <v-progress-circular
          indeterminate
          color="#777BD2"
          class="progressLoader"
        ></v-progress-circular>
      </div>
    </transition>
  </v-app>
</template>

<script>
import AppNavigation from "@/components/AppNavigation";
import AppHeader from "@/components/AppHeader";

export default {
  name: "App",
  data() {
    return {
      mainDivClass: ""
    };
  },
  components: {
    AppNavigation,
    AppHeader
  },
  mounted() {
    //this.$store.dispatch('getUserLoginStatus', false);
    //this.$store.dispatch('userCurrentSession');
    this.vMainClass();
  },
  methods: {
    /* Headers(){
            if(this.$route.path != '/sign-in' || this.$route.path != '/update-password' || this.$route.path != '/forgot-password'){
                this.$store.state.showHeader = true;
                this.$store.state.showSubHeader = true;
            }
            else{
                this.$store.state.showHeader = false;
                this.$store.state.showSubHeader = false;
            }
        }, */
    vMainClass() {
      if (
        this.$route.path != "/sign-in" &&
        this.$route.path != "/update-password" &&
        this.$route.path != "/forgot-password"
      ) {
        this.mainDivClass = "";
      } else {
        this.mainDivClass = "fill-height";
      }
    }
  },
  computed: {
    showHeader() {
      return this.$store.state.showHeader;
    },
    showSubHeader() {
      return this.$store.state.showSubHeader;
    },
    pageLoading() {
      return this.$store.state.pageLoading;
    }
  },
  watch: {
    $route: function() {
      this.vMainClass();
    }
  }
};
</script>

<style>
html {
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
}
header .v-toolbar__content {
  height: auto !important;
  padding: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.v-data-table {
  width: 100%;
}
.v-data-table__wrapper thead {
  background-color: #cdeaff;
}
.v-data-table .v-data-table__wrapper thead > tr > th {
  font-size: 18px !important;
  font-weight: 400;
}
.v-data-table .v-data-table__wrapper tbody > tr > td {
  font-size: 16px !important;
  font-weight: 400;
}
.vue-quick-edit__buttons {
  display: none !important;
}
.vue-quick-edit__input,
.vue-quick-edit__link {
  outline: none;
}
.version {
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}
.v-application {
  margin-bottom: 50px;
}
.v-application button {
  transition: 0.3s all;
}
.v-application button.primary,
.primary.v-btn {
  background-color: #777bd2 !important;
}
.v-application button.secondary {
  background-color: transparent !important;
  border: 2px solid #777bd2 !important;
  color: #777bd2 !important;
}
.v-application button.secondary:hover {
  background-color: #777bd2 !important;
  color: #ffffff !important;
}
.v-application a {
  transition: 0.3s all;
}
.v-application a.primary {
  background-color: #777bd2 !important;
}
.v-application a.secondary {
  background-color: transparent !important;
  border: 2px solid #777bd2 !important;
  color: #777bd2 !important;
}
.v-application a.secondary:hover {
  background-color: #777bd2 !important;
  color: #ffffff !important;
}
.theme--light.v-btn:hover::before {
  opacity: 0.1 !important;
}
thead {
  background-color: #d9daff !important;
}
thead > tr > th {
  color: #575757 !important;
}
.pageLoader {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 2555, 255, 0.9);
}
.pageLoader .v-progress-circular {
  height: calc(20vh) !important;
  width: calc(15vw) !important;
  margin: auto;
}
</style>
