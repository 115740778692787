<script>
// eslint-disable-next-line
import { Bar } from 'vue-chartjs';
 
export default {
    name: 'DashboardChart',
    props: ['label', 'label2','bgcolor', 'bgcolor2', 'chartdata', 'chartdata2', 'labels'],
    extends: Bar,
    methods:{
        getMonth(month){
            var month_name = '';
            var actualDate = '';
            var dateLatest = new Date().getMonth();
            
            if(month<0){
                if(dateLatest+month<0){
                    actualDate = (dateLatest+month)+12;
                }
                else{
                    actualDate = (dateLatest+month);
                }
            }
            else{
                actualDate = dateLatest - month;
            }
            var month_list = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
            month_name = month_list[actualDate];
            return month_name;
        },
    },
    mounted () {
        var data = this.chartdata;
        var data2 = this.chartdata2,
        label = this.label,
        label2 = this.label2,
        labels = this.labels,
        bgcolor = this.bgcolor,
        bgcolor2 = this.bgcolor2;

        /** reverse week days to be shown on the graph's x-axis */
        var tempWeekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        if(labels.some(label => tempWeekdays.includes(label))){
            labels = labels.reverse();
            data = data.reverse();
            data2 = data2.reverse();
        }

        // Overwriting base render method with actual data.
        this.renderChart({
            //labels: [this.getMonth(-6), this.getMonth(-5), this.getMonth(-4), this.getMonth(-3), this.getMonth(-2), this.getMonth(-1), this.getMonth(0)],
            labels: labels,
            datasets: [
                {
                    label: label,
                    backgroundColor: bgcolor,
                    data: data,
                    barThickness: 20,
                    fill: false,
                    options: {
                        responsive: true,
                        maintainAspectRatio: true,
                    }
                },
                {
                    label: label2,
                    backgroundColor: bgcolor2,
                    data: data2,
                    barThickness: 20,
                    fill: false,
                    options: {
                        responsive: true,
                        maintainAspectRatio: true,
                    }
                }
            ]

            // labels : ["January", "February", "March", "April", "May", "June", "July"],
            // datasets : [
            //     {
            //         label: "My First dataset",
            //         data: [65, 0, 80, 81, 56, 85, 40],
            //         fill: false,
            //         display: false,
            //     },
            //     {
            //         label: "My second dataset",
            //         data: [15, 0, 80, 81, 56, 85, 40],
            //         fill: false,
            //         display: false,
            //     }
            // ],
        })
    },
    computed:{
        sentCampaigns(){
            return this.$store.state.sentCampaigns;
        },
        sentCampaignsDate(){
            return this.$store.state.sentCampaignsDate;
        },
        sentEmails(){
            return this.$store.state.sentEmails;
        },
        sentEmailsDate(){
            return this.$store.state.sentEmailsDate;
        },
    },
}

</script>
<style scoped>
canvas{
  max-width: 600px !important;
  max-height: 600px !important;
  margin: 11px 0px 35px;
  pointer-events: none!important;
}
</style>