import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import Home from '../views/Home.vue';
import { Auth } from 'aws-amplify';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: '/sign-in',
    name: 'Sign In',
    component: () => import('../views/Signin.vue')
  },
  {
    path: '/add-new-campaign',
    name: 'Add New Campaign',
    component: () => import('../views/AddNewCampaign.vue')
  },
  {
    path: '/campaign/:id',
    name: 'Campaign Detail',
    props: true,
    component: () => import('../views/CampaignDetail.vue')
  },
  {
    path: '/Automation',
    name: 'Automation',
    component: () => import('../views/Automation.vue')
  },
  {
    path: '/email-templates',
    name: 'Email Templates',
    component: () => import('../views/EmailTemplates.vue')
  },
  {
    path: '/all-campaigns',
    name: 'Active Campaigns',
    component: () => import('../views/AllCampaigns.vue')
  },
  {
    path: '/archived-campaigns',
    name: 'Archived Campaigns',
    component: () => import('../views/ArchivedCampaigns.vue')
  },
  {
    name: 'Rules Management',
    path: '/management/rules',
    component: () => import('../views/RulesManagement.vue')
  },
  {
    name: 'Users Management',
    path: '/management/users',
    component: () => import('../views/UserManagement.vue')
  },
  {
    name: 'Update Password',
    path: '/update-password',
    component: () => import('../views/UpdatePassword.vue')
  },
  {
    name: 'Forgot Password',
    path: '/forgot-password',
    component: () => import('../views/ForgotPassword.vue')
  },
  {
    name: 'Random Test',
    path: '/random-test',
    component: () => import('../views/RandomTest.vue')
  },
  { 
    name: 'Page Not Found',
    path: "*",
    component: () => import('../views/PageNotFound.vue')
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

/*router.beforeEach((to, from, next) => {
router.beforeEach(() => {  
  if (to.name !== 'Sign In' && to.name !== 'Update Password' && to.name !== 'Forgot Password' && !store.state.user) next({ name: 'Sign In' })
  else next()

  store.state.csvUploadBox = false
}) */
// eslint-disable-next-line
router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  if(to.name !== 'Sign In' && to.name !== 'Forgot Password' && to.name !== 'Update Password'){
    Auth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(user => {
      store.state.user = user;
      store.state.showHeader = true;
      store.state.showSubHeader = true;
      if(from.name == 'Home' && store.state.csv_data != null && store.state.routeChangeFlag == false){
        store.state.routeConfirmationDialog = true;
        store.state.routeTo = to.fullPath;
        store.state.routeChangeMsg = 'You have alomost completed sending campaign. Are you sure you want to navigate somewhere else?';
      }
      else if(to.name == 'Home'){
        store.state.routeChangeFlag = false;
        store.state.routeChangeMsg = '';
        store.state.routeTo = '';
        store.state.pageLoading = false;
        next()
      }
      else{
        store.state.pageLoading = false;
        next()
      }
    })
    // eslint-disable-next-line
    .catch(err => {
      if(localStorage.getItem('remember-') != null){
        store.state.pageLoading = true;
        Auth.currentSession()
        // eslint-disable-next-line
        .then(data => {
          Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
          }).then(user => {
            store.state.user = user;
            store.state.showHeader = true;
            store.state.showSubHeader = true;
            if(from.name == 'Home' && store.state.csv_data != null && store.state.routeChangeFlag == false){
              store.state.routeConfirmationDialog = true;
              store.state.routeTo = to.fullPath;
              store.state.routeChangeMsg = 'You have alomost completed sending campaign. Are you sure you want to navigate somewhere else?';
            }
            else if(to.name == 'Home'){
              store.state.routeChangeFlag = false;
              store.state.routeChangeMsg = '';
              store.state.routeTo = '';
              store.state.pageLoading = false;
              next()
            }
            else{
              store.state.pageLoading = false;
              next()
            }
          })
          .catch(err => {
            store.dispatch('nullAll');
            console.log(err);
            if(from.path != '/sign-in' && from.path != '/forgot-password' && from.path != '/update-password' ){
              next({ path: '/sign-in' });
            }
            else{
              store.state.pageLoading = false;
              next();
            }
          });
        })
        // eslint-disable-next-line
        .catch(err => {
          store.dispatch('nullAll');
          console.log(err);
          if(from.path != '/sign-in' && from.path != '/forgot-password' && from.path != '/update-password' ){
            next({ path: '/sign-in' });
          }
          else{
            store.state.pageLoading = false;
            next();
          }
        });
      }
      else{
        store.dispatch('nullAll');
        if(from.path != '/sign-in' && from.path != '/forgot-password' && from.path != '/update-password' ){
          next({ path: '/sign-in' });
        }
        else{
          next();
        }
      }
    });
  }
  else{
    store.state.showHeader = false;
    store.state.showSubHeader = false;
    store.state.pageLoading = false;
    next();
  }
})

export default router;
