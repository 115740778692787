<template>
    <v-container fill-height class="response-mask">
        <v-layout align-center justify-center>
            <v-flex xs10 sm8 md4 class="response-box">
                
                <v-card class="elevation-4 response-wrapper" :class="wrapperClass">
                <v-img
                    v-if="wrapperClass == 'success'"
                    class="success-tick"
                    src='@/assets/img/success-tick.png'
                    max-width="50"
                ></v-img>
                <v-img
                    v-if="wrapperClass == 'error'"
                    class="error-cross"
                    src='@/assets/img/error-cross.png'
                    max-width="50"
                ></v-img>
                <v-img
                    v-if="wrapperClass == 'info' || wrapperClass == 'info hasAction'"
                    class="info-sign"
                    src='@/assets/img/success-tick.png'
                    max-width="50"
                ></v-img>
                <h2 v-if="wrapperClass == 'success'">Successful!</h2>
                <h2 v-if="wrapperClass == 'error'">Error!</h2>
                <h2 v-if="wrapperClass == 'info'|| wrapperClass == 'info hasAction'">Successful!</h2>
                <div class="card-content">
                    {{Msg}}
                </div>
                </v-card>
                <v-btn class="close-btn elevation-1" v-on:click="$emit('close')" v-if="wrapperClass != 'info hasAction'">OK</v-btn>
                <v-btn class="action-close-btn elevation-1" v-on:click="$emit('close')" v-if="wrapperClass == 'info hasAction'" :disabled="emailSendingInProgress">Cancel</v-btn>
                <v-btn dark class="action-primary-btn elevation-1" v-on:click="SendEmail" v-if="wrapperClass == 'info hasAction'" style="background-color:#777BD2">
                    <v-progress-circular
                        indeterminate
                        color="#fff"
                        v-if="emailSendingInProgress"
                    ></v-progress-circular><span v-if="!emailSendingInProgress">Send Now</span>
                </v-btn>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
export default {
    name: 'ResponseMsg',
    props: ['Msg', 'wrapperClass', 'data'],
    data(){
        return{
        }
    },
    methods:{
        SendEmail(){
            var stringConstructor = "test".constructor;
            var arrayConstructor = [].constructor;
            var objectConstructor = ({}).constructor;
            var sendEmailData = this.data;
            if (this.data === null) {
                console.log("null");
            }
            if (this.data === undefined) {
                console.log("undefined");
            }
            if (this.data.constructor === stringConstructor) {
                this.$store.state.emailSendingInProgress = true;
                this.$store.dispatch('sendNowAutomatedEmail', sendEmailData);
            }
            if (this.data.constructor === arrayConstructor) {
                console.log("Array");
            }
            if (this.data.constructor === objectConstructor) {
                this.$store.state.emailSendingInProgress = true;
                this.$store.dispatch('sendEmails', sendEmailData);
            }
        }
    },
    computed: {
        emailSendingInProgress(){
            return this.$store.state.emailSendingInProgress;
        }
    }
}
</script>
<style scoped>
.response-mask{
    position: fixed;
    max-width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.6);
    z-index: 99999;
}
.response-box .response-wrapper{
    padding: 20px 50px;
    text-align: center;
    border-radius: 5px 5px 0 0;
}
.card-header p{
    font-size: 23px;
    margin-bottom: 15px;
}
.response-box{
    position: relative;
    border-radius: 5px;
}
.response-box .success, .response-box .error, .response-box .info{
    background-color: #fff !important;
}
/* .response-box .success{
    border: 2px solid;
    border-top-width: 16px;
    border-color:#4F8A10 !important;
    background-color: #DFF2BF !important;
} 

.response-box .error{
    border: 2px solid;
    border-top-width: 16px;
    border-color: #D8000C !important;
    background-color: #FFBABA !important;
} */
.response-box .success h2{
    color: #23B53F;
    margin-bottom: 15px;
}
.response-box .error h2{
    color: #FF4141;
}
.response-box .info h2{
    color: #23B53F;
}
.response-box .close-btn{
    font-weight: 300;
    width: 100%;
    border-radius: 0 0 5px 5px;
    padding-top: 25px;
    padding-bottom: 25px;
}
.response-box .action-close-btn, .response-box .action-primary-btn{
    font-weight: 300;
    width: 50%;
    padding-top: 25px;
    padding-bottom: 25px;
}
.response-box .action-close-btn{
    border-radius: 0 0 0px 5px;
}
.response-box .action-primary-btn{
    border-radius: 0 0 5px 0px;
}
.response-box .card-content{
    color: #929DA6;
}
.success-tick, .error-cross, .info-sign{
    margin: 0 auto 15px;
}
</style>