<template>
    <v-container fill-height class="confirmation-mask">
        <v-layout align-center justify-center>
            <v-flex xs10 sm8 md4 >
                <v-card class="elevation-4 confirmation-wrapper">
                <div class="card-header">
                    <p>Are you sure you want to send this campaign (<span v-if="status == 'send_new'">{{$store.state.stepperCampaignName}}</span><span v-if="status != 'send_new'">{{batchName}}</span>)?</p>
                </div>
                <div class="card-footer">
                    <v-btn class="elevation-0" v-on:click="$emit('close')" :disabled="saveCampaignInProgress">Cancel</v-btn>
                    <v-btn class="elevation-0 primary" :disabled="saveCampaignInProgress" v-on:click="sendEmail()">
                        <v-progress-circular
                        indeterminate
                        color="#fff"
                        v-if="saveCampaignInProgress"
                    ></v-progress-circular><span v-if="!saveCampaignInProgress">Send Now</span></v-btn>
                </div>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
export default {
    name: 'sendEmailConfirmation',
    props: ['status', 'batch_id', 'batchName'],
    data(){
        return{
            /* actionName: '' */
            btnDisabled: false
        }
    },
    methods:{
        /*setAction(){
            if($route.params.action = deleteCampaign){
                this.actionName = 'deleteCampaign'
            }
            else if($route.params.action = deleteTemplate){
                this.actionName = 'deleteEmailTemplate'
            }
        }, */
        confirmation(item){
            this.btnDisabled = true;
            this.$store.dispatch(this.actionName, item.id);
        },
        sendEmail(){
            this.$store.state.saveCampaignInProgress = true;
            if(this.status == 'send_new'){
                var createCampaignData = {
                    "user" : this.$store.state.user.attributes.email,
                    "status": "PENDING",
                    "name": this.$store.state.stepperCampaignName,
                    "template_name": this.$store.state.stepperCampaignTemplate,
                    "clients": this.$store.state.csv_data
                };
                //console.log('send Now');
                //console.log(createCampaignData);
                this.$store.dispatch('sendAndSaveCampaignEmails', createCampaignData);
            }
            else{
                this.$store.state.emailSendingInProgress = true;
                
                var EmailData = {
                    "user": this.$store.state.user.attributes.email,
                    "template_name": this.$store.state.campaignTemplate,
                    "batch_id": this.batch_id
                }
                //console.log('send Later');
                //console.log(EmailData);
                this.$store.dispatch('sendEmails', EmailData);
            }
        },
    },
    computed:{
        saveCampaignInProgress(){
            return this.$store.state.saveCampaignInProgress
        }
    }
    /* watch: {
        '$route': function() {
            this.setAction();
        }
    } */
}
</script>
<style scoped>
.confirmation-mask{
    position: fixed;
    max-width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.6);
    z-index: 9999;
}
.confirmation-wrapper{
    padding: 40px 45px;
    background-color: #FAFAFA;
    text-align: center;
}
.confirmation-mask .card-footer button{
    padding: 10px 30px;
}
.card-footer button:last-child{
    margin-left:20px;
}
.card-header p{
    font-size: 18px;
    color: #575757;
    margin-bottom: 25px;
}
.card-footer .v-btn{
    min-width: 130px;
}
</style>