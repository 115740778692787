<template>
    <transition name="fade">
            <!-- <div id="add-email-template-wrapper">
                <div id="add-email-template-box">
                    
                </div>
            </div> -->
        <v-container fill-height id="add-new-email-template-container">
            <v-layout align-center justify-center>
                <v-flex xs10 sm8 md6 class="add-new-email-template-card-wrapper">
                        <span class="close_btn" @click="closeAddEmailTemplateBox">&#10005;</span>
                    <v-card class="elevation-4" id="add-new-email-template-card" v-bind:style="{ height: cardHeight }">
                        <v-card-text ref="templateCard">
                            <h1>{{emailTemplateHd}}</h1>
                            <v-form ref="templateForm" v-model="valid" lazy-validation>
                                <!-- <v-text-field outlined dense name="templateName" label="Template Name" type="text" v-model="templateName" required :disabled="fieldDisabled" color="#777BD2" hint="Template name can't be changed later. Template Name should only have Alphanumeric, special characters(_ and - only) and can't have spaces." persistent-hint>
                                </v-text-field> -->
                                <v-text-field outlined dense name="templateName" label="Template Name" type="text" v-model="templateName" required :disabled="fieldDisabled" color="#777BD2" :rules="templateNameRule" hint="Template name can not be changed later." persistent-hint prepend-icon="article">
                                </v-text-field>
                                <v-text-field outlined dense name="templateFromName" label="From Name" type="text" v-model="templateFromName" required color="#777BD2" :rules="templateFromNameRule" prepend-icon="person">
                                </v-text-field>
                                <v-text-field color="#777BD2" outlined dense name="templateSubject" label="Subject" type="text" required v-model="templateSubject" :rules="subjectTemplate" prepend-icon="contact_support">
                                </v-text-field>
                                <v-text-field color="#777BD2" outlined dense name="templateReplyTo" label="Reply To" type="text" required v-model="templateReplyTo" :rules="emailRules" prepend-icon="reply">
                                </v-text-field>
                                <v-textarea prepend-icon="email" color="#777BD2" outlined dense name="templateBody" label="Body" v-model="templateBody" class="BodyTxt" counter></v-textarea>
                                <div class="drag-area">
                                    <div class="NewUpload">
                                        <div class="icon"><span class="material-icons">cloud_upload</span></div>
                                        <header>Drag and Drop</header>
                                        <input
                                            id="attachment-file"
                                            type="file"
                                            accept=".doc, .docx,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, audio/*, video/*, image/*, .pdf"
                                            @change="checkUploadedFile2"
                                        />
                                    </div>
                                </div>
                                <v-form ref="attachmentForm" v-model="valid1" lazy-validation>
                                    <!-- <v-file-input
                                        v-model="emailTemplateAttachment"
                                        @change="checkUploadedFile"
                                        accept=".doc, .docx,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, audio/*, video/*, image/*, .pdf"
                                        show-size
                                        :disabled="templateActionBtnDisabled || fileUploading"
                                        outlined
                                        dense
                                        color="#777BD2"
                                        :placeholder="attachmentLabel(emailTemplateAttachment)"
                                        hint="File size should be less than 5 MB" persistent-hint
                                    > -->
                                    <v-file-input
                                        v-model="emailTemplateAttachment"
                                        accept=".doc, .docx,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, audio/*, video/*, image/*, .pdf"
                                        show-size
                                        :disabled="templateActionBtnDisabled || fileUploading"
                                        outlined
                                        dense
                                        class="File-attachment-disabled"
                                        color="#777BD2"
                                        :placeholder="attachmentLabel(emailTemplateAttachment)"
                                        hint="File size should be less than 5 MB" persistent-hint
                                    >
                                        <template v-slot:selection="{ text }">
                                            <v-chip
                                                small
                                                label
                                                color="#F0F0F0"
                                            >
                                                {{ text }}
                                            </v-chip>
                                        </template>
                                    </v-file-input>
                                    <template v-if="uploadProgress>0">
                                        <v-progress-linear style="margin-botton:20px" v-model="uploadProgress" height="15" color="#777BD2">
                                            <strong style="color:#fafafa">{{ Math.ceil(uploadProgress) }}%</strong>
                                        </v-progress-linear>
                                    </template>
                                    <p v-if="emailTemplateAttachmentName" class="attachment-name">Attachment: <a @click="downloadBlob(downloadURL, emailTemplateAttachmentName.replace(/\_@.*\./, '.'))">{{emailTemplateAttachmentName.replace(/\_@.*\./, '.')}}</a></p>
                                </v-form>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn elevation="0" id="cancelTemplateBtn" @click="cancel" style="margin-left:auto">Cancel</v-btn>
                            <v-btn elevation="0" id="addTemplateBtn" class="primary" @click="submit" :disabled="templateActionBtnDisabled || fileUploading">
                                <v-progress-circular
                                    indeterminate
                                    color="#fff"
                                    v-if="templateActionInProgress"
                                ></v-progress-circular>
                                <span id="emailTemplatePrimaryBtnText">{{primary_btn_txt}}</span>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>    
    </transition>
</template>
<script>
import { Storage } from 'aws-amplify';
export default {
    name: 'AddNewEmailTemplate',
    data(){
        return{
            cardHeight: '500',
            uploadProgress: 0,
            UploadedFileName: null,
            /* templateAttachmentRules: [
                v => !!v || v.size < 5000000 || 'Attachment size should be less than 5 MB',
            ], */
            templateNameRule: [
                v => !!v || 'Template name is required',
                v => v.length >= 3 || 'Template name must have 3 or more characters',
                v => v.match(/^[A-Za-z0-9_-]*$/) != null || "Template name can't be changed later. Template Name should only have Alphanumeric, special characters(_ and - only) and can't have spaces."
            ],
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+/.test(v) !=null || 'E-mail must be valid'
            ],
            subjectTemplate: [
                v=> !!v || 'Email subject is required'
            ],
            templateFromNameRule: [
                v=> !!v || 'From name is required',
                v=> v.match(/^[A-Za-z ]*$/) != null || "From name should only contain alphabets",
                v=> v.match(/^[ ]*$/) == null || "From name cannot only have spaces"
            ],
            valid: false,
            valid1: false,
            downloadURL: '',
            fileUploading: false,
        }
    },
    methods: {
        DownloadFileBlob(attachment_name){
            if(attachment_name){
                var _this = this;
                Storage.get(attachment_name, { download: true }).then(data=> {
                    _this.downloadURL = URL.createObjectURL(data.Body);
                });
            }
        },
        downloadBlob(blob, filename) {
          //const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = blob;
          a.download = filename || 'download';
          const clickHandler = () => {
            setTimeout(() => {
              URL.revokeObjectURL(blob);
              a.removeEventListener('click', clickHandler);
            }, 150);
          };
          a.addEventListener('click', clickHandler, false);
          a.click();
          this.DownloadFileBlob(this.$store.state.emailTemplateAttachmentName);
          return a;
        },
        attachmentLabel(file){
            var x = 'Select Template Attachment';
            if(file){
                if(file.length > 0){
                    x = 'Want to change the attached file? Click here.';
                }
                else{
                    x = 'Select file to attach';
                }
            }
            return x;
        },
        submit(){
            console.log('1');
            if (this.$refs.templateForm.validate() && this.$refs.attachmentForm.validate()) {
                var _this = this;
                console.log('2');
                //var name = this.$store.state.templateName;
                if(_this.primary_btn_txt == 'Add'){
                    document.querySelector('#add-new-email-template-card .primary .v-btn__content span').innerHTML = '';
                    _this.$store.state.templateActionInProgress = true;
                    _this.$store.state.templateActionBtnDisabled = true;
                    var createTempalteData = {
                        "sender":{
                            "email": _this.currentUser.email,
                            "id": ""
                        },
                        "subject": _this.templateSubject,
                        "reply_to": _this.templateReplyTo,
                        "body": _this.templateBody,
                        "name": _this.templateName,
                        "from_name": _this.templateFromName,
                        "html_content":_this.templateBody,
                        "signature": "",
                        "attachment_name": _this.UploadedFileName,
                    };
                    _this.$store.dispatch('addEmailTemplate', createTempalteData);
                }
                else if(this.primary_btn_txt == 'Update'){
                    var attachment = '';
                    if(_this.UploadedFileName){
                        attachment = _this.UploadedFileName
                    }
                    else{
                        attachment = _this.emailTemplateAttachmentName
                    }
                    document.querySelector('#add-new-email-template-card .primary .v-btn__content span').innerHTML = '';
                    _this.$store.state.templateActionInProgress = true;
                    _this.$store.state.templateActionBtnDisabled = true;
                    var updateTempalteData = {
                        "sender":{
                            "email": _this.currentUser.email,
                            "id": ""
                        },
                        "subject": _this.templateSubject,
                        "reply_to": _this.templateReplyTo,
                        "body": _this.templateBody,
                        "name": _this.templateName,
                        "from_name": _this.templateFromName,
                        "html_content":_this.templateBody,
                        "signature": "",
                        "attachment_name": attachment,
                    };
                    
                    _this.$store.dispatch('updateEmailTemplate', {updateTempalteData : updateTempalteData, templateID : _this.templateID});
                }
                else{
                    _this.$store.state.responseMsg = 'Error: Could not perform action';
                    _this.$store.state.ResponseClass = 'error';
                }
            }
        },
        cancel(){
            this.closeAddEmailTemplateBox();
        },
        onResize() {
            if((this.$refs.templateCard.clientHeight + 40)> (window.innerHeight - 100)){
                this.cardHeight = (window.innerHeight - 100)+'px';
            }
            else{
                this.cardHeight = 'auto';
            }
        },
        ListFilesUploaded(){
            Storage.list('') // for listing ALL files without prefix, pass '' instead
            .then(result => console.log(result))
            .catch(err => console.log(err));
        },
        closeAddEmailTemplateBox(){
            this.$store.state.templateName = '';
            this.$store.state.templateFromName = '';
            this.$store.state.templateSubject = '';
            this.$store.state.templateBody = '';
            this.$store.state.templateID = '';
            this.$store.state.templateReplyTo = '';
            this.$store.state.emailTemplateAttachment = [];
            this.$store.state.emailTemplateAttachmentName = '';
            this.$store.state.addEmailTemplateBox = false;
        },
        checkUploadedFile2(){
            var fileName = document.getElementById('attachment-file').files[0];
            this.emailTemplateAttachment = fileName;
            this.checkUploadedFile();
        },
        checkUploadedFile(){
            var _this = this;
            if(this.emailTemplateAttachment != [] && this.emailTemplateAttachment != null && this.emailTemplateAttachment != undefined){
                if(this.emailTemplateAttachment.name){
                    if(this.emailTemplateAttachment.name.indexOf(' ') < 0){
                        if(this.emailTemplateAttachment.size <= (5*1024*1024)){
                            this.$store.state.emailTemplateAttachmentName = '';
                            this.fileUploading = true;
                            this.UploadedFileName = this.emailTemplateAttachment.name.replace(/(\.[\w\d_-]+)$/i, '_@'+(new Date().getTime())+'$1');
                            Storage.put(_this.UploadedFileName, _this.emailTemplateAttachment, {
                                progressCallback(progress) {
                                    _this.uploadProgress = (progress.loaded/progress.total)*100;
                                    if(_this.uploadProgress == 100){
                                        _this.fileUploading = false;
                                    }
                                    //console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                                },
                            });
                        }
                        else{
                            _this.uploadProgress = 0;
                            this.$store.state.emailTemplateAttachment = null;
                            this.$store.state.emailTemplateAttachmentName = '';
                            this.UploadedFileName = '';
                            this.fileUploading = null;
                            this.uploadProgress = 0;
                            this.$store.state.responseMsg = 'File is greater than 5MB';
                            this.$store.state.ResponseClass = 'error';
                        }
                    }
                    else{
                        _this.uploadProgress = 0;
                        this.$store.state.emailTemplateAttachment = null;
                        this.$store.state.emailTemplateAttachmentName = '';
                        this.UploadedFileName = '';
                        this.fileUploading = null;
                        this.uploadProgress = 0;
                        this.$store.state.responseMsg = 'File name should not have spaces';
                        this.$store.state.ResponseClass = 'error';
                        return true;
                    }
                }
                else{
                    _this.uploadProgress = 0;
                    console.log(this.$store.state.emailTemplateAttachment);
                    console.log(this.$store.state.emailTemplateAttachmentName);
                    console.log('Error1');
                    return false;
                }
            }else{
                _this.uploadProgress = 0;
                console.log('Error2');
                return false;
            }
        },
        /* DownloadFile(){
            var _this = this;
            Storage.get(_this.$store.state.emailTemplateAttachment.key, { download: true }).then(data=> {
                _this.downloadBlob(data.Body, _this.$store.state.emailTemplateAttachmentName);
            });
        },
        downloadBlob(blob, filename) {
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename || 'download';
          const clickHandler = () => {
            setTimeout(() => {
              URL.revokeObjectURL(url);
              a.removeEventListener('click', clickHandler);
            }, 150);
          };
          a.addEventListener('click', clickHandler, false);
          a.click();
          return a;
        } */
        /* checkManualFile(e){
            console.log(e);
        } */
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
            });
        this.onResize();
    },

    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize); 
    },
    computed:{
        fieldDisabled(){
            var x = false;
            if(this.primary_btn_txt == 'Add'){
                x = false;
            }
            else if(this.primary_btn_txt == 'Update'){
                x = true;
            }
            return x;
        },
        primary_btn_txt(){
            return this.$store.state.primary_btn_txt;
        },
        emailTemplateHd(){
            return this.$store.state.emailTemplateHd;
        },
        templateID(){
            return this.$store.state.templateID;
        },
        templateName:{
            get()
            {
                return this.$store.state.templateName;
            },
            set(value)
            {
                this.$store.state.templateName = value
            }
        },
        templateFromName:{
            get()
            {
                return this.$store.state.templateFromName;
            },
            set(value)
            {
                this.$store.state.templateFromName = value
            }
        },
        templateReplyTo:{
            get()
            {
                return this.$store.state.templateReplyTo;
            },
            set(value)
            {
                this.$store.state.templateReplyTo = value
            }
        },
        templateFrom:{
            get()
            {
                return this.$store.state.templateFrom;
            },
            set(value)
            {
                this.$store.state.templateFrom = value
            }
        },
        currentUser(){
            return this.$store.state.user.attributes;
        },
        templateSubject:{
            get()
            {
                return this.$store.state.templateSubject;
            },
            set(value)
            {
                this.$store.state.templateSubject = value
            }
        },
        templateBody:{
            get()
            {
                return this.$store.state.templateBody;
            },
            set(value)
            {
                this.$store.state.templateBody = value
            }
        },
        templateActionInProgress(){
            return this.$store.state.templateActionInProgress;
        },
        templateActionBtnDisabled(){
          return this.$store.state.templateActionBtnDisabled
        },
        emailTemplateAttachment:{
            get(){
                return this.$store.state.emailTemplateAttachment;
            },
            set(value){
                this.$store.state.emailTemplateAttachment = value;
            }
        },
        emailTemplateAttachmentName(){
            if(!this.downloadURL){
                this.DownloadFileBlob(this.$store.state.emailTemplateAttachmentName)
            }
            return this.$store.state.emailTemplateAttachmentName;
        }
    }
}
</script>
<style scoped>
.theme--light.v-text-field--filled > .v-input__control > .v-input__slot{
    background: #fff !important;
}
#add-new-email-template-container{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    max-width: 100%;
    background-color: rgba(0,0,0,0.3);
}
#add-new-email-template-card{
    background-color: #FAFAFA;
    padding: 0 75px 40px;
    margin: 38px auto;
    overflow-y: auto;
}
#add-new-email-template-card h1{
    margin: 25px 0 40px;
    text-align:center;
    color: #000;
}
.v-card__actions{
    padding: 0 16px;
    margin-left: 33px;
}
/* width */
#add-new-email-template-card::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#add-new-email-template-card::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
#add-new-email-template-card::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
#add-new-email-template-card::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.close_btn{
    position: absolute;
    right: 10px;
    top: 40px;
    z-index: 999;
    color: rgba(0,0,0,0.8);
    padding: 2px 10px;
    font-size: 20px;
    cursor: pointer;
    transition: 0.5s all;
}

.add-new-email-template-card-wrapper{
    position: relative;
}
.v-card__actions button{
    padding: 10px 30px !important;
    min-width: 150px !important;
}
.v-input>>>.v-messages__message{
    color: rgba(0,0,0,0.5) !important
}
.v-progress-linear, p.attachment-name{
    margin-bottom: 20px;
    width: 93%;
    margin-left: auto;
}
.v-file-input >>> .v-input__slot, .v-file-input >>> .v-text-field__details{
    padding: 0px !important;
}
.v-file-input >>> .v-input__slot fieldset{
    border: 0px !important
}
.v-file-input >>> .v-input__slot .v-file-input__text{
    cursor: pointer;
}
.v-file-input >>> .v-input__slot .v-file-input__text span{
    margin: 0px !important;
    cursor: pointer;
}
.v-progress-linear{
    pointer-events: none;
}
.drag-area{
  border: 2px dashed rgba(0,0,0,0.5);
  margin: auto;
  height: 200px;
  width: 93%;
  margin-left: 33px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.drag-area.active{
  border: 2px solid rgba(0,0,0,0.5);
}
.drag-area .NewUpload{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
}
.drag-area .NewUpload .icon{
  color: rgba(0,0,0,0.3);
}
.drag-area .NewUpload .icon span{
    font-size: 70px;
    margin-bottom: 0px;
}
.drag-area .NewUpload header{
  font-size: 24px;
  font-weight: 500;
  color: rgba(0,0,0,0.5);
  cursor: pointer;
}
.drag-area .NewUpload span{
  font-size: 25px;
  font-weight: 500;
  color: rgba(0,0,0,0.5);
  margin: 10px 0 15px 0;
}

.drag-area .NewUpload img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.drag-area .NewUpload input{
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.File-attachment-disabled{
    pointer-events: none;
}
</style>