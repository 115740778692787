<template>
  <div>
    <v-container>
      <v-row no-gutters class="subHeaderRow">
        <template v-if="home">
          <v-col cols="12" sm="7" id="header-left">
            <h1>{{ getTitle }}</h1>
          </v-col>
          <v-col cols="12" sm="5" id="header-right">
            <v-row no-gutters>
              <v-col cols="12" sm="4" class="export-campaign-btn">
                <!-- <v-btn rounded class="elevation-0">Export</v-btn> -->
              </v-col>

              <v-col cols="12" sm="5" class="search-campaign-field">
                <!-- <v-text-field
                                        label="Search"
                                        outlined
                                        rounded
                                        dense
                                        prepend-inner-icon="search"
                                    ></v-text-field> -->
              </v-col>
              <v-col cols="12" sm="3" class="add-campaign-btn">
                <!-- <v-btn v-if="csvUploadBtn" @click="openCsvUploadBox"><span class="material-icons" style="font-size: 30px">add</span></v-btn> -->
                <v-btn
                  v-if="emailTemplateUploadBtn"
                  @click="openAddEmailTemplateBox('add')"
                  ><span class="material-icons" style="font-size: 30px"
                    >add</span
                  ></v-btn
                >
                <v-btn v-if="AddUserBtn" @click="openAddUserBox('add')"
                  ><span class="material-icons" style="font-size: 30px"
                    >add</span
                  ></v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </template>
        <template v-if="addNewCampaign">
          <v-col cols="12" sm="6" id="header-left" :class="subHeaderClass">
            <h1 v-if="editCampaign">
              <v-text-field
                name="campaignTitle"
                label="Campaign Name Here"
                type="text"
                v-model="campaignTitle"
                required
                color="#777BD2"
                loading
              >
                <template v-slot:progress>
                  <v-progress-linear
                    :value="progress"
                    :color="color"
                    absolute
                    height="3"
                  ></v-progress-linear>
                </template>
              </v-text-field>
              <!-- <quick-edit v-model="campaignTitle" :placeholder="'Campaign Name'" ></quick-edit>-->
            </h1>
            <h1 v-if="!editCampaign">{{ campaignTitle }}</h1>
          </v-col>
          <v-col cols="12" sm="6" id="header-right">
            <v-row no-gutters>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Template</th>
                      <th>Open Rate</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        v-if="editCampaign || pendingCampaign"
                        class="header-email-template-select"
                      >
                        <v-icon
                          v-if="campaignTemplate != ''"
                          @click="viewEmailTemplate(campaignTemplate)"
                          class="viewEye"
                          >remove_red_eye</v-icon
                        ><v-select
                          :items="templatesList"
                          label="Campaign Template"
                          color="#777BD2"
                          v-model="campaignTemplate"
                        ></v-select>
                      </td>
                      <td v-if="!editCampaign && !pendingCampaign" class="campaign-template">
                        <v-icon
                          v-if="campaignTemplate != ''"
                          @click="viewEmailTemplate(campaignTemplate)"
                          class="viewEye"
                          >remove_red_eye</v-icon
                        > <span>{{ campaignTemplate }}</span>
                      </td>
                      <td v-if="campaignData && campaignData.data.status == 'SENT'">{{ openRate }}</td>
                      <td v-if="campaignData && $store.state.campaignData.data.status != 'SENT'">-</td>
                      <td v-if="!NewUpload">
                        {{
                          getMonth() +
                            "/" +
                            new Date(campaignDate).getDate() +
                            "/" +
                            new Date(campaignDate).getFullYear()
                        }}
                      </td>
                      <td v-if="NewUpload">
                        {{
                          getMonth() +
                            "/" +
                            new Date().getDate() +
                            "/" +
                            new Date().getFullYear()
                        }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-row>
          </v-col>
        </template>
      </v-row>
    </v-container>
    <transition name="fade"
      ><upload-csv v-if="showCsvUploadBox"></upload-csv
    ></transition>

    <transition name="fade"
      ><add-new-email-template
        v-if="showAddEmailTemplateBox"
      ></add-new-email-template
    ></transition>

    <transition name="fade"
      ><add-new-user v-if="showAddUserBox"></add-new-user
    ></transition>

    <transition name="fade"
      ><response-msg
        v-if="!!ResponseMsg && $route.path != '/sign-in'"
        :Msg="ResponseMsg"
        :wrapperClass="ResponseClass"
        :data="responseData"
        @close="ResponseMsgClose"
      ></response-msg
    ></transition>

    <transition name="fade"
      ><view-email-template
        v-if="!!TemplateData"
        :template="TemplateData"
        @close="TemplateData = ''"
      ></view-email-template
    ></transition>

    <transition name="fade-transition"
      ><route-confirmation-dialog
        v-if="routeConfirmationDialog"
        :Msg="routeChangeMsg"
        primaryTxt="Yes"
        @close="cancelRouteChange()"
      ></route-confirmation-dialog
    ></transition>
  </div>
</template>
<script>
import UploadCsv from "@/components/UploadCsv.vue";
import AddNewEmailTemplate from "@/components/AddNewEmailTemplate.vue";
import AddNewUser from "@/components/AddNewUser.vue";
import ResponseMsg from "@/components/ResponseMsg.vue";
import ViewEmailTemplate from "@/components/ViewEmailTemplate.vue";
import RouteConfirmationDialog from "@/components/routeConfirmationDialog.vue";
export default {
  name: "AppHeader",
  components: {
    UploadCsv,
    AddNewEmailTemplate,
    ResponseMsg,
    ViewEmailTemplate,
    AddNewUser,
    RouteConfirmationDialog
  },
  data() {
    return {
      title: "Recent Campaigns",
      home: true,
      addNewCampaign: false,
      csvUploadBtn: false,
      emailTemplateUploadBtn: false,
      AddUserBtn: false,
      editCampaign: false,
      NewUpload: false,
      pendingCampaign: false,
      paddingBottom: "25px",
      subHeaderClass: "",
      daata: ""
    };
  },
  mounted() {
    this.setHeader();
  },
  methods: {
    Title() {
      if (this.$route.path == "/") {
        this.title = "Recent Campaigns";
      } else if (this.$route.path == "add-new-campaign") {
        this.title = "Campaign 1";
      } else {
        this.title = this.$route.name;
      }
    },
    openCsvUploadBox() {
      this.$store.state.csvUploadBox = true;
    },
    openAddEmailTemplateBox(param) {
      if (param == "add") {
        this.$store.state.templateReplyTo = this.currentUser.email;
        this.$store.state.primary_btn_txt = "Add";
        this.$store.state.emailTemplateHd = "Create Email Template";
      } else {
        this.$store.state.primary_btn_txt = "Update";
        this.$store.state.emailTemplateHd = "Edit Email Template";
      }
      this.$store.state.addEmailTemplateBox = true;
    },
    openAddUserBox(param) {
      if (param == "add") {
        this.$store.state.add_user_primary_btn_txt = "Add";
        this.$store.state.userHd = "Add New User";
      } else {
        this.$store.state.add_user_primary_btn_txt = "Update";
        this.$store.state.userHd = "Edit User";
      }
      this.$store.state.addUserBox = true;
    },
    setHeader() {
      if (this.$route.path == "/") {
        this.home = true;
        this.addNewCampaign = false;
        this.csvUploadBtn = false;
        this.emailTemplateUploadBtn = false;
        this.AddUserBtn = false;
      } else if (this.$route.path == "/all-campaigns") {
        this.home = true;
        this.addNewCampaign = false;
        this.csvUploadBtn = true;
        this.emailTemplateUploadBtn = false;
        this.AddUserBtn = false;
        this.show = true;
      } else if (this.$route.path == "/add-new-campaign") {
        this.home = false;
        this.addNewCampaign = true;
        this.csvUploadBtn = false;
        this.emailTemplateUploadBtn = false;
        this.AddUserBtn = false;
        this.editCampaign = true;
        this.NewUpload = true;
        this.show = true;
      } else if (this.$route.name == "Campaign Detail") {
        if (this.$route.params.edit == true) {
          this.home = false;
          this.addNewCampaign = true;
          this.csvUploadBtn = false;
          this.emailTemplateUploadBtn = false;
          this.AddUserBtn = false;
          this.editCampaign = true;
          this.NewUpload = false;
          this.pendingCampaign = false;
          this.subHeaderClass = "editCampaign";
          this.show = true;
        } else if (this.$route.params.pending == true) {
          this.home = false;
          this.addNewCampaign = true;
          this.csvUploadBtn = false;
          this.emailTemplateUploadBtn = false;
          this.AddUserBtn = false;
          this.editCampaign = false;
          this.NewUpload = false;
          this.pendingCampaign = true;
          this.subHeaderClass = "";
          this.show = true;
        } else {
          this.home = false;
          this.addNewCampaign = true;
          this.csvUploadBtn = false;
          this.emailTemplateUploadBtn = false;
          this.AddUserBtn = false;
          this.editCampaign = false;
          this.NewUpload = false;
          this.pendingCampaign = false;
          this.subHeaderClass = "";
          this.show = true;
        }
      } else if (this.$route.path == "/email-templates") {
        this.home = true;
        this.addNewCampaign = false;
        this.AddUserBtn = false;
        this.csvUploadBtn = false;
        this.emailTemplateUploadBtn = true;
        this.show = true;
      } else if (this.$route.path == "/management/users") {
        this.home = true;
        this.addNewCampaign = false;
        this.csvUploadBtn = false;
        this.emailTemplateUploadBtn = false;
        this.AddUserBtn = true;
        this.editCampaign = false;
        this.NewUpload = false;
        this.show = true;
      } else if (this.$route.path == "/management/rules") {
        this.home = true;
        this.addNewCampaign = false;
        this.csvUploadBtn = false;
        this.emailTemplateUploadBtn = false;
        this.AddUserBtn = false;
        this.editCampaign = false;
        this.NewUpload = false;
        this.show = true;
      } else {
        this.home = true;
        this.addNewCampaign = false;
        this.csvUploadBtn = true;
        this.AddUserBtn = false;
        this.emailTemplateUploadBtn = false;
        this.show = true;
      }
    },
    getMonth() {
      var month = "";
      if (!this.NewUpload) {
        month = new Date(this.campaignDate).getMonth() + 1;
      } else {
        month = new Date().getMonth() + 1;
      }

      if (month < 10) {
        month = "0" + month;
      }
      return month;
    },
    getTemplates() {
      this.$store.dispatch("getEmailTemplates", this.currentUser.email);
    },
    ResponseMsgClose() {
      this.$store.state.responseMsg = "";
      this.$store.state.ResponseClass = "";
      this.$store.state.responseData = "";
    },
    viewEmailTemplate(emailTemplate) {
      if (this.emailTemplatesData.find(e => e["name"] == emailTemplate)) {
        this.$store.state.TemplateData = this.emailTemplatesData.find(
          e => e["name"] == emailTemplate
        );
      } else {
        this.$store.state.responseMsg = "Email Template Not Found";
        this.$store.state.ResponseClass = "error";
      }
    },
    cancelRouteChange() {
      this.$store.state.routeConfirmationDialog = false;
      this.$store.state.routeTo = "";
      this.$store.state.routeChangeMsg = "";
    }
  },
  computed: {
    getTitle() {
      //this.setHeader();
      if (this.$route.path == "/") {
        return "Get Started";
      } else {
        return this.$route.name;
      }
    },
    showCsvUploadBox() {
      return this.$store.state.csvUploadBox;
    },
    showAddEmailTemplateBox() {
      return this.$store.state.addEmailTemplateBox;
    },
    showAddUserBox() {
      return this.$store.state.addUserBox;
    },
    currentUser() {
      return this.$store.state.user.attributes;
    },
    campaignData(){
      return this.$store.state.campaignData;
    },
    openRate() {
      var _this = this;
      if (this.$store.state.campaignData.data) {
        let totalSentEmails;
        let openedEmails = this.$store.state.campaignData.data.clients.filter(
          e => e.delivery_status == 1
        ).length;
        if(this.$store.state.campaignData.data.clients.filter(e => e.status == 'subscribed').length){
          totalSentEmails = _this.$store.state.campaignData.data.clients.filter(e => e.status == 'subscribed').length;
        }
        else{
          totalSentEmails = _this.$store.state.campaignData.data.clients.length
        }
        let result = (openedEmails / totalSentEmails) * 100;
        return parseInt(result) + "%";
      }

      return 0;
    },
    campaignTitle: {
      get() {
        return this.$store.state.campaignTitle;
      },
      set(value) {
        this.$store.state.campaignTitle = value;
      }
    },
    templatesList() {
      var x = "";
      var list = [];
      if (this.$store.state.EmailTemplates == undefined) {
        this.getTemplates();
      } else if (this.$store.state.EmailTemplates) {
        x = this.$store.state.EmailTemplates.data;
        // eslint-disable-next-line
                x.forEach((value, index) => {
          list.push(value.name);
        });
      } else {
        this.getTemplates();
      }
      return list;
    },
    emailTemplatesData() {
      var x = "";
      if (
        (this.$store.state.EmailTemplates == undefined ||
          this.$store.state.EmailTemplates == null) &&
        this.currentUser.email != ""
      ) {
        this.getTemplates();
      } else if (
        this.$store.state.EmailTemplates &&
        this.currentUser.email != ""
      ) {
        x = this.$store.state.EmailTemplates.data;
      }
      return x;
    },
    campaignTemplate: {
      get() {
        return this.$store.state.campaignTemplate;
      },
      set(value) {
        this.$store.state.campaignTemplate = value;
      }
    },
    campaignDate() {
      return this.$store.state.campaignDate;
    },
    ResponseMsg: {
      get() {
        return this.$store.state.responseMsg;
      },
      set(value) {
        this.$store.state.responseMsg = value;
      }
    },
    TemplateData: {
      get() {
        return this.$store.state.TemplateData;
      },
      set(value) {
        this.$store.state.TemplateData = value;
      }
    },
    ResponseClass() {
      return this.$store.state.ResponseClass;
    },
    responseData() {
      return this.$store.state.responseData;
    },
    routeConfirmationDialog() {
      return this.$store.state.routeConfirmationDialog;
    },
    routeChangeMsg() {
      return this.$store.state.routeChangeMsg;
    },
    progress() {
      var x = "";
      if (this.$store.state.campaignTitle) {
        x = Math.min(100, this.$store.state.campaignTitle.length * 33.4);
      } else {
        x = Math.min(100, "".length * 10);
      }
      return x;
    },
    color() {
      return ["error", "warning", "success"][Math.floor(this.progress / 40)];
    }
  },
  watch: {
    $route: function() {
      this.setHeader();
      if (this.$route.path == "/") {
        this.paddingBottom = "25px";
      } else {
        this.paddingBottom = "0px";
      }
    }
  }
};
</script>
<style scoped>
#header-left h1 {
  line-height: 1;
  display: flex;
  align-items: center;
  padding: 3px 0;
}
#header-right .add-campaign-btn button {
  font-weight: 500;
  height: 35px;
  border-radius: 35px;
  padding: 0 17px;
  box-shadow: none;
  background-color: #ff5d6d;
  color: #fff;
}
#header-right .col-12 {
  text-align: right;
}
#header-right .export-campaign-btn button {
  padding: 11px 35px;
  height: auto;
}
/* #header-right, #header-left{
    padding-top: 20px;
} */
.container > .row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.subHeaderRow {
  padding: 25px 0px;
}
#header-left.editCampaign {
  display: flex;
}
#header-left.editCampaign .v-input {
  font-size: 30px;
  height: 100%;
  line-height: 75px;
  align-items: center;
}
.header-email-template-select .v-text-field--is-booted {
  display: inline-block;
  width: 80%;
  margin-left: 15px;
}
.viewEye {
  opacity: 0.6;
}
.viewEye:hover {
  opacity: 1;
}
.campaign-template .viewEye{
  display: inline-block;
  vertical-align: top;
}
.campaign-template span{
  display: inline-block;
  vertical-align: top;
}

@media screen and (min-width: 960px) {
  #header-right .export-campaign-btn.col-12 {
    text-align: right;
  }
  .campaign-template span{
    width: 70%;
  }
}
@media screen and (max-width: 959px) {
  #header-right .col-12 {
    float: left;
  }
  .campaign-template span{
    width: 70%;
  }
}
@media screen and (min-width: 1260px) {
  .campaign-template span{
    width: 82%;
  }
}
@media screen and (max-width: 600px) {
  #header-right .col-12 {
    float: left;
  }
  .campaign-template span{
    width: 80%;
  }
}
</style>
