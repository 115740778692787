<template>
    <v-stepper v-model="campaignUploadStep" class="elevation-0 getStarted" id="campaignUploadStepper">
        <v-stepper-header>
            <v-stepper-step
                :complete="campaignUploadStep > 1"
                step="1"
            >
                Upload CSV
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
                :complete="campaignUploadStep > 2"
                step="2"
            >
                Create Campaign
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
                :complete="campaignUploadStep > 3"
                step="3"
            >
                Select Email Template
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="4">
                Send/Schedule Campaign
            </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
            <v-stepper-content step="1">
                <v-card
                    class="mb-12 elevation-0 upload-csv"
                >
                    <div class="drag-area">
                        <div class="NewUpload" :class="NewUploadClass">
                            <div class="icon"><span class="material-icons">cloud_upload</span></div>
                            <header>Drag and Drop</header>
                            <input
                                id="csv-file"
                                type="file"
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                label="File input"
                                @change="checkUploadedFile"
                            />
                            <p><v-divider></v-divider>or<v-divider></v-divider></p>
                            <div id="csv-file-container"><input
                                id="browse-csv-file"
                                type="file"
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                label="File input"
                                @change="checkUploadedFile2"
                            /></div>
                            <!-- eslint-disable-next-line -->
                            <p id="csv-file-name">{{UploadedFileName}}</p><transition name="fade-transition"><p class="error" dark v-if="!!error">{{error}}</p></transition>
                        </div>
                        <div class="Uploaded" v-if="fileUploaded == true">
                            <v-img
                                class="success-tick"
                                src='@/assets/img/success-tick.png'
                                max-width="50"
                            ></v-img>
                            <p>File uploaded successfully. Please proceed to the next step</p>
                            <p id="csv-file-name">{{UploadedFileName}}</p><transition name="fade-transition"><p class="error" dark v-if="!!error">{{error}}</p></transition>
                        </div>
                    </div>
                </v-card>
                <v-card-actions class="text-right upload-csv-actions">
                    <v-btn class="elevation-0 secondary" title="Download Sample CSV File" href='/csv-sample.csv' download="sample-spreadsheet.csv">
                        <v-icon left dark>mdi-download</v-icon>
                        Sample CSV File
                    </v-btn>
                    <v-btn
                        @click="cancelUpload"
                        class="elevation-0 right-btn"
                        :class="NewUploadCancelClass"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        @click="parseCSV"
                        class="primary elevation-0"
                    >
                        Next
                    </v-btn>
                </v-card-actions>
            </v-stepper-content>

            <v-stepper-content step="2" class="campaignUploaded">
                <v-card
                    class="mb-12 elevation-0 edit-csv"
                >
                    <template>
                        <v-skeleton-loader
                            class="mx-auto"
                            type="table-heading, table-thead, table-tbody"
                            v-if="getSubscriptionStatusInProgress"
                        ></v-skeleton-loader>
                    </template>
                    <template v-if="$store.state.csv_data && !getSubscriptionStatusInProgress">
                    <v-data-table
                        :headers="headers"
                        :items="$store.state.csv_data"
                        :items-per-page="5"
                        :page="currentPageNumber"
                        class="elevation-0"
                        :search="search"
                        :custom-filter="filterSearch"
                    >
                        <template v-slot:top>
                            <v-row no-gutters>
                                <v-col cols="12" sm="5">
                                    <v-form ref="campaignForm" v-model="valid" lazy-validation v-on:submit.prevent="toSelectEmailTemplateStep">
                                        <v-text-field
                                            v-model="stepperCampaignName"
                                            label="Campaign Name"
                                            :rules="campaignNameRules"
                                            required
                                            id="stepper_campaign_name"
                                            color="#777BD2"
                                            ref="campaign_name"
                                            style="margin-bottom:15px"
                                            prepend-icon="campaign"
                                        ></v-text-field>
                                    </v-form>
                                </v-col>
                                <v-col cols="12" sm="2"></v-col>
                                <v-col cols="12" sm="5">
                                    <v-text-field
                                        prepend-icon="search"
                                        class="dataTable"
                                        color="#777BD2"
                                        v-model="search"
                                        label="Search"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-toolbar
                                flat
                            >
                                <v-dialog
                                    v-model="dialog"
                                    max-width="500px"
                                >
                                <v-card>
                                    <v-card-title>
                                    <span class="headline">{{ formTitle }}</span>
                                    </v-card-title>

                                    <v-card-text>
                                    <v-container>
                                        <v-row>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="4"
                                        >
                                            <v-text-field
                                            v-model="editedItem.email"
                                            label="Email"
                                            color="#777BD2"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="4"
                                        >
                                            <v-text-field
                                            v-model="editedItem.company_name"
                                            label="Company Name"
                                            color="#777BD2"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="4"
                                        >
                                            <v-text-field
                                            v-model="editedItem.link_to_company"
                                            label="Company Website"
                                            color="#777BD2"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="4"
                                        >
                                            <v-text-field
                                            v-model="editedItem.category"
                                            label="Category"
                                            color="#777BD2"
                                            ></v-text-field>
                                        </v-col>
                                        </v-row>
                                    </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                    class="elevation-0"
                                        @click="close"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        class="primary elevation-0"
                                        @click="save"
                                    >
                                        Save
                                    </v-btn>
                                    </v-card-actions>
                                </v-card>
                                </v-dialog>
                                <v-dialog v-model="dialogDelete" max-width="500px">
                                <v-card class="confirmation-wrapper">
                                    <div class="card-header">
                                        <p>Are you sure you want to delete this item?</p>
                                    </div>
                                    <div class="card-footer">
                                        <v-btn class="elevation-0 primary" @click="deleteItemConfirm">Yes</v-btn>
                                        <v-btn class="elevation-0" @click="closeDelete">No</v-btn>
                                    </div>
                                </v-card>
                                </v-dialog>
                            </v-toolbar>
                        </template>
                        <template v-slot:item="{ item }">
                            <tr :class="item.flag_class">
                                <td class="email" :title="item.email">{{ item.email }}</td>
                                <td class="company_name" :title="item.company_name">{{ item.company_name }}</td>
                                <td class="phone_number" :title="item.phone_number">{{ item.phone_number }}</td>
                                <td class="website" :title="item.link_to_company">{{ item.link_to_company }}</td>
                                <td class="category" :title="item.category">{{ item.category }}</td>
                                <td class="status text-center">
                                    <!-- <v-progress-circular
                                        indeterminate
                                        color="#777BD2"
                                        v-if="getSubscriptionStatusInProgress"
                                        style="margin:auto"
                                    ></v-progress-circular> -->
                                    <span v-if="item.status == 'subscribed'" class="material-icons subscribed" style="margin:auto" title="Subscribed">notifications</span>
                                    <span v-if="item.status == 'un-subscribed'" class="material-icons un-subscribed" title="Un-Subscribed" style="margin:auto">notifications_off</span>
                                </td>
                                <td class="actions text-center">
                                    <v-icon
                                        
                                        class="mr-2"
                                        @click="editItem(item)"
                                    >
                                        mdi-pencil
                                    </v-icon>
                                    <v-icon
                                        
                                        @click="deleteItem(item)"
                                    >
                                        mdi-delete
                                    </v-icon>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                    
                    </template>
                </v-card>
                <v-card-actions class="text-right">
                    <v-btn @click="cancelCampaign" class="elevation-0 right-btn">
                        Cancel
                    </v-btn>
                    <v-btn
                        @click="toSelectEmailTemplateStep"
                        class="primary elevation-0"
                    >
                        Create
                    </v-btn>
                </v-card-actions>
            </v-stepper-content>

            <v-stepper-content step="3">
                <v-card
                    class="mb-12 elevation-0 selectEmailTemplate"
                >
                    <v-row>
                        <v-form ref="emailForm" v-model="validEmail" lazy-validation style="width:100%;">
                        <v-radio-group v-model="stepperCampaignTemplate" row :rules="emailTemplateRule" @change="checkEmailTemplate" id="templateRadio">
                            <v-col 
                                cols="12" sm="3" 
                                v-for="(item, index) in emailTemplatesData"
                                :key="item.id">
                                    <v-card class="elevation-0" @click="stepperCampaignTemplate = item.name; checkEmailTemplate()">
                                        <v-card-title>{{item.name}}</v-card-title>
                                        <v-card-text>
                                            <b>Subject: {{ item.subject }}</b><br/>
                                            <div :id="item.name+'-emailTempBody'" class="emailTempBody">
                                                <span :class="getOverflowClass(item.name)">{{item.body}}</span>
                                            </div>
                                            <p v-if="item.attachment_name">Attachment: <a @click="downloadBlob(downloadURL[index], item.attachment_name.replace(/\_@.*\./, '.'), index)">{{item.attachment_name.replace(/\_@.*\./, '.')}}</a></p>
                                        </v-card-text>
                                        <v-card-actions class="more-options text-center">
                                            <v-icon @click="openAddEmailTemplateBox('Update', item)" :class="'disabled-'+templateActionBtnDisabled">edit</v-icon>
                                            <a @click="deleteEmailTemplate(item)" :class="'disabled-'+templateActionBtnDisabled">
                                            <v-progress-circular
                                                indeterminate
                                                color="#777BD2"
                                                v-if="selectedID == item.id"
                                            ></v-progress-circular>
                                            <v-icon v-if="selectedID != item.id" class="deleteBtn">delete</v-icon>
                                            </a>
                                            <transition name="fade-transition"><confirmation-dialog v-if="dialogId == item.id" actionName="deleteEmailTemplate" primaryTxt="Delete" :item="item" @close="dialogId = ''"></confirmation-dialog></transition>
                                        </v-card-actions>
                                    </v-card>
                                <v-radio color="#777BD2" :value="item.name"></v-radio>
                            </v-col>
                            <v-col cols="12" sm=3 class="addEmailTemplate">
                                <v-card class="elevation-0" @click="openNewAddEmailTemplateBox('add')">
                                    <v-card-text>
                                        <v-icon>add</v-icon>
                                        <p>Create New Email Template</p>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-radio-group>
                        </v-form>
                    </v-row>
                </v-card>
                <v-card-actions class="text-right">
                    <v-btn @click="goBack" class="elevation-0 right-btn">
                        Back
                    </v-btn>
                    <v-btn
                        @click="finalCreateCampaignStep"
                        class="primary elevation-0"
                    >
                        Next
                    </v-btn>
                </v-card-actions>
            </v-stepper-content>
            <v-stepper-content step="4">
                <v-card
                    class="mb-12 elevation-0"
                >
                    <v-row>
                        <v-col cols="12" sm="6">
                            <p><b style="opacity: 0.4">Campaign Name: </b><b>{{stepperCampaignName}}</b></p>
                        </v-col>
                        <v-col cols="12" sm="6" class="text-right">
                            <p><b style="opacity: 0.4">Email Template: </b><b>{{stepperCampaignTemplate}}</b></p>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12" sm="12">
                            <template v-if="$store.state.csv_data">
                                <v-data-table
                                    :headers="headers2"
                                    :items="$store.state.csv_data"
                                    :items-per-page="5"
                                    class="elevation-0"
                                >
                                    <template v-slot:item="{ item }">
                                        <tr>
                                            <td class="email">{{ item.email }}</td>
                                            <td class="company_name">{{ item.company_name }}</td>
                                            <td class="phone_number">{{ item.phone_number }}</td>
                                            <td class="website">{{ item.link_to_company }}</td>
                                            <td class="category">{{ item.category }}</td>
                                            <td class="status text-center">
                                                <span v-if="item.status == 'subscribed'" class="material-icons subscribed" style="margin:auto" title="Subscribed">notifications</span>
                                                <span v-if="item.status == 'un-subscribed'" class="material-icons un-subscribed" title="Un-Subscribed" style="margin:auto">notifications_off</span>
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </template>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card-actions class="text-right">
                    <v-btn @click="goBack" class="elevation-0 right-btn"> 
                        Back
                    </v-btn>
                    <v-btn
                        color="secondary"
                        class="elevation-0"
                        @click="openScheduler"
                        :disabled="saveCampaignInProgress"
                    >Schedule</v-btn>
                    <v-btn
                        color="#777BD2"
                        @click="sendEmail"
                        class="elevation-0"
                        dark
                        :disabled="saveCampaignInProgress"
                    >Send Now</v-btn>
                    <transition name="fade-transition"><send-email-confirmation v-if="SendEmailConfirmation" status="send_new" batch_id="" batchName="" @close="SendEmailConfirmation = false"></send-email-confirmation></transition>
                </v-card-actions>
                <v-container fill-height class="scheduler" v-if="showScheduler">
                <template>
                    <v-layout align-center justify-center>
                        <v-flex xs12 sm8 md6>
                            <v-row no-gutters class="currentTime">
                                <v-col cols="12" sm="12">
                                    Campaigns need to be scheduled at least 30 minutes from the current time {{getTime}}
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="12" sm="6">
                                    <v-date-picker
                                        v-model="scheduleDate"
                                        color="#777BD2"
                                        full-width
                                        :min="calendarMinDate"
                                        show-adjacent-months
                                    ></v-date-picker>
                                </v-col>
                                <v-col cols="12" sm="6"> 
                                    <v-time-picker
                                        format="ampm"
                                        ampm-in-title
                                        color="#777BD2"
                                        v-model="scheduleTime"
                                        :min="calendarMinTime"
                                    ></v-time-picker>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="12" sm="6">
                                    <v-btn @click="cancelScheduler" class="elevation-0">Cancel</v-btn>
                                </v-col>
                                <v-col cols="12" sm="6">
                                        <v-btn @click="saveCampaign" class="elevation-0 primary" :disabled='saveCampaignInProgress'><v-progress-circular
                                        indeterminate
                                        color="#777BD2"
                                        v-if="saveCampaignInProgress"
                                    ></v-progress-circular><span v-if="!saveCampaignInProgress">Schedule</span></v-btn>
                                </v-col>
                            </v-row>
                        </v-flex>
                    </v-layout>
                </template>
            </v-container>
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
</template>
<script>
import sendEmailConfirmation from '@/components/sendEmailConfirmation.vue';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import { Storage } from 'aws-amplify';
export default {
    components: { sendEmailConfirmation, ConfirmationDialog },
    name: 'AddCampaignStepper',
    data(){
        return{
            error: '',
            userEmail: '',
            radioGroup: null,
            valid: false,
            validEmail: false,
            campaignNameRules: [
                v => !!v || 'Campaign name is required',
                v => (v || '' ).length >= 3 || 'Campaign Name should have minimum 3 characters'
            ],
            emailTemplateRule: [
                v => !!v || 'Email template is required'
            ],
            showScheduler: false,
            calendarMinDate: '',
            calendarMinTime: '',
            scheduleDate: '',
            scheduleTime: '',
            getTime: '',
            indexof: 1,
            headers: [
                {
                    text: 'Email',
                    align: 'start',
                    sortable: false,
                    value: 'email',
                },
                { text: 'Company Name', value: 'company_name',
                },
                { text: 'Phone Number', value: 'phone_number' },
                { text: 'Company Website', value: 'link_to_company', class: 'website'},
                { text: 'Category', value: 'category' },
                { text: 'Status', value: 'status', align: 'center', sortable: false},
                { text: 'Actions', value: 'actions', sortable: false, align: 'center'},
            ],
            headers2: [
                {
                    text: 'Email',
                    align: 'start',
                    sortable: false,
                    value: 'email',
                },
                { text: 'Company Name', value: 'company_name'},
                { text: 'Phone Number', value: 'phone_number' },
                { text: 'Company Website', value: 'link_to_company' },
                { text: 'Category', value: 'category' },
                { text: 'Status', value: 'status',align: 'center', sortable: false }
            ],
            dialog: false,
            dialogDelete: false,
            editedIndex: -1,
            editedItem: {
                email: '',
                company_name: '',
                phone_number: '',
                link_to_company: '',
                category: '',
            },
            defaultItem: {
                email: '',
                company_name: '',
                phone_number: '',
                link_to_company: '',
                category: '',
            },
            search: '',
            downloadURL: [],
            currentPageNumber: 1,
        }
    },
    methods:{
        //eslint-disable-next-line
        filterSearch (value, search, item) {
            return value != null &&
            search != null &&
            typeof value === 'string' &&
            value.toString().toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
        },
        defaultRadioValue(){
            if(this.emailTemplatesData[0]){
                this.stepperCampaignTemplate = this.emailTemplatesData[0].name;
                if(document.querySelector("#templateRadio .active")){
                    document.querySelector("#templateRadio .active").classList.remove("active");
                    setTimeout(function(){
                        document.querySelector("#templateRadio .v-item--active").parentNode.classList.add("active");
                    },100);
                }
                else{
                    setTimeout(function(){
                        document.querySelector("#templateRadio .v-item--active").parentNode.classList.add("active");
                    },100);
                }
            }
        },
        checkUploadedFile(){
            this.fileUploaded = true;
            this.currentFileUploadID = 'csv-file';
            this.error = '';
            //eslint-disable-next-line
            var fileName = document.getElementById('csv-file').value.replace(/^.*[\\\/]/, '');
            this.UploadedFileName = fileName;
        },
        checkUploadedFile2(){
            this.fileUploaded = true;
            this.currentFileUploadID = 'browse-csv-file';
            this.error = '';
            //eslint-disable-next-line
            var fileName = document.getElementById('browse-csv-file').value.replace(/^.*[\\\/]/, '');
            this.UploadedFileName = fileName;
        },
        checkREF(){
            this.$refs.campaignForm.validate()
        },
        parseCSV(){
            var _this = this;
            if(this.fileUploaded == true){
                var file = document.getElementById(this.currentFileUploadID).files[0];
                var allKeyPresent = false; // Flag
                var EmailsList = [];
                var EmailsData = {};
                this.$papa.parse(file, {
                    header : true,
                    skipEmptyLines : true,
                    /* step: function(results) {
                        console.log("Row data:", results.data);
                        console.log("Row errors:", results.errors);
                    }, */
                    /*complete: function(results, file) {
                        console.log("Parsing complete:", results, file);
                    },*/
                    complete: function(row){
                        if (!allKeyPresent) { //Only chek if flag is not set, i.e, for the first time
                            //parser.pause(); // pause the parser
                            
                            // Now check object keys, if it match
                            row.data.forEach((value) =>{
                                Object.keys(value).forEach(function(key) {
                                    var replaced = key.trim().toLowerCase().replace(/ /g,"_");
                                    if (key !== replaced) {
                                        value[replaced] = value[key];
                                        delete value[key];
                                    }
                                });
                            });
                            var first_row_data = row.data[0];
                            if (('email' in first_row_data)) {
                                if(row.errors.length == 0){
                                    _this.getSubscriptionStatusInProgress = true;
                                    //every required key is present
                                    allKeyPresent = true;

                                    _this.$store.state.csv_data = row.data;
                                    
                                    // eslint-disable-next-line
                                    row.data.forEach((value, index) => {
                                        EmailsList.push(value.email);
                                    });
                                    EmailsData = { "clients": EmailsList, "user": _this.$store.state.user.attributes.email};
                                    _this.$store.dispatch('checkUnsubscribers', EmailsData);
                                    //_this.addSubscriptionStatus();
                                    _this.$store.state.csv_data.map(v => { delete v[""]; return v });
                                    _this.$store.state.csv_data.map(v => { delete v["__parsed_extra"]; return v });
                                    _this.campaignUploadStep = 2;
                                    document.getElementById("campaignUploadStepper").scrollIntoView();
                                    /* document.getElementById("stepper_campaign_name").focus();
                                    setTimeout(() => {
                                        //_this.$refs.campaignForm.validate();
                                        _this.$refs.campaign_name.focus();
                                    }, 1); */
                                    //parser.resume();
                                }
                                else{
                                    _this.error = "Anomaly in row "+(row.errors[0].row+1)+" of Spreadsheet. Note: Comma (,) shall not be used without encapsulating string in inverted commas";
                                    document.getElementById('browse-csv-file').value = '';
                                    document.getElementById('csv-file').value = '';
                                    _this.UploadedFileName = '';
                                    _this.currentFileUploadID = null;
                                    _this.fileUploaded = false;
                                }
                            } else{
                                //some key is missing, abort parsing
                                console.log('Wrong File');
                                _this.error = 'Email column is mandatory';
                                document.getElementById('browse-csv-file').value = '';
                                document.getElementById('csv-file').value = '';
                                _this.UploadedFileName = '';
                                _this.currentFileUploadID = null;
                                _this.fileUploaded = false;
                                //parser.abort();
                            }

                        } else{ // we already match the header, all required key is present
                            _this.getSubscriptionStatusInProgress = true;
                            row.data.forEach((value) =>{
                                Object.keys(value).forEach(function(key) {
                                    var replaced = key.trim().toLowerCase().replace(/ /g,"_");
                                    if (key !== replaced) {
                                        value[replaced] = value[key];
                                        delete value[key];
                                    }
                                });
                            });
                            _this.$store.state.csv_data = row.data;
                            // eslint-disable-next-line
                            row.data.forEach((value, index) => {
                                EmailsList.push(value.email);
                            });
                            EmailsData = { "clients": EmailsList, "user": _this.$store.state.user.attributes.email};
                            _this.$store.dispatch('checkUnsubscribers', EmailsData);
                            //_this.addSubscriptionStatus();
                            _this.campaignUploadStep = 2;
                            document.getElementById("campaignUploadStepper").scrollIntoView();
                            /* document.getElementById("stepper_campaign_name").focus();
                            setTimeout(() => {
                                //_this.$refs.campaignForm.validate();
                                _this.$refs.campaign_name.focus();
                            }, 1); */
                        }

                    }
                });
            }
            else{
                _this.error = 'Kindly select a CSV file before going to the next step';
                this.fileUploaded = false;
                this.UploadedFileName = '';
            }
        },
        addSubscriptionStatus(){
            var _this = this;
            const filteredCSV_from_empty_rows = this.$store.state.csv_data.filter((el) => {
                return el.phone_number !== "" || el.category !== "" || el.link_to_company !== "" || el.company_name !== "" || el.email !== "";
            });
            this.$store.state.csv_data = filteredCSV_from_empty_rows;
            this.$store.state.csv_data.forEach(function(item/* , index, object */) {
                if(item.phone_number !== "" || item.category !== "" || item.link_to_company !== "" || item.company_name !== "" || item.email !== ""){
                    if(_this.Unsubscribers.indexOf(item.email) > -1){
                        item.status = 'un-subscribed';
                    }
                    else{
                        item.status = 'subscribed'
                    }
                }
            });
        },
        CheckSubscribtion(needle) {
            // Per spec, the way to identify NaN is that it is not equal to itself
            var findNaN = needle !== needle;
            var indexOf;

            if(!findNaN && typeof Array.prototype.indexOf === 'function') {
                indexOf = Array.prototype.indexOf;
            } else {
                indexOf = function(needle) {
                    var i = -1, index = -1;

                    for(i = 0; i < this.length; i++) {
                        var item = this[i];

                        if((findNaN && item !== item) || item === needle) {
                            index = i;
                            break;
                        }
                    }

                    return index;
                };
            }

            return indexOf.call(this, needle) > -1;
        },
        goBack(){
            this.campaignUploadStep = this.campaignUploadStep - 1;
        },
        showEmailTemplates(){
          this.$store.state.gettingTemplatesInProgress = true;
          this.$store.dispatch('getEmailTemplates', this.userEmail);
        },
        openNewAddEmailTemplateBox(param){
            if(param == 'add'){
                this.$store.state.templateReplyTo = this.userEmail;
                this.$store.state.primary_btn_txt = 'Add';
                this.$store.state.emailTemplateHd = 'Create Email Template';
            }
            else{
                this.$store.state.primary_btn_txt = 'Update';
                this.$store.state.emailTemplateHd = 'Edit Email Template';
            }
            this.$store.state.addEmailTemplateBox = true;
        },
        viewEmailTemplate(item){
          this.$store.state.TemplateData = item;
        },
        getOverflowClass(TempName){
            var x = '';
            setTimeout(function(){
                if(document.getElementById(TempName+'-emailTempBody')){
                    var divHeight = document.getElementById(TempName+'-emailTempBody').clientHeight;
                    var spanHeight = document.querySelector('#'+TempName+'-emailTempBody'+' span').clientHeight;
                    if(spanHeight>divHeight){
                        x = 'overflow';
                    }
                    else{
                        x = 'no-overflow'
                    }
                }
                document.querySelector('#'+TempName+'-emailTempBody'+' span').classList.add(x);
            }, 10);
        },
        toSelectEmailTemplateStep(){
            var _this = this;
            if (this.$refs.campaignForm.validate()) {
                if(!this.$store.state.csv_data.find(e => e['email'] == '')){
                    // eslint-disable-next-line
                    if(!!!this.$store.state.csv_data.find(e => !(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(e['email'])))){
                        if(document.querySelector("#templateRadio .v-item--active")){
                            document.querySelector("#templateRadio .v-item--active").parentNode.classList.add("active");
                        }
                        this.defaultRadioValue();
                        this.emailTemplatesData.forEach( function(item, index){
                            console.log(index);
                            console.log(item.attachment_name);
                            if(item.attachment_name != '' && item.attachment_name != null && item.attachment_name != undefined && item.attachment_name.indexOf(' ') < 0){
                                _this.DownloadFileBlob(item.attachment_name, index);
                            }
                            else{
                                _this.downloadURL[index] = '';
                            }
                        });
                        this.campaignUploadStep = 3;
                        document.getElementById("campaignUploadStepper").scrollIntoView();
                    }
                    else{
                        var x = 0;
                        this.$store.state.csv_data.forEach(item => {
                            if(item.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) == null){
                                item.flag_class = 'flagged';
                            }
                            else{
                                item.flag_class = 'un-flagged';
                            }
                        });
                        this.$store.state.csv_data.find(function(item, h){
                            if(item.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) == null){
                                x = h+1;
                                return true;
                            }
                        });
                        if(x%5 == 0){
                            console.log( x/5)
                            this.currentPageNumber = Math.floor(x/5);
                        }
                        else{
                            console.log((x/5)+1)
                            this.currentPageNumber = Math.floor(x/5)+1;
                        }
                        this.$store.state.responseMsg = 'Kindly write a valid email in row number '+x+' of the spreadsheet or delete that row';
                        this.$store.state.ResponseClass = 'error';
                    }
                }
                else{
                    var y = 0;
                    this.$store.state.csv_data.forEach(item => {
                        if(item.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) == null || item.email == ''){
                            item.flag_class = 'flagged';
                        }
                        else{
                            item.flag_class = 'un-flagged';
                        }
                    });
                    this.$store.state.csv_data.find(function(item, i){
                        if(item.email == ''){
                            y = i+1;
                            return true;
                        }
                    });
                    if(y%5 == 0){
                        console.log((y/5))
                        this.currentPageNumber = Math.floor(y/5);
                    }
                    else{
                        console.log((y/5)+1)
                        this.currentPageNumber = Math.floor(y/5)+1;
                    }
                    this.$store.state.responseMsg = 'Kindly fill the empty email value in row number '+y+' of the spreadsheet or delete that row';
                    this.$store.state.ResponseClass = 'error';
                }
            }
        },
        finalCreateCampaignStep(){
            if (this.stepperCampaignTemplate) {
                this.campaignUploadStep = 4;
                document.getElementById("campaignUploadStepper").scrollIntoView()
            }
            else{
                this.$store.state.responseMsg = 'Email Template is required.';
                this.$store.state.ResponseClass = 'error';
            }
        },
        DownloadFileBlob(attachment_name, index){
            var _this = this;
            Storage.get(attachment_name, { download: true }).then(data=> {
                _this.downloadURL[index] = URL.createObjectURL(data.Body);
            });
        },
        downloadBlob(blob, filename, index) {
          //const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = blob;
          a.download = filename || 'download';
          const clickHandler = () => {
            setTimeout(() => {
              URL.revokeObjectURL(blob);
              a.removeEventListener('click', clickHandler);
            }, 150);
          };
          a.addEventListener('click', clickHandler, false);
          a.click();
          this.DownloadFileBlob(this.emailTemplatesData[index].attachment_name, index);
          return a;
        },
        currentTime(date){
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var seconds = date.getSeconds();
            var ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            hours = hours < 10 ? '0'+hours : hours;
            minutes = minutes < 10 ? '0'+minutes : minutes;
            seconds = seconds < 10 ? '0'+seconds : seconds;
            var strTime = hours + ':' + minutes + ':' + seconds + ' ' +ampm;
            this.getTime = strTime;
        },
        sendEmail(){
            if(this.$store.state.stepperCampaignName != '' && this.$store.state.csv_data != null && this.$store.state.stepperCampaignTemplate != ''){
            //this.$store.state.saveCampaignInProgress = true;
                /* var name = this.$store.state.stepperCampaignName;
                var campaignTemplate = this.$store.state.stepperCampaignTemplate;
                var createCampaignData = {
                    "user" : this.$store.state.user.attributes.email,
                    "status": "PENDING",
                    "name": name,
                    "template_name": campaignTemplate,
                    "clients": this.$store.state.csv_data
                };
                this.$store.dispatch('sendAndSaveCampaignEmails', createCampaignData); */
                this.SendEmailConfirmation = true
            }
            else{
                this.$store.state.responseMsg = 'No CSV Uploaded';
                this.$store.state.ResponseClass = 'error';
                this.campaignUploadStep = 1;
                this.$store.state.stepperCampaignTemplate = '';
                document.getElementById('browse-csv-file').value = '';
                document.getElementById('csv-file').value = '';
                document.getElementById('csv-file-name').innerHTML = '';
                this.currentFileUploadID = null;
                this.$store.state.csv_data = null;
                this.fileUploaded = false;
                this.stepperCampaignName = '';
                this.UploadedFileName = '';
            }
        },
        saveCampaign(){
            if(this.$store.state.stepperCampaignName != '' && this.$store.state.csv_data != null && this.$store.state.stepperCampaignTemplate != ''){
                this.$store.state.saveCampaignInProgress = true;
                var name = this.$store.state.stepperCampaignName;
                var campaignTemplate = this.$store.state.stepperCampaignTemplate;
                var createCampaignData;
                if(this.showScheduler == true){
                    if(this.scheduleTime != '' && this.scheduleDate != ''){
                        var final_time = this.scheduleDate+' '+this.scheduleTime;
                        createCampaignData = {
                            "user" : this.$store.state.user.attributes.email,
                            "status": "SCHEDULED",
                            "name": name,
                            "template_name": campaignTemplate,
                            "clients": this.$store.state.csv_data,
                            "send_time": new Date(final_time).getTime()
                        };
                        this.$store.dispatch('saveCampaign', createCampaignData);
                    }
                    else{
                        this.$store.state.responseMsg = 'Please select date and time';
                        this.$store.state.ResponseClass = 'error';
                        this.$store.state.saveCampaignInProgress = false;
                    }
                }
                else{
                    createCampaignData = {
                        "user" : this.$store.state.user.attributes.email,
                        "status": "PENDING",
                        "name": name,
                        "template_name": campaignTemplate,
                        "clients": this.$store.state.csv_data
                    };
                    this.$store.dispatch('saveCampaign', createCampaignData);
                }
            }
            else{
                this.$store.state.responseMsg = 'No CSV Uploaded';
                this.$store.state.ResponseClass = 'error';
                this.campaignUploadStep = 1;
                this.$store.state.stepperCampaignTemplate = '';
                document.getElementById('browse-csv-file').value = '';
                document.getElementById('csv-file').value = '';
                document.getElementById('csv-file-name').innerHTML = '';
                this.currentFileUploadID = null;
                this.$store.state.csv_data = null;
                this.fileUploaded = false;
                this.stepperCampaignName = '';
                this.UploadedFileName = '';
            }
        },
        getCalendarMinDate(){
            var month = new Date().getMonth()+1;
            var date = new Date().getDate();
            var year= new Date().getFullYear();
            var daysInMonth =  new Date(year, month, 0).getDate();
            if(month < 10){
                month = '0'+month;
            }
            if(date < 10){
                date = '0'+date;
            }
            if(date > daysInMonth){
                date = '01'
            }
            this.calendarMinDate = year+'-'+month+'-'+date;
        },
        getCalendarMinTIme(time){
            var minTime, hour, min;
            //console.log(new Date(new Date().getFullYear()+'-'+new Date().getMonth()+'-'+new Date().getDate()).getTime())
            if(this.scheduleDate){
                var selectedDate = new Date(this.scheduleDate).getDate();
                var selectedMonth = new Date(this.scheduleDate).getMonth();
                var selectedYear = new Date(this.scheduleDate).getFullYear();
                var today = new Date();
                if(selectedDate == today.getDate() && selectedMonth == today.getMonth() && selectedYear == today.getFullYear()){
                    minTime = new Date().getTime() + (30 * 60 * 1000);
                    hour = new Date(minTime).getHours();
                    min = new Date(minTime).getMinutes();
                    if(min >= 60){
                        min = min - 60;
                        hour = hour + 1;
                        if(hour>24){
                            hour = 0;
                        }
                    }
                    if(min < 10){
                        min = '0'+min;
                    }
                    this.calendarMinTime = hour+':'+min;
                }
                else{
                    this.calendarMinTime = '00:00';
                }
            }
            else{
                minTime = time.getTime() + (30 * 60 * 1000);
                hour = new Date(minTime).getHours();
                min = new Date(minTime).getMinutes();
                if(min >= 60){
                    min = min - 60;
                    hour = hour + 1;
                    if(hour>24){
                        hour = 0;
                    }
                }
                if(min < 10){
                    min = '0'+min;
                }
                this.calendarMinTime = hour+':'+min;
            }
        },
        getCalendarMinTimeOnChange(){
            // console.log(this.scheduleDate);
            // console.log(new Date(this.scheduleDate).getTime());
            if(new Date(this.scheduleDate).getTime() == new Date(new Date().getFullYear()+'-'+new Date().getMonth()+'-'+new Date().getDate()).getTime()){
                var minTime = new Date().getTime() + (30 * 60 * 1000);
                var hour = new Date(minTime).getHours();
                var min = new Date(minTime).getMinutes();
                if(min >= 60){
                    min = min - 60;
                    hour = hour + 1;
                    if(hour>24){
                        hour = 0;
                    }
                }
                if(min < 10){
                    min = '0'+min;
                }
                this.calendarMinTime = hour+':'+min;
                //console.log(this.calendarMinTime);
            }
            else{
                this.calendarMinTime = '00:00';
                //console.log(this.calendarMinTime);
            }
        },
        getCalendarDefaultTime(time){
            var defaultMin = time.getMinutes()+30;
            var defaulthr = time.getHours();
            if(defaultMin >= 60){
                defaultMin = defaultMin - 60;
                defaulthr = defaulthr + 1;
                if(defaulthr>=24){
                    defaulthr = 0;
                }
            }
            this.scheduleTime = defaulthr+':'+defaultMin;
        },
        openScheduler(){
            this.showScheduler = true;
        },
        cancelScheduler(){
            this.showScheduler = false;
            this.scheduleDate= '';
            this.getCalendarDefaultTime(new Date());
        },
        cancelUpload(){
            document.getElementById('browse-csv-file').value = '';
            document.getElementById('csv-file').value = '';
            document.getElementById('csv-file-name').innerHTML = '';
            this.currentFileUploadID = null;
            this.fileUploaded = false;
            this.stepperCampaignName = '';
            this.UploadedFileName = '';
        },
        cancelCampaign(){
            document.getElementById('browse-csv-file').value = '';
            document.getElementById('csv-file').value = '';
            document.getElementById('csv-file-name').innerHTML = '';
            this.currentFileUploadID = null;
            this.$store.state.csv_data = null;
            this.fileUploaded = false;
            this.stepperCampaignName = '';
            this.UploadedFileName = '';
            this.campaignUploadStep = 1;
        },
        checkEmailTemplate(){
            if(document.querySelector("#templateRadio .active")){
                document.querySelector("#templateRadio .active").classList.remove("active");
                setTimeout(function(){
                    document.querySelector("#templateRadio .v-item--active").parentNode.classList.add("active");
                },100);
            }
            else{
                setTimeout(function(){
                    document.querySelector("#templateRadio .v-item--active").parentNode.classList.add("active");
                },100);
            }
        },
        editItem (item) {
            this.editedIndex = this.$store.state.csv_data.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem (item) {
            this.editedIndex = this.$store.state.csv_data.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm () {
            this.$store.state.csv_data.splice(this.editedIndex, 1)
            this.closeDelete()
        },

        close () {
            this.dialog = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },

        closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },
        openAddEmailTemplateBox(param, template){
          var _this = this;
          this.$store.state.templateID = template.id;
          this.$store.state.templateName = template.name;
          this.$store.state.templateFromName = template.from_name
          this.$store.state.templateFrom = template.sender.email;
          this.$store.state.templateSubject = template.subject;
          this.$store.state.templateBody = template.body;
          this.$store.state.templateReplyTo = template.reply_to;
          this.$store.state.emailTemplateAttachmentName = template.attachment_name;
          if(param == 'add'){
            this.$store.state.emailTemplateAttachment = [];
            this.$store.state.templateReplyTo = this.userEmail;
            this.$store.state.primary_btn_txt = 'Create';
            this.$store.state.emailTemplateHd = 'Create Email Template';
          }
          else{
            if(this.$store.state.templateReplyTo == '' || this.$store.state.templateReplyTo == undefined){
              this.$store.state.templateReplyTo = this.userEmail;
            }
            if(this.$store.state.emailTemplateAttachmentName){
              Storage.list(_this.$store.state.emailTemplateAttachmentName)
              .then(result => {
                _this.$store.state.emailTemplateAttachment = result[0];
                //eslint-disable-next-line
                _this.$store.state.emailTemplateAttachment.name = _this.$store.state.emailTemplateAttachmentName.replace(/\_@.*\./, '.');
              })
              .catch(err => console.log(err));
            }
            else{
              this.$store.state.emailTemplateAttachment = [];
            }
            this.$store.state.primary_btn_txt = 'Update';
            this.$store.state.emailTemplateHd = 'Edit Email Template';
          }
          this.$store.state.addEmailTemplateBox = true;
        },
        save () {
            if (this.editedIndex > -1) {
                if(this.editedItem.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) != null || this.editedItem.email != ''){
                    this.editedItem.flag_class = 'un-flagged';
                }
                Object.assign(this.$store.state.csv_data[this.editedIndex], this.editedItem)
            } else {
                this.$store.state.csv_data.push(this.editedItem)
            }
            var EmailsList = [], EmailsData;
            // eslint-disable-next-line
            this.$store.state.csv_data.forEach((value, index) => {
                EmailsList.push(value.email);
            });
            EmailsData = { "clients": EmailsList, "user": this.$store.state.user.attributes.email};
            this.$store.dispatch('checkUnsubscribers', EmailsData);
            this.close()
        },
        deleteEmailTemplate(item){
          this.$store.state.templateActionBtnDisabled = true;
          this.$store.state.dialogId = item.id;
        },
        cancel(){
          this.$store.state.dialogId = '';
          this.$store.state.templateActionBtnDisabled = false;
        },
        
    },
    computed:{
        /* csv_data(){
            return this.$store.state.csv_data;
        }, */
        selectedID(){
            return this.$store.state.selectedID
        },
        templateActionBtnDisabled(){
            return this.$store.state.templateActionBtnDisabled
        },
        dialogId:{
            get(){
                return this.$store.state.dialogId;
            },
            set(value){
                this.$store.state.dialogId = value
            }
        },
        getSubscriptionStatusInProgress:{
            get(){
                return this.$store.state.getSubscriptionStatusInProgress;
            },
            set(value){
                this.$store.state.getSubscriptionStatusInProgress = value
            }
        },
        formTitle () {
            return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
        },
        Unsubscribers(){
            return this.$store.state.unSubscribers;
        },
        emailTemplatesData(){
            var x = '';
            if((this.$store.state.EmailTemplates == undefined || this.$store.state.EmailTemplates == null) && this.userEmail != ''){
                this.showEmailTemplates();
            }
            else if(this.$store.state.EmailTemplates && this.userEmail != ''){
                x = this.$store.state.EmailTemplates.data;
            }
            return x;
        },
        gettingTemplatesInProgress(){
            return this.$store.state.gettingTemplatesInProgress;
        },
        responseMsg(){
            return this.$store.state.responseMsg;
        },
        ResponseClass(){
            return this.$store.state.ResponseClass;
        },
        stepperCampaignTemplate:{
            set(value){
                this.$store.state.stepperCampaignTemplate = value
            },
            get(){
                return this.$store.state.stepperCampaignTemplate;
            }
        },
        stepperCampaignName: {
            set(value){
                this.$store.state.stepperCampaignName = value;
            },
            get(){
                return this.$store.state.stepperCampaignName;
            }
        },
        campaignUploadStep:{
            set(value){
                this.$store.state.campaignUploadStep = value;
            },
            get(){
                return this.$store.state.campaignUploadStep;
            }
        },
        saveCampaignInProgress(){
            return this.$store.state.saveCampaignInProgress;
        },
        NewUploadClass(){
            var x= '';
            if(this.fileUploaded == true){
                x = 'hidden';
            }
            else{
                x = 'visible';
            }
            return x;
        },
        NewUploadCancelClass(){
            var x= '';
            if(this.fileUploaded == true){
                x = 'visible';
            }
            else{
                x = 'hidden';
            }
            return x;
        },
        canLoadThirdStep() {
            return (this.$refs.campaignForm);
        },
        UploadedFileName:{
            get(){
                return this.$store.state.UploadedFileName
            },
            set(value){
                this.$store.state.UploadedFileName = value
            }
        },
        fileUploaded:{
            get(){
                return this.$store.state.fileUploaded
            },
            set(value){
                this.$store.state.fileUploaded = value
            }
        },
        currentFileUploadID:{
            get(){
                return this.$store.state.currentFileUploadID
            },
            set(value){
                this.$store.state.currentFileUploadID = value
            }
        },
        SendEmailConfirmation:{
            get(){
                return this.$store.state.SendEmailConfirmation;
            },
            set(value){
                this.$store.state.SendEmailConfirmation = value;
            }
        }
    },

    mounted(){
        var _this = this;
        this.userEmail = this.$store.state.user.attributes.email;
        this.showEmailTemplates();
        this.getCalendarDefaultTime(new Date());
        setInterval(function(){
            _this.currentTime(new Date());
            _this.getCalendarMinDate();
            _this.getCalendarMinTIme(new Date());
        }, 1000);
        // eslint-disable-next-line
        if(!!this.$store.state.csv_data){
            this.campaignUploadStep = 2;
        }
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    inject: {
      theme: {
        default: { isDark: false },
      },
    },
}
</script>
<style scoped>
.more-options button:first-child, .more-options a:first-child{
  margin-right: 5px;
}
.more-options button, .more-options a{
  opacity: 0.6;
}
.more-options button:hover, .more-options a:hover{
  opacity: 1;
}
div#templateRadio .v-card .more-options{
    transition: 0.2s all;
    min-height: 50px;
    background: linear-gradient(to bottom, rgba(249, 249, 250, 0), rgba(249, 249, 250, 1));
}
div#templateRadio .v-card .v-card__text{
    overflow-y: auto;
}
.v-stepper>>>.v-stepper__header .v-stepper__step:first-child{
    padding-left: 0px;
}
.v-stepper>>>.v-stepper__header .v-stepper__step:last-child{
    padding-right: 0px;
}
#campaignUploadStepper{
    position: relative;
    top: -24px;
}
.v-stepper__content{
    padding: 25px 0px 25px;
}
.v-stepper >>> .v-stepper__content.campaignUploaded{
    padding-top: 25px;
}
.v-stepper >>> .v-stepper__content #templateRadio .v-card{
    background-color: #F9F9FA;
}
.v-stepper >>> .v-stepper__content .v-card{
    margin-bottom: 25px !important;
}
.v-stepper >>> .v-stepper__content .scheduler .v-card{
    margin-bottom: 0px !important;
}
.drag-area{
  border: 2px dashed rgba(0,0,0,0.5);
  margin: auto;
  height: 400px;
  width: 600px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.drag-area.active{
  border: 2px solid rgba(0,0,0,0.5);
}
.drag-area .NewUpload{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}
.drag-area .NewUpload .error{
    padding: 5px 20px;
    border-radius: 5px;
    color: #fff;
}
.drag-area .NewUpload .icon{
  color: rgba(0,0,0,0.3);
}
.drag-area .NewUpload .icon span{
    font-size: 110px;
}
.drag-area .NewUpload header{
  font-size: 30px;
  font-weight: 500;
  color: rgba(0,0,0,0.5);
}
.drag-area .NewUpload span{
  font-size: 25px;
  font-weight: 500;
  color: rgba(0,0,0,0.5);
  margin: 10px 0 15px 0;
}

.drag-area .NewUpload img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.drag-area .NewUpload input{
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    opacity: 0;
}
.upload-csv p{
    text-align: center;
    margin-top: 20px;
}
.upload-csv p>hr{
    display: inline-block;
    width: 180px;
    position: relative;
    top: -5px;
    border-color: rgba(0, 0, 0, 0.3);
}
.upload-csv p>hr:first-child{
    right: 20px;
}
.upload-csv p>hr:last-child{
    left: 20px;
}
#csv-file-container{
    width: 150px;
    position: relative;
    margin: 0 auto;
}
#browse-csv-file{
    position: relative;
    height: 35px;
    width: 100%;
    opacity: 0;
    font-size: 0px;
    top: 0px;
    cursor: pointer;
}
#csv-file-container:before{
    content: "Browse File";
    position: absolute;
    left: 0;
    padding: 5px 0;
    color: #fff;
    width: 100%;
    text-align: center;
    border-radius: 3px;
    background-color: #777cd2c7;
    cursor: pointer;
    transition: all 0.5s;
}
#csv-file-container:hover:before{
    background-color: #777BD2;
}
.getStarted .v-card{
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 480px !important;
}
.getStarted .selectEmailTemplate .v-card__title{
    text-align: center;
    display: block;
    background-color: #D9DAFF;
    padding: 10px;
}
.getStarted .selectEmailTemplate .v-card__text{
    padding: 15px;
    background-color: #F9F9FA;
    height: 250px;
}
.getStarted .selectEmailTemplate .v-card__text>div{
    display: block;
    /*height: 85%;*/
}
.getStarted .selectEmailTemplate .v-card__text>div>span{
    display: block;
}
.v-radio.theme--light>>>.v-input--selection-controls__input{
    margin: 15px auto;
    display: block;
}
.selectEmailTemplate .v-input.v-input--radio-group--row{
    width: 100%;
}
.selectEmailTemplate .addEmailTemplate .v-card__text{
    min-height: 250px;
    text-align: center;
}
.selectEmailTemplate .addEmailTemplate .v-card__text .v-icon{
    font-size: 130px;
}
.v-card__actions.text-right .right-btn{
    margin-left: auto !important;
}
.v-card .v-input>>>.v-messages__message{
    color: rgba(0,0,0,0.5) !important;
    padding: 0 0px;
}
.scheduler{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.3);
    max-width: 100%;
    z-index: 999;
}
.scheduler .v-picker{
    border-radius: 0;
}
.scheduler button{
    min-width: 100% !important;
}
.scheduler .v-picker--time{
    width: 100%;
}
.v-picker--time>>>.v-picker__title{
    padding: 9px !important;
}
.currentTime{
    background-color: #fff;
    padding: 15px;
}
.v-stepper.getStarted>>>span.v-stepper__step__step.primary{
    background-color: #777BD2 !important;
    border-color: #777BD2 !important;
}

.v-stepper.getStarted>>>.v-stepper__wrapper .v-card__actions .v-btn{
    min-width: 150px !important;
}
.success-tick{
    margin: 0 auto 15px;
}
.NewUpload {
    width: 100%;
    height: 100%;
    z-index: 9;
}
.Uploaded{
    position: absolute;
}
.NewUpload.hidden .icon, .NewUpload.hidden header, .NewUpload.hidden p, .NewUpload.hidden #csv-file-container{
    display: none;
}
div#templateRadio .col-12.active .v-card{
    border: 2px solid #777BD2;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 25%) !important;
    /* transition: 0.3s all; */
}
.v-data-table >>> .v-data-table__wrapper > table > tbody > tr > td, .v-data-table >>> .v-data-table__wrapper > table > thead > tr > th{
    padding: 5px !important;
}
.v-data-table >>> header.v-sheet.v-toolbar{
    height: auto !important;
}
.confirmation-wrapper{
    padding: 40px 50px;
    background-color: #FAFAFA;
    text-align: center;
}
.confirmation-wrapper .card-footer button{
    padding: 10px 30px;
}
.confirmation-wrapper .card-footer button:last-child{
    margin-left:20px;
}
.confirmation-wrapper .card-header p{
    font-size: 18px;
    color: #575757;
    margin-bottom: 25px;
}
.confirmation-wrapper .card-footer .v-btn{
    min-width: 130px;
}
.v-dialog__content >>> .v-dialog{
    box-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important
}
.v-dialog__content >>> .v-dialog > .v-card > .v-card__title{
    padding: 16px 35px 10px;
}
.v-dialog__content >>> .v-card__actions{
    padding: 0px 35px 20px !important;
}
.v-dialog__content >>> .v-card__actions button{
    min-width: 125px;
}
.v-data-table >>> .v-data-footer .v-data-footer__select{
    display: none;
}
.v-data-table >>> .v-text-field{
    margin-top: 0px !important;
}
/* .dataTable{
    max-width: 400px;
    margin-left: auto;
} */
.v-data-table >>> .v-card .v-input .v-messages .error--text .v-messages__message{
    color: inherit !important;
}
/* .v-data-table >>> .v-data-table__wrapper tr td:nth-child(6) {
    display: grid !important;
    align-items: center;
} */
.emailTempBody>span{
    white-space: pre-wrap;
}
.upload-csv-actions .v-btn.hidden{
    visibility: hidden;
}
span.un-subscribed{
    color: red
}
span.material-icons.subscribed, span.material-icons.un-subscribed {
    opacity: 0.7;
    margin: auto;
    display: flex;
    width: min-content;
}
.v-picker>>>.v-picker__body{
    padding: 15px 0px;
}
tr.flagged {
    background-color: rgba(255, 0, 0, 0.2);
}
</style>