<template>
  <v-container v-if="show">
    <add-campaign-stepper></add-campaign-stepper>
    <hr />
    <template v-if="recentBatch">
      <template>
        <v-row no-gutters class="subHeaderRow">
          <v-col cols="12" sm="7" id="header-left">
            <h1>Recent Campaigns</h1>
          </v-col>
          <v-col cols="12" sm="5" id="header-right">
            <v-row no-gutters>
              <v-col cols="12" sm="1" class="export-campaign-btn">
                <!-- <v-btn rounded class="elevation-0">Export</v-btn> -->
              </v-col>
              <v-col cols="12" sm="5" class="search-campaign-field"> </v-col>
              <v-col cols="12" sm="1" class="export-campaign-btn">
                <!-- <v-btn rounded class="elevation-0">Export</v-btn> -->
              </v-col>
              <v-col cols="12" sm="5" class="changeStatView">
                <v-btn
                  @click="changeStatView"
                  class="primary elevation-0"
                  style="height:40px"
                  >Stats</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
      <v-row no-gutters>
        <v-simple-table id="recent-campaigns">
          <template v-slot:default>
            <thead style="background-color: #D9DAFF;">
              <tr>
                <th class="text-left">
                  Name
                </th>
                <th class="text-left">
                  Email Template
                </th>
                <th class="text-center">
                  Status
                </th>
                <th class="text-center">
                  Added On
                </th>
                <th class="text-center">
                  Sent On
                </th>
                <th class="text-center">
                  No. of Items
                </th>
                <th class="text-center">
                  Open Rate
                </th>
                <th class="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, index) in campaignsData">
                <tr
                  v-if="index < 5 && !gettingCampaignsInProgress"
                  :key="item.id"
                  :class="rowColor(item.status)"
                >
                  <td
                    @click="goToCampaign(item.id, item.status)"
                    class="campaignName"
                  >
                    <div>{{ item.name }}</div>
                  </td>
                  <td style="cursor: auto">
                    <v-icon
                      @click="viewEmailTemplate(item.template_name)"
                      class="viewEye"
                      v-if="item.template_name != 'Rule Based'"
                      >remove_red_eye</v-icon
                    >
                    {{ item.template_name }}
                  </td>
                  <td
                    class="text-center status"
                    @click="goToCampaign(item.id, item.status)"
                  >
                    <span>{{ item.status }}</span>
                  </td>
                  <td
                    class="text-center"
                    @click="goToCampaign(item.id, item.status)"
                  >
                    {{
                      getMonth(item.createdAt) +
                        "/" +
                        new Date(item.createdAt).getDate() +
                        "/" +
                        new Date(item.createdAt).getFullYear()
                    }}
                  </td>
                  <td
                    class="text-center"
                    @click="goToCampaign(item.id, item.status)"
                  >
                    <span v-if="item.sent_time">{{
                      getMonth(item.sent_time) +
                        "/" +
                        new Date(item.sent_time).getDate() +
                        "/" +
                        new Date(item.sent_time).getFullYear()
                    }}</span
                    ><span v-if="!item.sent_time">-</span>
                  </td>
                  <td
                    class="text-center"
                    @click="goToCampaign(item.id, item.status)"
                  >
                    {{ item.clients.length }}
                  </td>
                  <td
                    v-if="item.status == 'SENT'"
                    class="text-center"
                    @click="goToCampaign(item.id, item.status)"
                  >
                    {{ openRate(item.clients) }}
                  </td>
                  <td
                    v-if="item.status != 'SENT'"
                    class="text-center"
                    @click="goToCampaign(item.id, item.status)"
                  >
                    -
                  </td>
                  <!-- <td><v-btn class="more-options" :to="{name: 'Campaign Detail', params:{name:item.user.replace(/\W+/g, '-').toLowerCase(), id: item.id} }">&#x2026;</v-btn></td> -->
                  <td class="more-options text-center">
                    <router-link
                      :to="{
                        name: 'Campaign Detail',
                        params: { id: item.id, edit: true }
                      }"
                      v-if="item.status != 'SENT' && item.status != 'SENDING'"
                      :class="'disabled-' + campaignActionBtnDisabled"
                      ><v-icon>edit</v-icon></router-link
                    >
                    <router-link
                      :to="{
                        name: 'Campaign Detail',
                        params: { id: item.id, edit: false }
                      }"
                      v-if="item.status == 'SENT' || item.status == 'SENDING'"
                      :class="'disabled-' + campaignActionBtnDisabled"
                      ><v-icon>remove_red_eye</v-icon></router-link
                    >
                    <a
                      @click="deleteCampaign(item)"
                      :class="'disabled-' + campaignActionBtnDisabled"
                      v-if="item.status != 'SENDING'"
                    >
                      <v-progress-circular
                        indeterminate
                        color="#777BD2"
                        v-if="selectedID == item.id"
                      ></v-progress-circular>
                      <v-icon v-if="selectedID != item.id" class="deleteBtn"
                        >delete</v-icon
                      >
                    </a>
                    <transition name="fade-transition"
                      ><confirmation-dialog
                        v-if="dialogId == item.id"
                        actionName="deleteCampaign"
                        primaryTxt="Delete"
                        :item="item"
                        @close="cancel()"
                      ></confirmation-dialog
                    ></transition>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
        <template v-if="!campaignsData.length && !gettingCampaignsInProgress">
          <p class="empty-campaign-msg">
            No campaign has been added. Get started by adding a new campaign.
          </p>
          <v-btn
            dark
            class="create-campaign primary elevation-0"
            @click="openCsvUploadBox"
            >Create Campaign</v-btn
          >
        </template>
        <template>
          <v-progress-circular
            indeterminate
            color="#777BD2"
            v-if="gettingCampaignsInProgress"
            class="progressLoader"
          ></v-progress-circular>
        </template>
      </v-row>
    </template>

    <template v-if="dashboard">
      <template>
        <v-row no-gutters class="subHeaderRow">
          <v-col cols="12" sm="7" id="header-left">
            <h1>Dashboard</h1>
          </v-col>
          <v-col cols="12" sm="5" id="header-right">
            <v-row no-gutters>
              <v-col cols="12" sm="1" class="export-campaign-btn">
                <!-- <v-btn rounded class="elevation-0">Export</v-btn> -->
              </v-col>
              <v-col cols="12" sm="5" class="search-campaign-field">
                <v-select
                  :items="dashboardFilterList"
                  label="Stats Range"
                  v-model="dashboardFilter"
                  outlined
                  dense
                  color="#777BD2"
                  @change="changeDashboard"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="1" class="export-campaign-btn">
                <!-- <v-btn rounded class="elevation-0">Export</v-btn> -->
              </v-col>
              <v-col cols="12" sm="5" class="changeStatView">
                <v-btn
                  @click="changeStatView"
                  class="primary elevation-0"
                  style="height:40px"
                  >Recent Batches</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
      <template>
        <v-row v-if="!dashboardLoading">
          <v-col cols="12" sm="1"></v-col>
          <v-col cols="12" sm="5">
            <dashboard-chart
              :key="componentKey"
              id="dashboardChart"
              label="Emails Sent"
              label2="Emails Opened"
              bgcolor="rgba(119, 123, 210, 0.5)"
              bgcolor2="rgba(0, 0, 210, 0.5)"
              :chartdata="sentEmailVar"
              :chartdata2="openedEmailVar"
              :labels="sentEmailLabelVar"
            ></dashboard-chart>
          </v-col>
          <!-- bgcolor="rgba(119, 123, 210, 0.5)" -->
          <v-col cols="12" sm="5">
            <line-dashboard-chart
              :key="componentKey"
              id="dashboardChart"
              label="Campaigns Sent"
              bgcolor="rgba(119, 123, 210, 0.5)"
              :chartdata="sentCampaignsVar"
              :labels="sentCampaignsLabelVar"
            ></line-dashboard-chart>
          </v-col>
          <v-col cols="12" sm="1"></v-col>
        </v-row>
        <v-row>
          <template>
            <v-progress-circular
              indeterminate
              color="primary"
              v-if="dashboardLoading"
              class="progressLoader"
            ></v-progress-circular>
          </template>
        </v-row>
      </template>
    </template>
  </v-container>
</template>

<script>
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import AddCampaignStepper from "@/components/AddCampaignStepper.vue";
import DashboardChart from "@/components/dashboardChart.vue";
import LineDashboardChart from "@/components/LineDashboardChart.vue";
export default {
  name: "Home",
  components: {
    ConfirmationDialog,
    AddCampaignStepper,
    DashboardChart,
    LineDashboardChart
  },
  data() {
    return {
      userEmail: "",
      show: false,
      recentBatch: true,
      dashboard: false,
      componentKey: 0,
      dashboardFilterList: ["This Week", "This Month", "This Year"],
      dashboardFilter: "",
      sentEmailLabelVar: [],
      sentEmailVar: [],
      openedEmailVar: [],
      sentCampaignsVar: [],
      sentCampaignsLabelVar: []
    };
  },
  mounted() {
    if (this.$store.state.user == null || this.$store.state.user == undefined) {
      this.$router.push({ path: "/sign-in" });
    } else {
      this.userEmail = this.$store.state.user.attributes.email;
      //this.$store.state.dashboardLoading = true;
      //this.$store.dispatch('userDashboardStats', this.userEmail);
      this.showCampaigns();
      this.$store.dispatch("userDashboardStats", this.userEmail);
      this.show = true;
    }
  },
  methods: {
    openRate(clients){

     if (clients) {
        let totalSentEmails = clients.filter(e => e.status == 'subscribed').length;
        let openedEmails = clients.filter(
          e => e.delivery_status == 1
        ).length;
        let result = (openedEmails / totalSentEmails) * 100;
        return parseInt(result) + "%";
      }

      return 0;
    },
    forceRerender() {
      this.componentKey += 1;
    },
    changeStatView() {
      var _this = this;
      this.recentBatch = !this.recentBatch;
      this.dashboard = !this.dashboard;
      if (this.dashboard == true) {
        this.$store.state.dashboardLoading = true;
        this.$store.dispatch("userDashboardStats", this.userEmail);
        _this.sentEmailLabelVar = [];
        _this.sentEmailVar = [];
        _this.openedEmailVar = [];
        _this.sentCampaignsVar = [];
        _this.sentCampaignsLabelVar = [];
        this.thisWeekData.forEach(element => {
          _this.sentEmailLabelVar.push(element.day);
          _this.sentEmailVar.push(element.emails_sent);
          _this.openedEmailVar.push(element.emails_opened);
          _this.sentCampaignsVar.push(element.campaigns_sent);
          _this.sentCampaignsLabelVar.push(element.day);
        });
        this.$nextTick(() => {
          window.addEventListener("resize", this.forceRerender);
        });
      } else if (this.recentBatch == true) {
        this.showCampaigns();
      }
    },
    showCampaigns() {
      this.$store.state.gettingCampaignsInProgress = true;
      this.$store.dispatch("getCampaigns", {
        user: this.userEmail,
        status: ""
      });
    },
    getMonth(time) {
      var month = new Date(time).getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      return month;
    },
    showbox(item) {
      this.selectedId = item;
    },
    deleteCampaign(item) {
      this.$store.state.campaignActionBtnDisabled = true;
      this.$store.state.dialogId = item.id;
    },
    goToCampaign(campaignId, campaignStatus) {
      if (campaignStatus == "SENT") {
        this.$router.push({
          name: "Campaign Detail",
          params: {
            id: campaignId,
            edit: false,
            pending: false,
            schedule: false,
            sending: false
          }
        });
      } else if (campaignStatus == "PENDING") {
        this.$router.push({
          name: "Campaign Detail",
          params: {
            id: campaignId,
            edit: false,
            pending: true,
            schedule: false,
            sending: false
          }
        });
      } else if (campaignStatus == "SCHEDULED") {
        this.$router.push({
          name: "Campaign Detail",
          params: {
            id: campaignId,
            edit: false,
            pending: false,
            schedule: true,
            sending: false
          }
        });
      } else if (campaignStatus == "SENDING") {
        this.$router.push({
          name: "Campaign Detail",
          params: {
            id: campaignId,
            edit: false,
            pending: false,
            schedule: false,
            sending: true
          }
        });
      } else if (campaignStatus == "UNSCHEDULED") {
        this.$router.push({
          name: "Campaign Detail",
          params: {
            id: campaignId,
            edit: false,
            pending: true,
            schedule: false,
            sending: false
          }
        });
      } else {
        this.$router.push({
          name: "Campaign Detail",
          params: {
            id: campaignId,
            edit: true,
            pending: false,
            schedule: false,
            sending: false
          }
        });
      }
    },
    openCsvUploadBox() {
      if (document.getElementById("campaignUploadStepper")) {
        document.getElementById("campaignUploadStepper").scrollIntoView();
      } else {
        document.body.scrollTop = 190; // For Safari
        document.documentElement.scrollTop = 190; // For Chrome, Firefox, IE and Opera
      }
    },
    rowColor(status) {
      var rowClass = "";
      if (status == "SCHEDULED") {
        rowClass = "scheduled";
      } else if (status == "PENDING") {
        rowClass = "pending";
      } else if (status == "SENT") {
        rowClass = "sent";
      } else if (status == "SENDING") {
        rowClass = "sending";
      } else if (status == "UNSCHEDULED") {
        rowClass = "unscheduled";
      } else {
        rowClass = "";
      }
      return rowClass;
    },
    viewEmailTemplate(emailTemplate) {
      if (this.emailTemplatesData.find(e => e["name"] == emailTemplate)) {
        this.$store.state.TemplateData = this.emailTemplatesData.find(
          e => e["name"] == emailTemplate
        );
      } else {
        this.$store.state.responseMsg = "Email Template Not Found";
        this.$store.state.ResponseClass = "error";
      }
    },
    getTemplates() {
      this.$store.dispatch("getEmailTemplates", this.userEmail);
    },
    cancel() {
      this.$store.state.dialogId = "";
      this.$store.state.campaignActionBtnDisabled = false;
    },
    changeDashboard() {
      var _this = this;
      _this.sentEmailLabelVar = [];
      _this.sentEmailVar = [];
      _this.openedEmailVar = [];
      _this.sentCampaignsVar = [];
      _this.sentCampaignsLabelVar = [];
      if (this.dashboardFilter == "This Week") {
        this.thisWeekData.forEach(element => {
          _this.sentEmailLabelVar.push(element.day);
          _this.sentEmailVar.push(element.emails_sent);
          _this.openedEmailVar.push(element.emails_opened);
          _this.sentCampaignsVar.push(element.campaigns_sent);
          _this.sentCampaignsLabelVar.push(element.day);
        });
        this.forceRerender();
      } else if (this.dashboardFilter == "This Month") {
        this.thisMonthData.forEach(element => {
          _this.sentEmailLabelVar.push(element.date);
          _this.sentEmailVar.push(element.emails_sent);
          _this.openedEmailVar.push(element.emails_opened);
          _this.sentCampaignsVar.push(element.campaigns_sent);
          _this.sentCampaignsLabelVar.push(element.date);
        });
        this.forceRerender();
      } else {
        this.thisYearData.forEach(element => {
          _this.sentEmailLabelVar.push(element.date);
          _this.sentEmailVar.push(element.emails_sent);
          _this.openedEmailVar.push(element.emails_opened);
          _this.sentCampaignsVar.push(element.campaigns_sent);
          _this.sentCampaignsLabelVar.push(element.date);
        });
        this.forceRerender();
      }
      console.log(_this.sentEmailLabelVar);
      console.log(_this.sentEmailVar);
      console.log(_this.openedEmailVar);
      console.log(_this.sentCampaignsVar);
      console.log(_this.sentCampaignsLabelVar);
    }
  },
  computed: {
    campaignsData() {
      var x = "";
      if (
        this.$store.state.campaigns == undefined ||
        this.$store.state.campaigns == null
      ) {
        this.showCampaigns();
      }
      if (this.$store.state.campaigns) {
        x = this.$store.state.campaigns.data;
      }
      return x;
    },
    selectedID() {
      return this.$store.state.selectedID;
    },
    campaignActionBtnDisabled() {
      return this.$store.state.campaignActionBtnDisabled;
    },
    gettingCampaignsInProgress() {
      return this.$store.state.gettingCampaignsInProgress;
    },
    dialogId: {
      get() {
        return this.$store.state.dialogId;
      },
      set(value) {
        this.$store.state.dialogId = value;
      }
    },
    // sentCampaigns() {
    //   return this.$store.state.sentCampaigns;
    // },
    // sentCampaignsDate() {
    //   return this.$store.state.sentCampaignsDate;
    // },
    // sentEmails() {
    //   return this.$store.state.sentEmails;
    // },
    // openedEmails() {
    //   return this.$store.state.openedEmails;
    // },
    // sentEmailsDate() {
    //   return this.$store.state.sentEmailsDate;
    // },

    // yearSentCampaigns() {
    //   return this.$store.state.yearSentCampaigns;
    // },
    // yearSentLabel() {
    //   return this.$store.state.yearSentLabel;
    // },
    // yearSentEmails() {
    //   return this.$store.state.yearSentEmails;
    // },
    // yearOpenedEmails() {
    //   return this.$store.state.yearOpenedEmails;
    // },

    // monthSentCampaigns() {
    //   return this.$store.state.monthSentCampaigns;
    // },
    // monthSentLabel() {
    //   return this.$store.state.monthSentLabel;
    // },
    // monthSentEmails() {
    //   return this.$store.state.monthSentEmails;
    // },
    // monthOpenedEmails() {
    //   return this.$store.state.monthOpenedEmails;
    // },
    thisWeekData() {
      return this.$store.state.thisWeekData;
    },
    thisMonthData() {
      return this.$store.state.thisMonthData;
    },
    thisYearData() {
      return this.$store.state.thisYearData;
    },
    dashboardLoading() {
      return this.$store.state.dashboardLoading;
    },
    emailTemplatesData() {
      var x = "";
      if (
        (this.$store.state.EmailTemplates == undefined ||
          this.$store.state.EmailTemplates == null) &&
        this.userEmail != ""
      ) {
        this.getTemplates();
      } else if (this.$store.state.EmailTemplates && this.userEmail != "") {
        x = this.$store.state.EmailTemplates.data;
      }
      return x;
    }
  },
  watch: {
    /* campaignsData (newCount) {
      // Our fancy notification (2).
      console.log(`We have ${newCount} fruits now, yay!`)
    } */
  },
  created() {}
};
</script>
<style scoped>
#recent-campaigns {
  width: 100%;
}
#recent-campaigns .more-options {
  font-size: 25px !important;
  /* background: none;
  box-shadow: none;
  padding: 0;
  height: fit-content;
  min-width: max-content; */
}
#recent-campaigns thead {
  background-color: #cdeaff;
}
#recent-campaigns thead th {
  font-size: 18px;
  font-weight: 400;
}
#recent-campaigns tbody td {
  font-size: 16px;
  font-weight: 400;
}
.progressLoader.v-progress-circular {
  height: 100px !important;
  width: 100px !important;
  margin: 15px auto;
}
tr td {
  cursor: pointer;
}
tr td:last-child {
  cursor: auto;
}
.more-options button {
  margin-right: 10px;
}
.more-options button,
.more-options a,
.viewEye {
  opacity: 0.6;
}
.more-options button:hover,
.more-options a:hover,
.viewEye:hover {
  opacity: 1;
}
.empty-campaign-msg {
  width: 100%;
  text-align: center;
  font-size: 20px;
  padding: 25px 0px 15px;
}
.create-campaign {
  margin: auto;
  padding: 25px 30px;
}
#header-left h1 {
  line-height: 1;
  display: flex;
  align-items: center;
  padding: 3px 0;
}
/* #header-right .add-campaign-btn button{
    font-weight: 500;
    height: 35px;
    border-radius: 35px;
    padding: 0 17px;
    box-shadow: none;
    background-color: #FF5D6D;
    color: #fff;
}*/
#header-right .col-12 {
  text-align: right;
}
/*#header-right .export-campaign-btn button{
    padding: 11px 35px;
    height: auto;
}
 #header-right, #header-left{
    padding-top: 20px;
} */
.more-options button:first-child,
.more-options a:first-child {
  margin-right: 5px;
}
.subHeaderRow {
  padding: 25px 0px;
}
.campaignName > div {
  max-width: 350px;
  overflow: hidden;
  overflow-wrap: break-word;
}
tr.scheduled {
  background-color: #f5e8ff;
  /* border-left: 5px solid #BE89F2; */
}
tr.pending {
  background-color: #ffece7;
  /* border-left: 5px solid #FB8474; */
}
tr.sent {
  background-color: #eaffe7;
  /* border-left: 5px solid #8CC383; */
}
tr.sending {
  background-color: #f7c49f;
  /* border-left: 5px solid #8CC383; */
}
tr.unscheduled {
  background-color: #d5d5d5;
}
tr.unscheduled .status span {
  background-color: #878787;
}
tr.scheduled .status span {
  background-color: #be89f2;
}
tr.pending .status span {
  background-color: #fb8474;
}
tr.sent .status span {
  background-color: #8cc383;
}
tr.sending .status span {
  background-color: #f08b45;
}
a.disabled-false {
  pointer-events: auto;
}
a.disabled-true {
  pointer-events: none;
}
thead {
  background-color: #d9daff;
}
thead > tr > th {
  color: #575757 !important;
}
.status span {
  min-width: 95px;
  display: block;
  border-radius: 20px;
  padding: 5px 15px;
  color: #fff;
}
.changeStatView .v-btn {
  min-width: 200px;
}
td.campaignName{
  max-width: 200px;
}
#recent-campaigns.v-data-table >>> .v-data-table__wrapper table{
  min-width: 1050px;
}

@media screen and (max-width: 850px) {
  #recent-campaigns .more-options {
    padding: 2px;
  }
}
</style>
