<template>
  <div>
    <v-navigation-drawer
      app
      v-model="drawer"
      class="blue hidden-md-and-up"
      disable-resize-watcher
    >
      <v-list>
        <template v-for="(item, index) in items">
          <v-list-item :key="index">
            <v-list-item-content>
              <v-btn text :to="`{{item.url}}`">{{ item.title }}</v-btn>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="`divider-${index}`"></v-divider>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar flat dense>
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-spacer class="hidden-md-and-up"></v-spacer>
      <div class="hidden-sm-and-down" style="width: 100%">
        <v-btn text to="/">Home</v-btn>
        <v-btn text to="/automation" v-if="currentUser.attributes['custom:role'] == 'Admin'">Automation</v-btn>
        <v-btn text to="/email-templates">Email Templates</v-btn>
        <v-menu open-on-hover bottom offset-y v-if="currentUser">
          <template v-slot:activator="{ on, attrs }">
            <a
              v-bind="attrs"
              v-on="on"
              class="v-btn v-btn--router v-btn--text theme--light v-size--default"
              :class="campaignClass"
            >
              Campaign History
              <v-icon right dark>
                keyboard_arrow_down
              </v-icon>
            </a>
          </template>
          <v-list class="sub-menu">
            <v-list-item>
              <v-btn text to="/all-campaigns">Active</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn text to="/archived-campaigns">Archived</v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu open-on-hover bottom offset-y v-if="currentUser">
          <template
            v-slot:activator="{ on, attrs }"
            v-if="currentUser.attributes['custom:role'] == 'Admin'"
          >
            <a
              v-bind="attrs"
              v-on="on"
              class="v-btn v-btn--router v-btn--text theme--light v-size--default"
              :class="managementClass"
            >
              Management
              <v-icon right dark>
                keyboard_arrow_down
              </v-icon>
            </a>
          </template>
          <v-list class="sub-menu">
            <v-list-item>
              <v-btn text to="/management/users">Users</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn text to="/management/rules">Rules</v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn text @click="signout" class="signout-btn"
          ><span class="material-icons">logout</span></v-btn
        >
        <span class="user-email-menu" :title="User">Welcome {{ User }}</span>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "AppNavigation",
  data() {
    return {
      appTitle: "ABEE - EMS",
      drawer: false,
      items: [
        { title: "Home", url: "/" },
        { title: "Automation", url: "/automation" },
        { title: "Email Templates", url: "/email-templates" },
        { title: "Campaign History", url: "/all-campaigns" },
        { title: "Management", url: "/management" }
      ],
      managementClass: "",
      campaignClass: ""
    };
  },
  computed: {
    User() {
      var x = "";
      if (this.$store.state.user) {
        if (this.$store.state.user.attributes.given_name) {
          x = this.$store.state.user.attributes.given_name;
        } else {
          x = this.$store.state.user.attributes.email;
        }
      }
      return x;
    },
    currentUser() {
      return this.$store.state.user;
    }
  },
  methods: {
    signout() {
      this.$store.dispatch("userSignOut");
    }
  },
  mounted() {
    if (
      this.$route.name == "Users Management" ||
      this.$route.name == "Rules Management"
    ) {
      this.managementClass = "v-btn--active";
      this.campaignClass = "";
    } 
    else if (
      this.$route.name == "Campaign Detail" ||
      this.$route.name == "Active Campaigns" || 
      this.$route.name == "Archived Campaigns"
    ) {
      this.campaignClass = "v-btn--active";
      this.managementClass = "";
    } 
    else {
      this.campaignClass = "";
      this.managementClass = "";
    }
  },
  watch: {
    $route: function() {
      if (
        this.$route.name == "Users Management" ||
        this.$route.name == "Rules Management"
      ) {
        this.managementClass = "v-btn--active";
        this.campaignClass = "";
      } else if (
        this.$route.name == "Campaign Detail" ||
        this.$route.name == "Active Campaigns" || 
        this.$route.name == "Archived Campaigns"
      ) {
        this.campaignClass = "v-btn--active";
        this.managementClass = "";
      } else {
        this.campaignClass = "";
        this.managementClass = "";
      }
    }
  }
};
</script>

<style scoped>
header {
  height: auto !important;
  background-color: #fff !important;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.05) !important;
}
.v-btn:before {
  border-radius: 0;
}
.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
  opacity: 0;
}
header .v-btn.v-btn--router,
header .v-btn.signout-btn {
  padding: 45px calc(3vw);
  border-radius: 0;
}
header .v-btn.signout-btn {
  float: right;
  font-size: 25px;
}
header .user-email-menu {
  float: right;
  padding: 33px calc(0vw);
  max-width: 175px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
}
header a.v-btn--active.v-btn.v-btn--router {
  background-color: #777bd2;
  color: #fff;
}
.v-btn i {
  color: inherit;
}
.v-btn:hover i {
  transform: rotate(180deg);
}
.sub-menu,
.sub-menu > div {
  padding: 0px;
  min-height: auto;
}
.sub-menu a.v-btn {
  padding: 10px 15px;
  min-width: 100%;
}
.sub-menu a.v-btn .v-btn__content {
  display: block;
}
.sub-menu a.v-btn--active {
  background-color: rgba(0, 0, 0, 0.05);
  color: #777bd2;
}
@media screen and (max-width: 959px) {
  header {
    padding: 10px 15px;
  }
}
</style>
