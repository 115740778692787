<template>
    <v-container fill-height class="confirmation-mask">
        <v-layout align-center justify-center>
            <v-flex xs10 sm10 md5 >
                <v-card class="elevation-4 confirmation-wrapper">
                <div class="card-header">
                    <p>{{Msg}}</p>
                </div>
                <div class="card-footer">
                    <v-btn class="elevation-0" v-on:click="$emit('close')">Cancel</v-btn>
                    <v-btn class="elevation-0 primary" v-on:click="confirmation()">{{primaryTxt}}</v-btn>
                </div>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
export default {
    name: 'routeConfirmationDIalog',
    props: ['route', 'primaryTxt', 'Msg'],
    data(){
        return{
            /* actionName: '' */
        }
    },
    methods:{
        /*setAction(){
            if($route.params.action = deleteCampaign){
                this.actionName = 'deleteCampaign'
            }
            else if($route.params.action = deleteTemplate){
                this.actionName = 'deleteEmailTemplate'
            }
        }, */
        confirmation(){
            this.$store.state.routeChangeFlag = true;
            var route = this.$store.state.routeTo;
            //console.log(route);
            this.$router.push({ path: route });
            this.$store.state.routeConfirmationDialog = false;
        }
    },
    /* watch: {
        '$route': function() {
            this.setAction();
        }
    } */
}
</script>
<style scoped>
.confirmation-mask{
    position: fixed;
    max-width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.6);
    z-index: 9999;
}
.confirmation-wrapper{
    padding: 40px 50px;
    background-color: #FAFAFA;
    text-align: center;
}
.confirmation-mask .card-footer button{
    padding: 10px 30px;
}
.card-footer button:last-child{
    margin-left:20px;
}
.card-header p{
    font-size: 18px;
    color: #575757;
    margin-bottom: 25px;
}
.card-footer .v-btn{
    min-width: 130px;
}
</style>