<template>
    <transition name="fade">
        <v-container fill-height id="view-email-template-container">
            <v-layout align-center justify-center>
                <v-flex xs10 sm8 md6 class="view-email-template-card-wrapper">
                    <span class="close_btn" @click="$emit('close')">&#10005;</span>
                    <v-card class="elevation-4" id="view-email-template-card" v-bind:style="{ height: cardHeight }">
                        
                        <v-card-text ref="templateCard">
                            <h1>{{template.name}}</h1>
                            <p><b>From:</b> {{template.sender.email}}</p>
                            <p><b>From Name:</b> {{template.from_name}}</p>
                            <p><b>Subject:</b> {{template.subject}}</p>
                            <p v-if="template.reply_to"><b>Reply To:</b> {{template.reply_to}}</p>
                            <p><b>Body:</b><br/>{{template.body}}</p>
                            <p v-if="template.attachment_name">Attachment: <a @click="downloadBlob(downloadURL, template.attachment_name.replace(/\_@.*\./, '.'))">{{template.attachment_name.replace(/\_@.*\./, '.')}}</a></p>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>    
    </transition>
</template>
<script>
import { Storage } from 'aws-amplify';
export default {
    name: 'AddNewEmailTemplate',
    props: ['template'],
    data(){
        return{
            cardHeight: '500',
            downloadURL: ''
        }
    },
    methods: {
        onResize() {
            if((this.$refs.templateCard.clientHeight + 40)> (window.innerHeight - 100)){
                this.cardHeight = (window.innerHeight - 100)+'px';
            }
            else{
                this.cardHeight = 'calc(80vh)';
            }
        },
        DownloadFileBlob(attachment_name){
            var _this = this;
            Storage.get(attachment_name, { download: true }).then(data=> {
                _this.downloadURL = URL.createObjectURL(data.Body);
            });
        },
        downloadBlob(blob, filename) {
          //const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = blob;
          a.download = filename || 'download';
          const clickHandler = () => {
            setTimeout(() => {
              URL.revokeObjectURL(blob);
              a.removeEventListener('click', clickHandler);
            }, 150);
          };
          a.addEventListener('click', clickHandler, false);
          a.click();
          this.DownloadFileBlob(this.template.attachment_name);
          return a;
        }
        /* downloadBlob(blob, filename) {
            console.log('yes2');
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename || 'download';
          const clickHandler = () => {
            setTimeout(() => {
              URL.revokeObjectURL(url);
              a.removeEventListener('click', clickHandler);
            }, 150);
          };
          a.addEventListener('click', clickHandler, false);
          a.click();
          console.log(a);
          return a;
        }
        closeViewEmailTemplateBox(){
            this.$store.state.templateName = '';
            this.$store.state.templateSubject = '';
            this.$store.state.templateBody = '';
            this.$store.state.templateID = '';
            this.$store.state.emailTemplateAttachment = null;
            this.$store.state.addEmailTemplateBox = false;
        } */
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
            });
        this.onResize();
        if(this.template.attachment_name){
            this.DownloadFileBlob(this.template.attachment_name)
        }
    },

    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize); 
    },
    computed:{
        
    }
}
</script>
<style scoped>
.theme--light.v-text-field--filled > .v-input__control > .v-input__slot{
    background: #fff !important;
}
#view-email-template-container{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    max-width: 100%;
    background-color: rgba(0,0,0,0.3);
}
#view-email-template-card{
    background-color: #FAFAFA;
    padding: 0 75px 40px;
    margin: 38px auto;
    overflow-y: auto;
}
#view-email-template-card h1{
    margin: 25px 0 40px;
    text-align:center;
    color: #000;
}
#view-email-template-card p{
    white-space: pre-line;
    margin-bottom: 0;
}
.v-card__actions{
    padding: 0 16px;
}
/* width */
#view-email-template-card::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#view-email-template-card::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
#view-email-template-card::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
#view-email-template-card::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.close_btn{
    position: absolute;
    right: 10px;
    top: 40px;
    z-index: 999;
    color: rgba(0,0,0,0.8);
    padding: 2px 10px;
    font-size: 20px;
    cursor: pointer;
    transition: 0.5s all;
}
.view-email-template-card-wrapper{
    position: relative;
}
</style>